import React from "react";
import styles from "./Loader.module.scss";

const Loader = (props) => {
  return (
    <div className={`${styles.container} ${styles.light}`}>
      <i className={`spinner-border`}></i>
    </div>
  );
};

export default Loader;
