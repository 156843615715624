import React, { Component } from "react";
import { get, debounce } from "lodash";
import ReactModal from "react-modal";
import Pagination from "react-js-pagination";
import { toast } from "react-toastify";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { Form } from "formik";
import { DOMAIN_NAMES } from "./helper";
import CustomLink from "../CustomLink";
import whiteLabelStyles from "./WhiteLabel.module.scss";
import styles from "../AdminDashboard.module.scss";
import Switch from "../Switch";
import LeftPanel from "../LeftPanel";
import {
  API_URL,
  ADMIN_WHITELABEL_ACCOUNTS,
  ADMIN_WHITELABEL_API,
  ADMIN_WHITELABEL_NEW_ACCOUNT,
  ADMIN_WHITELABEL_EDIT_ACCOUNT,
} from "../../constants";
import { getErrorMessage } from "../../helper";
import SvgIcons from "../../../../component/MaterialIcons/SvgIcons";
import request from "../../../../utils/request";
import { PROFILE_ICON } from "../../../../component/MaterialIcons/constants";
import LoginHeader from "../../../../component/LoginHeader";
import Navigator from "../../../../component/Navigation/Navigator";
import Loader from "../../../../component/Loader";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import generalColors from "../../../../styles/global.scss";
import Image from "../../../../component/Image";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import { getPartnerName } from "../../../../component/ThemeManager/helper";
import { WHITELABEL_TABLE_COLUMN } from "./constants";

const useDefaultToken = false;
class WhiteLabelListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      whitelabel: [],
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 6,
      searchValue: "",
      switchModal: false,
      editAccount: "",
    };
  }

  componentDidMount() {
    document.title = `${getPartnerName()}: Whitelabel Accounts`;
    this.getWhitelabel();
  }

  /**
   * handleAccount
   * @param {string} accountType
   */
  handleAccount = (accountType, accountData = null) => {
    if (accountType === "new") {
      this.props.history.push(ADMIN_WHITELABEL_NEW_ACCOUNT);
    } else {
      this.props.history.push({
        pathname: ADMIN_WHITELABEL_EDIT_ACCOUNT,
        state: {
          accountData,
        },
      });
    }
  };

  /**
   * isAccountActiveInformationPresent
   * @returns true if whitelabel account active information is present
   */
  isAccountActiveInformationPresent = () => {
    const { editAccount } = this.state;
    return (
      editAccount &&
      editAccount.isActive !== undefined &&
      editAccount.isActive !== null
    );
  };
  /**
   * getWhitelabel
   * @returns
   * fetch data from API
   */
  getWhitelabel = () => {
    const { activePage, searchValue } = this.state;
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN_WHITELABEL_ACCOUNTS}?limit=20&page=${activePage}&sort=-createdAt&searchBySlugName=${searchValue}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            whitelabel: res.data.list,
            itemsCountPerPage: res.data.limit,
            totalItemsCount: res.data.total,
          });
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  /**
   * handlePageChange
   * @param {number} pageNumber
   * page change with pagination
   */
  handlePageChange(pageNumber) {
    const { activePage } = this.state;
    if (activePage !== pageNumber) {
      this.setState(
        {
          activePage: pageNumber,
        },
        () => {
          this.getWhitelabel();
        }
      );
    }
  }

  /**
   * showSwitchModal
   * Makes the switch modal visible
   * @param {object} account
   */
  showSwitchModal = (account) => {
    this.setState({
      switchModal: true,
      editAccount: account,
    });
  };

  /**
   * editAccount - handle for active and deactivate of whiteLabel account
   */
  editAccount = () => {
    const { editAccount } = this.state;
    this.setState({ loading: true });
    const payload = {
      id: editAccount && editAccount._id && editAccount._id,
      isActive:
        this.isAccountActiveInformationPresent() && !editAccount.isActive,
    };
    const data = {
      method: "PATCH",
      body: payload,
    };
    const requestUrl = `${API_URL}${ADMIN_WHITELABEL_API}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(get(res, "message"));
          this.setState(
            { loading: false, editAccount: "", switchModal: false },
            () => {
              this.getWhitelabel();
            }
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
        this.setState({ loading: false, editAccount: "", switchModal: false });
      });
  };

  /**
   * getWhiteLabelURL
   * @param {string} slugName
   * @returns
   */
  getWhiteLabelURL = (slugName) =>
    `${slugName}.${this.getDomainName(slugName)}.com`;
  /**
   * switchModal
   * @returns Modal for Switch Modal
   */
  switchModal = () => {
    const { editAccount, switchModal } = this.state;
    const isSlugNameAvailable = editAccount && editAccount.slugName;
    return (
      <ReactModal
        isOpen={switchModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        onRequestClose={this.handleCancel}
        className={styles.imageModal}
        overlayClassName={styles.modalOverlay}
      >
        <h3>
          Are you sure you want to
          {this.isAccountActiveInformationPresent() && editAccount.isActive
            ? " disable "
            : " enable "}
          source&nbsp;
          <span className={whiteLabelStyles.dialogSlugName}>{`${
            isSlugNameAvailable &&
            `${this.getWhiteLabelURL(editAccount.slugName)}`
          }`}</span>
          ?
        </h3>
        <div className={styles.modalBtnContainer}>
          <button
            className={styles.primary}
            onClick={this.editAccount}
            data-testid="SwitchYes"
          >
            Yes
          </button>
          <button
            className={styles.secondary}
            onClick={() => {
              this.setState({ switchModal: false, editAccount: "" });
            }}
            data-testid="SwitchNo"
          >
            No
          </button>
        </div>
      </ReactModal>
    );
  };

  /**
   * getWhiteLabelProfileIcon - profile icon for account
   * @param {object} account
   * @returns profile icon
   */
  getWhiteLabelProfileIcon = (account) => {
    const { appLogo } = account;
    const urlWithPrefix = `${process.env.REACT_APP_URL}/logo/${appLogo}`;
    return (
      <div className={whiteLabelStyles.whiteLabelProfile}>
        {appLogo ? (
          <Image src={urlWithPrefix} alt="logo" imageType={IMAGE_TYPE.LOGO} />
        ) : (
          <SvgIcons icon={PROFILE_ICON} className={styles.altImage} />
        )}
      </div>
    );
  };

  /**
   * getDomainName
   * @param {string} slugName
   * @returns domain name based on slug name
   */
  getDomainName = (slugName) =>
    slugName === "app" ? DOMAIN_NAMES.BEATBREAD : DOMAIN_NAMES.CHORDCASH;

  /**
   * getSlugName - slug name for account
   * @param {object} account
   * @returns slug name with beatbread string
   */
  getSlugName = (account) => {
    const { slugName, isActive } = account;
    const isAccountActive =
      isActive !== undefined && isActive !== null && isActive;
    return (
      <CustomLink
        onClick={() => this.handleAccount("edit", account)}
        key={account._id}
        className={`${whiteLabelStyles.slugName} ${
          isAccountActive ? "" : whiteLabelStyles.disabledSlugName
        }`}
        disabled={!isAccountActive}
      >
        <p>{`${this.getWhiteLabelURL(slugName)}`}</p>
      </CustomLink>
    );
  };
  /**
   * getWhiteLabelActions - action for account
   * @param {object} account
   * @returns
   */
  getWhiteLabelActions = (account) => {
    return (
      <Switch
        id={account && account._id && account._id}
        title="Enable/Disable whitelabel account"
        isOn={account && account.isActive && account.isActive}
        onColor={generalColors.primaryColor}
        data-test="switch"
        handleToggle={() => this.showSwitchModal(account)}
      />
    );
  };
  /**
   * getWhiteLabelRows
   * @param {object} account
   * @returns whitelabel table rows
   */
  getWhiteLabelRows = (account) => {
    return (
      <TableRow key={account && account._id && account._id}>
        <TableCell></TableCell>
        <TableCell>{this.getWhiteLabelProfileIcon(account)}</TableCell>
        <TableCell>{this.getSlugName(account)}</TableCell>
        <TableCell>{this.getWhiteLabelActions(account)}</TableCell>
      </TableRow>
    );
  };
  /**
   * renderWhiteLabelTabularView - render table view for white label accounts
   * @returns
   */
  renderWhiteLabelTabularView = () => {
    const {
      whitelabel,
      activePage,
      itemsCountPerPage,
      totalItemsCount,
      pageRangeDisplayed,
    } = this.state;

    return (
      <center>
        <TableContainer
          component={Paper}
          className={whiteLabelStyles.whiteLabelTable}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {WHITELABEL_TABLE_COLUMN.map((column) => (
                  <TableCell {...column.props} key={column.key}>
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {whitelabel.map((account) => this.getWhiteLabelRows(account))}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className={`${styles.adminPagination} ${whiteLabelStyles.whiteLabelPagination}`}
        >
          <Pagination
            activePage={activePage}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={totalItemsCount}
            pageRangeDisplayed={pageRangeDisplayed}
            onChange={this.handlePageChange.bind(this)}
            activeClass={styles.active}
          />
        </div>
      </center>
    );
  };
  /**
   * delayedSearchResults - search with debounce
   */
  delayedSearchResults = debounce(() => this.getWhitelabel(), 500);

  render() {
    const { loading, searchValue } = this.state;
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
          <Navigator {...this.props} />
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div
              className={`${styles.title} ${styles.innerTitle} ${whiteLabelStyles.whiteLabelTitle}`}
            >
              <h1>Whitelabel Accounts</h1>
            </div>
            <center>
              <div className={whiteLabelStyles.searchContainer}>
                <FormikForm
                  initialValues={{
                    whiteLabelSearchbar: "",
                  }}
                >
                  <Form>
                    <div
                      className={`form-group ${whiteLabelStyles.formMargin0}`}
                    >
                      <FormField
                        name="whiteLabelSearchbar"
                        placeholder="Search by source name"
                        type="input"
                        value={searchValue}
                        onChange={(e) => {
                          this.setState(
                            {
                              searchValue: e.target.value,
                            },
                            () => {
                              if (
                                this.state.searchValue.length > 2 ||
                                this.state.searchValue.length === 0
                              ) {
                                this.delayedSearchResults();
                              }
                            }
                          );
                        }}
                        data-testid="SearchValue"
                      />
                    </div>
                  </Form>
                </FormikForm>
                <button
                  onClick={() => this.handleAccount("new")}
                  data-testid="NewWhiteLabelAccount"
                >
                  Create New
                </button>
              </div>
            </center>
            {this.renderWhiteLabelTabularView()}
            {this.switchModal()}
          </div>
        </div>
        {loading && <Loader />}
      </>
    );
  }
}

export default WhiteLabelListing;
