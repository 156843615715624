import React, { Component } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import {
  LOGIN,
  GETFUNDED,
  INVESTOR_LINK,
  PARTNER_LINK,
  ABOUT_US_LINK,
  FAQ_LINK,
  OUR_ARTIST,
  MAINTENANCE_PAGE,
} from "../../routes/pages/constants";
import styles from "./Header.module.scss";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import { IMAGE_TYPE } from "../Image/constants";
import Image from "../Image";
import { BB_SUBDOMAINS } from "../ThemeManager/constants";
import { getSubDomain } from "../ThemeManager/helper";
import SvgIcons from "../MaterialIcons/SvgIcons";
import { MENU_ICON } from "../MaterialIcons/constants";

class Header extends Component {
  toggleMenu = () => {
    const menuDiv = document.getElementsByClassName(styles.menu)[0];
    menuDiv.classList.toggle(styles.dNone);
    document
      .getElementsByClassName(styles.menuOverlay)[0]
      .classList.toggle(styles.dNone);
  };

  render() {
    return (
      <div className={styles.headerComponent}>
        <div className={styles.container}>
          <div>
            <a
              href={get(this.context, "servicePageUrl")}
              rel="noopener noreferrer"
              className={styles.title}
            >
              <Image
                src={get(this.context, "appLogo")}
                alt={"logo"}
                imageType={IMAGE_TYPE.LOGO}
              />
            </a>
          </div>
          <div
            className={styles.menuContainer}
            onClick={this.toggleMenu}
            data-testid="menuClick"
          >
            <i className={styles.menuIcon}>
              <SvgIcons icon={MENU_ICON} className={styles.menuIcon} />
            </i>
            <div className={`${styles.menuOverlay} ${styles.dNone}`}>
              <div className={`${styles.menu} ${styles.dNone}`}>
                <a
                  href={get(this.context, "servicePageUrl")}
                  rel="noopener noreferrer"
                  className={styles.title}
                >
                  <Image
                    src={get(this.context, "appLogo")}
                    alt={"logo"}
                    imageType={IMAGE_TYPE.LOGO}
                  />
                </a>
                <div className={styles.menuBtns}>
                  {BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 && (
                    <>
                      <Link
                        to={GETFUNDED}
                        className={`${
                          this.props.active === GETFUNDED && styles.primary
                        } ${styles.link}`}
                      >
                        Get Funded
                      </Link>
                      <a
                        href={OUR_ARTIST}
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        Our Artists
                      </a>
                      <a
                        href={INVESTOR_LINK}
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        Investors
                      </a>
                      <a
                        href={PARTNER_LINK}
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        Partners
                      </a>
                      <a
                        href={ABOUT_US_LINK}
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        About Us
                      </a>
                      <a
                        href={FAQ_LINK}
                        rel="noopener noreferrer"
                        className={styles.link}
                      >
                        FAQs
                      </a>
                    </>
                  )}
                  {this.props.active !== MAINTENANCE_PAGE && (
                    <Link
                      to={LOGIN}
                      className={`${
                        this.props.active === LOGIN && styles.primary
                      } ${styles.importantLink} ${styles.link} ${
                        styles.primary
                      }`}
                    >
                      Login
                    </Link>
                  )}
                </div>
                <p className={styles.close}>&times;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Header.contextType = ThemeContext;
export default Header;
