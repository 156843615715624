import React, { Component, createRef } from "react";
import ReactModal from "react-modal";
import _, { get, debounce } from "lodash";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import {
  IMG_EXTENSIONS_REGEX,
  FIELD_INITIAL_VALUES,
  IMG_MAX_SIZE,
  IMG_NOT_AVAILABLE_CASES,
  IMG_RATIO_LIMIT,
  validationSchema,
  checkForAdditionalFields,
  setFieldsMapping,
  setTitle,
  renderReportAdditionalFields,
  renderFinePrintText,
  renderSidebarText,
  renderColorCodes,
  renderSvgAssets,
  renderThankYouCta,
  renderAckScreenCta,
  FAVICON_EXTENSIONS_REGEX,
  FAVICON_MAX_SIZE,
  FAVICON_RATIO_LIMIT,
  renderOfferSummaryText,
  renderContractUrl,
  renderLandingPageURL,
} from "./helper";
import whiteLabelStyles from "./WhiteLabel.module.scss";
import ImageUpload from "./ImageUpload";
import LeftPanel from "../LeftPanel";
import Switch from "../Switch";
import styles from "../AdminDashboard.module.scss";
import { getErrorMessage } from "../../helper";
import {
  API_URL,
  ADMIN_WHITELABEL,
  ADMIN_WHITELABEL_EDIT_ACCOUNT,
  ADMIN_WHITELABEL_NEW_ACCOUNT,
  INSTANCE,
  ADMIN_WHITELABEL_API,
} from "../../constants";
import generalColors from "../../../../styles/global.scss";
import LoginHeader from "../../../../component/LoginHeader";
import Navigator from "../../../../component/Navigation/Navigator";
import Loader from "../../../../component/Loader";
import FormField from "../../../../component/FormField/FormField";
import request from "../../../../utils/request";
import { FIELD_NAMES, MAPPING_FIELDS, ASSETS_IDS } from "./constants";
import { DEFAULT_BB_THEME } from "../../../../component/ThemeManager/constants";

const useDefaultToken = false;
class WhiteLabelAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initialValues: FIELD_INITIAL_VALUES,
      //image
      appLogo: { src: "", fileName: "" },
      emailLogo: { src: "", fileName: "" },
      favicon: { src: "", fileName: "" },
      appLogoSizeError: false,
      emailLogoSizeError: false,
      appLogoTypeError: false,
      emailLogoTypeError: false,
      appLogoDimensionError: false,
      emailLogoDimensionError: false,
      faviconSizeError: false,
      faviconTypeError: false,
      faviconDimensionError: false,
      emailIconColor: "",
      emailBoxColor: "",
      // is distributor
      [FIELD_NAMES.IS_DISTRIBUTOR.NAME]: "Yes",
      isDistributorError: false,
      //edit
      isEdit: false,
      passwordBox: false,
    };
    this.emailAssets = { ...ASSETS_IDS };
    this.assetsColorFieldChanges = debounce(this.assetsColorFieldChange, 700);
    this.editorRef = createRef(null);
    this.editorSidebarRef = createRef(null);
  }

  /**
   * setValuesFromAPI
   * @param {object} res
   * @returns values populated from api
   */
  setValuesFromAPI = (res) => {
    const {
      data,
      data: {
        thankYouCTA,
        acknowledgementCTA,
        getContractUrl,
        emailStyle,
        appStyle,
        progressStatusStyle,
        sidebarStyle,
        alertStyle,
        contentStyle,
        reportFields,
      },
    } = res;
    this.setState({
      [FIELD_NAMES.IS_DISTRIBUTOR.NAME]:
        reportFields[FIELD_NAMES.IS_DISTRIBUTOR.NAME] || "Yes",
    });
    return {
      initialValues: {
        [FIELD_NAMES.DISPLAY_NAME.NAME]: data.displayName,
        [FIELD_NAMES.SLUG_NAME.NAME]: data.slugName,
        [FIELD_NAMES.CTA_DEFAULT_VALUE.NAME]: thankYouCTA.popupText,
        [FIELD_NAMES.CTA_BUTTON_TEXT.NAME]: thankYouCTA.buttonText,
        [FIELD_NAMES.CTA_ACTION_URL.NAME]: thankYouCTA.actionUrl,
        [FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME]: acknowledgementCTA.buttonText,
        [FIELD_NAMES.ACK_SCREEN_CTA_URL.NAME]: acknowledgementCTA.actionUrl,
        [FIELD_NAMES.LANDING_PAGE_URL.NAME]: data.frontendDomain,
        [FIELD_NAMES.WHITE_LISTED_URLS.NAME]:
          data[FIELD_NAMES.WHITE_LISTED_URLS.NAME] || [],
        [FIELD_NAMES.FINE_PRINT_TEXT.NAME]: data.finePrintText,
        [FIELD_NAMES.SIDEBAR_TEXT.NAME]: data.sidebarText,
        [FIELD_NAMES.OFFER_SUMMARY_TEXT.NAME]: data.offerSummaryText,
        [FIELD_NAMES.GET_CONTRACT_US_URL.NAME]:
          getContractUrl.nonInternationalUrl,
        [FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.NAME]:
          getContractUrl.internationalUrl,
        [FIELD_NAMES.ADMIN_PASSWORD.NAME]: "",
        ...setFieldsMapping(MAPPING_FIELDS.API_VALUES, data),
        colorCodes: {
          general: { primaryColor: data.primaryColor },
          email: {
            headerFooterColor: emailStyle.headerFooterColor,
            ctaColor: emailStyle.ctaColor,
            fontColorOne: emailStyle.fontColor1,
            fontColorTwo: emailStyle.fontColor2,
            boxColor: emailStyle.boxColor,
            iconColor: emailStyle.iconColor,
          },
          register: {
            mainBackgroundColor: appStyle.primaryBackgroundColor,
            headerBackgroundColor: appStyle.headerBackgroundColor,
            headerFontColor: appStyle.headerFontColor,
            accentTextColor: appStyle.accentFontColor,
            ctaColor: appStyle.ctaColor,
          },
          mainFunnel: {
            sidebar: {
              headerFooterAccentColor: sidebarStyle.headerFooterColor,
              primaryColor: sidebarStyle.primaryBackgroundColor,
              fontColorOne: sidebarStyle.fontColor1,
              fontColorTwo: sidebarStyle.fontColor2,
              toolTipIconColor: sidebarStyle.tooltipIconColor,
            },
            progressionStatus: {
              accentColor: progressStatusStyle.accentColor,
              reviewUpdatesColor: progressStatusStyle.updateColor,
            },
            alertsAndToolTips: {
              toolTipsBackground: alertStyle.tooltipBackgroundColor,
              toolTipsFontColor: alertStyle.tooltipFontColor,
              alertBackground: alertStyle.alertBackgroundColor,
              alertFontColor: alertStyle.alertFontColor,
            },
            content: {
              sliderColor: contentStyle.sliderColor,
              helpTextColor: contentStyle.helpTextColor,
            },
          },
        },
      },
    };
  };
  /**
   * setFormLogo
   * @param {object} res
   * @returns image populated from api
   */
  setFormLogo = (res, type) => {
    const splitLogoValue =
      get(res, `data.${type}`) && res.data[type].split("/").pop().trim();
    this.setState({
      [type]: { src: res.data[type], fileName: splitLogoValue },
    });
  };
  /**
   * validateForEdit - check if the form is edit and there is edit flag from listing page
   */
  validateForEdit = () => {
    const {
      history: {
        location: { pathname, state },
      },
    } = this.props;
    if (
      pathname.includes(ADMIN_WHITELABEL_EDIT_ACCOUNT) &&
      get(state, "accountData._id")
    ) {
      this.setState({
        isEdit: true,
        loading: true,
      });
      const data = {
        method: "GET",
        id: {
          id: state.accountData._id,
        },
      };
      const requestUrl = `${API_URL}${ADMIN_WHITELABEL_API}${INSTANCE}?source=${state.accountData.slugName}`;
      request(requestUrl, data, useDefaultToken)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status) {
            this.setFormLogo(res, "appLogo");
            this.setFormLogo(res, "emailLogo");
            this.setFormLogo(res, "favicon");
            const values = this.setValuesFromAPI(res);
            this.setState({ initialValues: values.initialValues });
            this.assetsColorFieldChange(
              get(
                values,
                "initialValues.colorCodes.email.iconColor",
                DEFAULT_BB_THEME.emailStyle.iconColor
              ),
              get(
                values,
                "initialValues.colorCodes.email.boxColor",
                DEFAULT_BB_THEME.emailStyle.boxColor
              )
            );
            return true;
          }
          toast.error(get(res, "message"));
          return false;
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(getErrorMessage(err));
        });
    } else {
      this.setState({ isEdit: false });
      this.assetsColorFieldChange(
        DEFAULT_BB_THEME.emailStyle.iconColor,
        DEFAULT_BB_THEME.emailStyle.boxColor
      );
      this.props.history.push(ADMIN_WHITELABEL_NEW_ACCOUNT);
    }
  };

  componentDidMount() {
    this.validateForEdit();
    setTitle(this.props);
  }
  /**
   * checkImageValidation
   * @param {object} file
   * @returns
   */
  checkImageValidation = (file, type) => {
    this.setState({
      appLogoTypeError: false,
      appLogoDimensionError: false,
      appLogoSizeError: false,
      emailLogoDimensionError: false,
      emailLogoTypeError: false,
      emailLogoSizeError: false,
      faviconTypeError: false,
      faviconDimensionError: false,
      faviconSizeError: false,
    });
    const fileType =
      type === "favicon" ? FAVICON_EXTENSIONS_REGEX : IMG_EXTENSIONS_REGEX;
    if (!fileType.test(file[0].type)) {
      this.setState({ [`${type}TypeError`]: true });
      return false;
    }
    const maxSize = type === "favicon" ? FAVICON_MAX_SIZE : IMG_MAX_SIZE;
    if (file[0].size > maxSize) {
      this.setState({ [`${type}SizeError`]: true });
      return false;
    }
    return file;
  };
  /**
   * onChangeFileHandler - file handler function
   * @param {object} event
   */
  onChangeFileHandler = (event, type) => {
    const file = event.target.files;
    let validateFile;
    if (file) {
      validateFile = this.checkImageValidation(file, type);
    }
    if (validateFile) {
      const img = new Image();
      img.src = window.URL.createObjectURL(file[0]);
      const fileToSave = file[0];
      img.onload = () => {
        const ratio = img.width / img.height;
        const minRatio =
          type === "favicon" ? FAVICON_RATIO_LIMIT.MIN : IMG_RATIO_LIMIT.MIN;
        const maxRatio =
          type === "favicon" ? FAVICON_RATIO_LIMIT.MAX : IMG_RATIO_LIMIT.MAX;
        if (!(ratio >= minRatio && ratio <= maxRatio)) {
          this.setState({ [`${type}DimensionError`]: true });
        } else {
          this.setState({
            [type]: {
              fileName: fileToSave && fileToSave.name,
              src: img.src,
            },
          });
          window.URL.revokeObjectURL(file[0]);
        }
      };
    }
    event.target.value = null;
  };
  /**
   * checkIfImageChanged - is image changed while editing.
   * @param {string} type
   */
  checkIfImageChanged = (type) => {
    if (this.state[type].src.includes("blob")) {
      return true;
    }
    return false;
  };
  /**
   * getImageBlob - returns blob of image
   * @param {string} type
   * @returns
   */
  getImageBlob = async (type, isEmailAsset) => {
    const payload = {
      blob: true,
    };
    if (isEmailAsset) {
      return await request(this.emailAssets[type], payload).then((r) => r);
    }
    return await request(this.state[type].src, payload).then((r) => r);
  };
  /**
   * onSubmit
   * @param {object} values
   */
  onSubmit = async (values) => {
    const formData = new FormData();
    const {
      history: {
        location: { state },
      },
    } = this.props;
    const { isEdit, emailLogo, appLogo, favicon } = this.state;
    const {
      colorCodes: {
        general,
        email,
        register,
        mainFunnel: { sidebar, progressionStatus, alertsAndToolTips, content },
      },
    } = values;
    formData.append(
      "isDistributor",
      this.state[FIELD_NAMES.IS_DISTRIBUTOR.NAME]
    );
    for (
      let index = 0;
      index <
      FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS.length;
      index++
    ) {
      const label =
        values[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
            .LABEL.NAME
        ];
      formData.append(
        `isField${index + 1}Required`,
        values[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
            .REQUIRED.NAME
        ]
      );
      const tooltip =
        values[
          FIELD_NAMES.REPORT_ADDITIONAL_FIELDS.FIELDS.INDIVIDUAL_FIELDS[index]
            .TOOLTIP.NAME
        ];
      if (label && label.length > 0) {
        formData.append(`label${index + 1}`, label);
        if (tooltip && tooltip.length > 0) {
          formData.append(`tooltip${index + 1}`, tooltip);
        }
      }
    }
    // email - logo
    const hasEmailLogoChanged = this.checkIfImageChanged("emailLogo");
    if (hasEmailLogoChanged) {
      const emailLogoBlob = await this.getImageBlob("emailLogo");
      formData.append("emailLogo", emailLogoBlob, emailLogo.fileName);
    }
    // app-logo
    const hasAppLogoChanged = this.checkIfImageChanged("appLogo");
    if (hasAppLogoChanged) {
      const appLogoBlob = await this.getImageBlob("appLogo");
      formData.append("appLogo", appLogoBlob, appLogo.fileName);
    }
    // favicon
    const hasFaviconChanged = this.checkIfImageChanged("favicon");
    if (hasFaviconChanged) {
      const faviconBlob = await this.getImageBlob("favicon");
      formData.append("favicon", faviconBlob, favicon.fileName);
    }
    // whitelabel display name and slug name
    formData.append("slugName", values[FIELD_NAMES.SLUG_NAME.NAME]);
    formData.append("displayName", values[FIELD_NAMES.DISPLAY_NAME.NAME]);
    // thankyou cta pop-up text
    formData.append(
      "thankYouCtaPopupText",
      values[FIELD_NAMES.CTA_DEFAULT_VALUE.NAME]
    );
    formData.append(
      "thankYouCtaButtonText",
      values[FIELD_NAMES.CTA_BUTTON_TEXT.NAME]
    );
    formData.append(
      "thankYouCtaActionUrl",
      values[FIELD_NAMES.CTA_ACTION_URL.NAME]
    );
    // acknowledgement screen cta
    formData.append(
      "acknowledgementCtaButtonText",
      values[FIELD_NAMES.ACK_SCREEN_CTA_TEXT.NAME]
    );
    formData.append(
      "acknowledgementCtaActionUrl",
      values[FIELD_NAMES.ACK_SCREEN_CTA_URL.NAME]
    );
    // landing page URL
    formData.append(
      "frontendDomain",
      values[FIELD_NAMES.LANDING_PAGE_URL.NAME]
    );
    formData.append("whitelistUrl", values[FIELD_NAMES.WHITE_LISTED_URLS.NAME]);
    formData.append("finePrintText", values[FIELD_NAMES.FINE_PRINT_TEXT.NAME]);
    formData.append("sidebarText", values[FIELD_NAMES.SIDEBAR_TEXT.NAME]);
    formData.append(
      "offerSummaryText",
      values[FIELD_NAMES.OFFER_SUMMARY_TEXT.NAME]
    );
    formData.append(
      "nonInternationalUrl",
      values[FIELD_NAMES.GET_CONTRACT_US_URL.NAME]
    );
    formData.append(
      "internationalUrl",
      values[FIELD_NAMES.GET_CONTRACT_INTERNATIONAL_URL.NAME]
    );
    // general color
    formData.append("primaryColor", general.primaryColor);
    // email color
    formData.append("emailHeaderFooterColor", email.headerFooterColor);
    formData.append("emailCtaColor", email.ctaColor);
    formData.append("emailFontColor1", email.fontColorOne);
    formData.append("emailFontColor2", email.fontColorTwo);
    formData.append("emailBoxColor", email.boxColor);
    formData.append("emailIconColor", email.iconColor);
    // main funnel
    // register
    formData.append("appPrimaryBackgroundColor", register.mainBackgroundColor);
    formData.append("appHeaderBackgroundColor", register.headerBackgroundColor);
    formData.append("appHeaderFontColor", register.headerFontColor);
    formData.append("appAccentFontColor", register.accentTextColor);
    formData.append("appCtaColor", register.ctaColor);
    // sidebar
    formData.append("sidebarPrimaryBackgroundColor", sidebar.primaryColor);
    formData.append(
      "sidebarHeaderFooterColor",
      sidebar.headerFooterAccentColor
    );
    formData.append("sidebarFontColor1", sidebar.fontColorOne);
    formData.append("sidebarFontColor2", sidebar.fontColorTwo);
    formData.append("sidebarTooltipIconColor", sidebar.toolTipIconColor);
    // progression status
    formData.append("progressStatusAccentColor", progressionStatus.accentColor);
    formData.append(
      "progressStatusUpdateColor",
      progressionStatus.reviewUpdatesColor
    );
    // alert and tooltips
    formData.append(
      "alertTooltipBackgroundColor",
      alertsAndToolTips.toolTipsBackground
    );
    formData.append(
      "alertTooltipFontColor",
      alertsAndToolTips.toolTipsFontColor
    );
    formData.append("alertBackgroundColor", alertsAndToolTips.alertBackground);
    formData.append("alertFontColor", alertsAndToolTips.alertFontColor);
    // content
    formData.append("contentSliderColor", content.sliderColor);
    formData.append("contentHelpTextColor", content.helpTextColor);
    formData.append("adminPassword", values[FIELD_NAMES.ADMIN_PASSWORD.NAME]);
    if (isEdit) {
      formData.append("id", state.accountData._id);
    }
    // custom assets
    formData.append(
      "iconMonitor",
      await this.getImageBlob("iconMonitor", true),
      "iconMonitor.png"
    );
    formData.append(
      "iconSlider",
      await this.getImageBlob("iconSlider", true),
      "iconSlider.png"
    );
    formData.append(
      "iconDiamond",
      await this.getImageBlob("iconDiamond", true),
      "iconDiamond.png"
    );
    formData.append(
      "iconLocker",
      await this.getImageBlob("iconLocker", true),
      "iconLocker.png"
    );
    formData.append(
      "iconCash",
      await this.getImageBlob("iconCash", true),
      "iconCash.png"
    );
    formData.append(
      "iconPunctuation",
      await this.getImageBlob("iconPunctuation", true),
      "iconPunctuation.png"
    );

    this.setState({
      loading: true,
      isDistributorError: false,
    });
    const data = {
      method: "POST",
      body: formData,
      headers: { "Access-Control-Allow-Origin": "*" },
    };
    const requestUrl = `${API_URL}${ADMIN_WHITELABEL_API}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(
            `${values.displayName} account is successfully ${
              isEdit ? `updated!` : `created!`
            }`
          );
          this.props.history.push(ADMIN_WHITELABEL);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  renderNameAndLogo = () => {
    const {
      emailLogo,
      appLogo,
      favicon,
      appLogoSizeError,
      emailLogoSizeError,
      faviconSizeError,
      appLogoTypeError,
      emailLogoTypeError,
      faviconTypeError,
      appLogoDimensionError,
      emailLogoDimensionError,
      faviconDimensionError,
      isEdit,
    } = this.state;
    return (
      <div className={whiteLabelStyles.nameAndLogoContainer}>
        <p>Whitelabel Name and Logo</p>
        <div className={whiteLabelStyles.subContainer}>
          <div>
            <div className={`form-group ${whiteLabelStyles.formGroup}`}>
              <FormField
                name={FIELD_NAMES.DISPLAY_NAME.NAME}
                type="text"
                as={"input"}
                placeholder={FIELD_NAMES.DISPLAY_NAME.PLACEHOLDER}
                label={`${FIELD_NAMES.DISPLAY_NAME.LABEL}*`}
              />
            </div>
            <div
              className={`form-group ${whiteLabelStyles.formGroup} 
                                        ${whiteLabelStyles.slugInstruction}`}
            >
              <FormField
                name={FIELD_NAMES.SLUG_NAME.NAME}
                disabled={isEdit}
                type="text"
                as={"input"}
                placeholder={FIELD_NAMES.SLUG_NAME.PLACEHOLDER}
                label={`${FIELD_NAMES.SLUG_NAME.LABEL}*`}
              />
              <p>{FIELD_NAMES.SLUG_NAME.EXTRA_TEXT}</p>
            </div>
          </div>
          <div>
            <div className={whiteLabelStyles.customFileUpload}>
              <ImageUpload
                accept=".jpg, .jpeg, .png, .svg"
                uploadType="Email Logo"
                aspectRatio="3:1"
                maxSize="250 KB"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "emailLogo")}
                editLogoMessage="Edit Email Logo"
                imageAvailable={emailLogo.src}
                buttonText="+ Add Email Logo*"
                sizeError={emailLogoSizeError}
                typeError={emailLogoTypeError}
                dimensionError={emailLogoDimensionError}
                data-testid="EmailLogo"
              />
            </div>
            <div className={whiteLabelStyles.customFileUpload}>
              <ImageUpload
                accept=".jpg, .jpeg, .png, .svg"
                uploadType="App Logo"
                aspectRatio="3:1"
                maxSize="250 KB"
                editLogoMessage="Edit App Logo"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "appLogo")}
                imageAvailable={appLogo.src}
                buttonText="+ Add App Logo*"
                sizeError={appLogoSizeError}
                typeError={appLogoTypeError}
                dimensionError={appLogoDimensionError}
                data-testid="AppLogo"
              />
            </div>
            <div className={whiteLabelStyles.customFileUpload}>
              <ImageUpload
                accept=".jpg, .jpeg, .png, .svg, .gif, .ico"
                uploadType="Favicon"
                aspectRatio="1:1"
                maxSize="5 KB"
                editLogoMessage="Edit Favicon"
                multiple={false}
                onChange={(e) => this.onChangeFileHandler(e, "favicon")}
                imageAvailable={favicon.src}
                buttonText="+ Add Favicon"
                sizeError={faviconSizeError}
                typeError={faviconTypeError}
                dimensionError={faviconDimensionError}
                data-testid="favicon"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDistributorSwitch = (values) => {
    const { isDistributorError } = this.state;
    return (
      <div className={whiteLabelStyles.isDistributor}>
        <div className={whiteLabelStyles.isDistributorContainer}>
          <p>{FIELD_NAMES.IS_DISTRIBUTOR.EXTRA_TEXT}</p>
          <div className={whiteLabelStyles.subContainer}>
            <Switch
              id="distributorSwitch"
              title="Enable/Disable Distributor Fields"
              isOn={this.state[FIELD_NAMES.IS_DISTRIBUTOR.NAME] === "Yes"}
              onColor={generalColors.primaryColor}
              data-testid="distributorSwitch"
              handleToggle={() => {
                const ifValidToGo = checkForAdditionalFields(values);
                if (!ifValidToGo) {
                  this.setState({
                    isDistributorError: true,
                  });
                } else {
                  this.setState((prevState) => ({
                    [FIELD_NAMES.IS_DISTRIBUTOR.NAME]:
                      prevState[FIELD_NAMES.IS_DISTRIBUTOR.NAME] === "Yes"
                        ? "No"
                        : "Yes",
                    isDistributorError: false,
                  }));
                }
              }}
            />
          </div>
        </div>
        {isDistributorError && (
          <div
            className={`${whiteLabelStyles.errorText} ${whiteLabelStyles.additionalFieldErrorMessage}`}
          >
            {FIELD_NAMES.IS_DISTRIBUTOR.ERROR_TEXT}
          </div>
        )}
      </div>
    );
  };

  validateCondition = (validateForm, handleSubmit) => {
    validateForm().then((val) => {
      !_.size(val) ? this.handleOpenPasswordBox() : handleSubmit();
    });
  };

  renderSubmitButton = (validateForm, handleSubmit) => {
    const {
      loading,
      isEdit,
      appLogo,
      emailLogo,
      appLogoDimensionError,
      appLogoTypeError,
      appLogoSizeError,
      emailLogoDimensionError,
      emailLogoTypeError,
      emailLogoSizeError,
    } = this.state;
    const appError =
      appLogoDimensionError || appLogoSizeError || appLogoTypeError;
    const emailError =
      emailLogoTypeError || emailLogoSizeError || emailLogoDimensionError;
    const areLogosUploaded =
      IMG_NOT_AVAILABLE_CASES.some((imgCase) => appLogo.src === imgCase) ||
      IMG_NOT_AVAILABLE_CASES.some((imgCase) => emailLogo.src === imgCase);
    const imageError = appError || emailError || areLogosUploaded;
    const isButtonDisabled = loading || imageError;
    return (
      <div className={whiteLabelStyles.saveButton}>
        <button
          type="submit"
          disabled={loading}
          className="btn btn-link"
          onClick={() => {
            toast.info("Data entered in this form will not be saved.");
            this.props.history.push(ADMIN_WHITELABEL);
          }}
          data-testid="Cancel"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            this.validateCondition(validateForm, handleSubmit);
          }}
          type="button"
          className={isButtonDisabled && whiteLabelStyles.disabledSubmitButton}
          disabled={isButtonDisabled}
        >
          {isEdit ? "Save Changes" : "Create Whitelabel"}
        </button>
      </div>
    );
  };

  handleOpenPasswordBox = () => this.setState({ passwordBox: true });
  handleClosePasswordBox = (setFieldValue) => {
    setFieldValue(FIELD_NAMES.ADMIN_PASSWORD.NAME, "");
    this.setState({ passwordBox: false });
  };

  renderAdminPasswordField = (isValid, handleSubmit, values, setFieldValue) => {
    if (!isValid && this.state.passwordBox)
      this.setState({ passwordBox: false });
    return (
      <ReactModal
        isOpen={this.state.passwordBox && isValid}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.handleClosePasswordBox}
        className={styles.imageModal}
        overlayClassName={styles.modalOverlay}
      >
        <h3>
          {this.state.isEdit ? "Save Changes" : "Create Whitelabel"} for{" "}
          <span className={styles.primaryName}>
            {values[FIELD_NAMES.DISPLAY_NAME.NAME]}
          </span>
          ?
        </h3>
        <div className={styles.changeSource}>
          <p>Please input the password to confirm these changes: </p>
          <div
            className={`${styles.changeTo} form-group justify-content-center`}
          >
            <FormField
              name={FIELD_NAMES.ADMIN_PASSWORD.NAME}
              label={FIELD_NAMES.ADMIN_PASSWORD.LABEL}
              placeholder={FIELD_NAMES.ADMIN_PASSWORD.PLACEHOLDER}
              as="password"
            />
          </div>
        </div>
        <div className={styles.modalBtnContainer}>
          <button
            className={styles.primary}
            disabled={!values[FIELD_NAMES.ADMIN_PASSWORD.NAME]}
            onClick={handleSubmit}
            type="submit"
          >
            Okay
          </button>
          <button
            className={styles.secondary}
            onClick={() => this.handleClosePasswordBox(setFieldValue)}
          >
            Cancel
          </button>
        </div>
      </ReactModal>
    );
  };

  changeSvgColor = (element, field) => {
    element.getElementById(
      "color-change"
    ).style.fill = this.state.emailIconColor;
    if (field === "iconPunctuation") {
      element.getElementById(
        "color-change-2"
      ).style.fill = this.state.emailBoxColor;
    }
  };

  onImageLoad = (img, blobUrl, field) => () => {
    const canvas = document.createElement("canvas");
    canvas.height = 118;
    canvas.width = 118;
    if (field === "iconPunctuation") {
      canvas.height = 190;
      canvas.width = 254;
    }
    const ctx = canvas.getContext("2d");
    if (field !== "iconPunctuation") {
      ctx.drawImage(img, 0, 0, 118, 118);
    }
    if (field === "iconPunctuation") {
      ctx.drawImage(img, 0, 0, 254, 190);
    }
    this.emailAssets[field] = canvas.toDataURL();
    URL.revokeObjectURL(blobUrl);
  };

  convertToPng = (field) => {
    const svgElement = document.getElementById(ASSETS_IDS[field]);
    svgElement.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    this.changeSvgColor(svgElement, field);
    const blob = new Blob([svgElement.outerHTML], {
      type: "image/svg+xml;charset=utf-8",
    });
    const blobUrl = URL.createObjectURL(blob);
    const img = new Image();
    img.onload = this.onImageLoad(img, blobUrl, field).bind(this);
    img.src = blobUrl;
  };

  createEmailAssets = () => {
    for (const key in ASSETS_IDS) {
      this.convertToPng(key);
    }
  };

  assetsColorFieldChange = (emailIconColor, emailBoxColor) => {
    this.setState(
      {
        emailIconColor: emailIconColor || this.state.emailIconColor,
        emailBoxColor: emailBoxColor || this.state.emailBoxColor,
      },
      this.createEmailAssets
    );
  };

  render() {
    const { loading, isEdit } = this.state;
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
          <Navigator {...this.props} />
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div
              className={`${styles.title} ${styles.innerTitle} ${whiteLabelStyles.whiteLabelTitle}`}
            >
              <h1>{isEdit ? "Edit" : "Create New"} Whitelabel Account</h1>
            </div>
            <div className={whiteLabelStyles.contentContainer}>
              <Formik
                initialValues={this.state.initialValues}
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
                enableReinitialize
                data-testid="formRender"
              >
                {({
                  values,
                  isValid,
                  handleSubmit,
                  validateForm,
                  setFieldValue,
                }) => (
                  <Form>
                    {this.renderNameAndLogo()}
                    {renderThankYouCta()}
                    {renderAckScreenCta()}
                    {this.renderDistributorSwitch(values)}
                    {this.state[FIELD_NAMES.IS_DISTRIBUTOR.NAME] === "Yes" &&
                      renderReportAdditionalFields(values, setFieldValue)}
                    {renderLandingPageURL(values)}
                    {renderFinePrintText()}
                    {renderSidebarText(
                      this.state.initialValues[FIELD_NAMES.SIDEBAR_TEXT.NAME],
                      setFieldValue,
                      this.editorSidebarRef
                    )}
                    {renderOfferSummaryText(
                      this.state.initialValues[
                        FIELD_NAMES.OFFER_SUMMARY_TEXT.NAME
                      ],
                      setFieldValue,
                      this.editorRef
                    )}
                    {renderContractUrl()}
                    {renderColorCodes(this.assetsColorFieldChanges)}
                    {this.renderSubmitButton(validateForm, handleSubmit)}
                    {this.renderAdminPasswordField(
                      isValid,
                      handleSubmit,
                      values,
                      setFieldValue
                    )}
                  </Form>
                )}
              </Formik>
            </div>
            {renderSvgAssets()}
          </div>
        </div>
        {loading && <Loader data-testid="Loader" />}
      </>
    );
  }
}
export default WhiteLabelAccount;
