export const ERR = "error";
export const UPLOADED = "uploaded";
export const FULFILLED = "fulfilled";
export const PENDING = "pending";

export const VALID_FILE_TYPE = [
  "pdf",
  "xls",
  "xlsx",
  "csv",
  "tsv",
  "txt",
  "numbers",
];
