import React, { Component } from "react";
import { get } from "lodash";
import moment from "moment";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import styles from "./OfferSummary.module.scss";
import LoginHeader from "../../../component/LoginHeader";
import HorizontalMenu from "../../../component/HorizontalMenu";
import ReactTooltip from "react-tooltip";
import Loader from "../../../component/Loader";
import MenuBar from "../../../component/MenuBar";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  DASHBOARD,
  OFFER_SUMMARY_API,
  ADJUST_TERMS,
  ACCEPT_OFFER,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { getErrorMessage } from "../helper";
import {
  numberWithCommas,
  getAdvanceOptionalCount,
} from "../YourAdvance/helper";
import HTMLReactParser from "html-react-parser";
import { ADJUST_DEAL_TOOLTIP } from "./constants";

class OfferSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitLoader: false,
      offerStage: {},
      offer: {},
      offerMessage: "",
    };
  }

  componentDidUpdate() {
    setTitle("Offer Summary", this.context);
  }

  componentDidMount() {
    this.getAdvanceData();
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          this.setState({
            offerStage: res.data.offerStage,
            offer: get(res.data, "offer", {}),
            offerMessage: res.data.approval_message,
          });
          this.checkSteps(
            res.data,
            get(res.data, "offerStage.verificationStatus"),
            get(res.data, "offer_approved", undefined),
            get(this.props, "location.state.fromDashboard")
          );
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  checkSteps = (data, verificationStatus, isOfferApproved, fromDashboard) => {
    if (!isOfferApproved) {
      this.props.history.push(DASHBOARD);
      return false;
    }
    if (fromDashboard) {
      if (verificationStatus.adjustTerms) {
        this.props.history.push({
          pathname: ACCEPT_OFFER,
          state: { reviewData: data },
        });
      } else if (verificationStatus.offerSummary) {
        this.props.history.push(ADJUST_TERMS);
      }
    }
    return true;
  };

  handleSubmit = (adjustTerms = false) => {
    this.setState({ submitLoader: true });
    const payload =
      adjustTerms === true ? { offerSummary: true } : { adjustTerms: true };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFER_SUMMARY_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          submitLoader: false,
        });
        if (res && res.status) {
          adjustTerms === true
            ? this.props.history.push(ADJUST_TERMS)
            : this.props.history.push(ACCEPT_OFFER);
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(getErrorMessage(err));
      });
  };

  getButtons = () => (
    <>
      <div>
        <button
          onClick={() => this.handleSubmit()}
          className={styles.primary}
          disabled={this.state.loading}
          data-testid="confirmTerms"
        >
          I want these terms
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            this.handleSubmit(true);
          }}
          className={styles.secondary}
          disabled={this.state.loading}
          data-testid="adjustTerms"
        >
          Adjust my terms{" "}
          <i
            className={styles.infoIcon}
            data-tip={ADJUST_DEAL_TOOLTIP}
            data-for="adjustTooltip"
            data-place="top"
          ></i>
        </button>
        <ReactTooltip
          class={styles.tooltip}
          effect="solid"
          place="top"
          id="adjustTooltip"
        />
      </div>
    </>
  );

  render() {
    const { offer } = this.state;
    return (
      <>
        <LoginHeader />
        <div className={styles.pageContainer}>
          <MenuBar
            offerStage={this.state.offerStage}
            hideSidebar
            {...this.props}
          />
          <div className={styles.mainContainer}>
            <HorizontalMenu
              offerStage={this.state.offerStage}
              className={styles.menuContainer}
              isApprovedFlow
              {...this.props}
            />
            <div className={styles.scrollContainer}>
              <div className={styles.container}>
                <div className={styles.title}>
                  <h1>Confirmed Offer Summary</h1>
                </div>

                <div
                  className={`${styles.detailsContainer} ${
                    this.state.loading ? "d-none" : ""
                  }`}
                >
                  <div className={styles.totalContent}>
                    <p className={styles.totalNumber}>
                      ${numberWithCommas(get(offer, "total_advance", 0))} Total
                    </p>
                    <span className="textMuted textSmall">
                      Valid through{" "}
                      {moment(get(offer, "expire")).format("MMMM, DD, YYYY")}
                    </span>
                    <p className={styles.sliderWordings}>
                      {offer.works}, {offer.term_length} year term{" "}
                      {offer.catalog}% flow through on all income
                    </p>
                  </div>
                  <div className={styles.digitContainer}>
                    <table className={styles.digitContent}>
                      <tr className={!offer.signing_advance ? "d-none" : ""}>
                        <td>${numberWithCommas(offer.signing_advance)}</td>
                        <td>
                          <div>Upfront advance</div>
                        </td>
                      </tr>
                      <tr className={!offer.upon_release ? "d-none" : ""}>
                        <td>${numberWithCommas(offer.upon_release)}</td>
                        <td>
                          <div>
                            Advance on release of{" "}
                            {getAdvanceOptionalCount(offer.works)} new tracks
                          </div>
                        </td>
                      </tr>
                      <tr className={!offer.option_advance ? "d-none" : ""}>
                        <td>${numberWithCommas(offer.option_advance)}</td>
                        <td>
                          <div>
                            Optional advance for{" "}
                            {getAdvanceOptionalCount(offer.works, true)} more
                            tracks
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>{parseInt(get(offer, "royalty", 0))}%</td>
                        <td>
                          <div>
                            Recoupment rate{" "}
                            <i
                              className={`${styles.infoIcon} ${styles.infoWhite}`}
                              data-tip={`This is the percentage of your distribution revenue that
                                we collect that goes toward recouping your advance.`}
                              data-for="royaltyTooltip"
                              data-place="top"
                            ></i>
                            <ReactTooltip
                              class={styles.tooltip}
                              effect="solid"
                              place="top"
                              id="royaltyTooltip"
                            />
                          </div>
                        </td>
                      </tr>
                    </table>
                    <div className={styles.htmlContent}>
                      {HTMLReactParser(
                        get(this.context, "offerSummaryText", "")
                      )}
                    </div>
                  </div>
                  <div className={styles.customizedContent}>
                    <div className={styles.recoupedContainer}>
                      <p>If advance is recouped before term, artist gets:</p>
                      <ul>
                        <li>
                          Catalog Income: {offer.income_catalog_post_recoupment}
                          %
                        </li>
                        {offer.income_future_post_recoupment ? (
                          <li>
                            New Music Income:{" "}
                            {offer.income_future_post_recoupment}%
                          </li>
                        ) : null}
                      </ul>
                      <p>After term and advance recouped:</p>
                      <ul>
                        <li>Artist keeps 100% of Income</li>
                      </ul>
                    </div>
                    {this.state.offerMessage && (
                      <div className={styles.artistMsg}>
                        <i>i</i>
                        <p>{this.state.offerMessage}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={`${styles.formMainButtons}`}>
                  <div className={`${styles.buttonContainer}`}>
                    {this.getButtons()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.submitLoader && <Loader light backgroundNone />}
        {this.state.loading && <Loader light backgroundNone />}
      </>
    );
  }
}

OfferSummary.contextType = ThemeContext;
export default OfferSummary;
