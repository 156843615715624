import React, { Component } from "react";
import { get } from "lodash";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import styles from "./AcceptOffer.module.scss";
import LoginHeader from "../../../component/LoginHeader";
import HorizontalMenu from "../../../component/HorizontalMenu";
import ReactTooltip from "react-tooltip";
import Loader from "../../../component/Loader";
import MenuBar from "../../../component/MenuBar";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  DASHBOARD,
  OFFER_SUMMARY_API,
  ADJUST_TERMS,
  EMAIL_OFFER_API,
  OFFER_CONTRACT_PDF,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { getErrorMessage } from "../helper";
import ContactPopup from "../../../component/ContactPopup";
import {
  ACCEPT_OFFER_TITLE,
  UNITED_STATES_COUNTRY,
  ACCEPT_OFFER_TOOLTIP,
} from "./constants";
import dataURItoBlob from "../MyAccount/dataURItoBlob";

class AcceptOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submitLoader: false,
      offerStage: {},
      offer: {},
      pdfUrl: "",
      pdfLoading: false,
      isContactOpen: false,
      isLocationUS: false,
    };
  }

  componentDidUpdate() {
    setTitle(ACCEPT_OFFER_TITLE, this.context);
  }

  componentDidMount() {
    this.getAdvanceData();
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false&isLocationNeeded=true`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          this.setState(
            {
              offerStage: res.data.offerStage,
              offer: get(res.data, "offer", {}),
              isLocationUS:
                get(res, "data.userCountry", "").toLowerCase() ===
                UNITED_STATES_COUNTRY,
            },
            this.getOfferPdfData
          );

          this.checkSteps(
            res.data,
            get(res.data, "offerStage.verificationStatus"),
            get(res.data, "offer_approved", undefined)
          );
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  checkSteps = (data, verificationStatus, isOfferApproved) => {
    if (!isOfferApproved || get(data, "offerStage.defaultOffer.isAccepted")) {
      this.props.history.push(DASHBOARD);
      return false;
    }
    if (!verificationStatus.adjustTerms) {
      this.props.history.push({
        pathname: ADJUST_TERMS,
        state: { reviewData: data },
      });
    }
    return true;
  };

  getOfferPdfData = () => {
    this.setState({ pdfLoading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFER_CONTRACT_PDF}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ pdfLoading: false });
        if (res.status) {
          let blob = {};
          let pdfUrl = "";
          if (
            res.data.approved_offer_pdf &&
            get(res.data, "approved_offer_pdf", "").includes("base64")
          ) {
            blob = dataURItoBlob(res.data.approved_offer_pdf);
            pdfUrl = URL.createObjectURL(blob);
          }
          this.setState({
            pdfUrl,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
      });
  };

  downloadOffer = () => {
    const a = document.createElement("a");
    a.href = this.state.pdfUrl;
    a.download = this.state.pdfUrl.split("/").pop() || "offer.pdf";
    a.style.display = "none";
    a.target = "_blank";
    document.body.append(a);
    a.click();
    document.body.removeChild(a);
  };

  emailOffer = () => {
    this.setState({ submitLoader: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${EMAIL_OFFER_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          submitLoader: false,
        });
        if (res && res.status) {
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(getErrorMessage(err));
      });
  };

  handleSubmit = () => {
    this.setState({ submitLoader: true });
    const payload = { isAccepted: true };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFER_SUMMARY_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          submitLoader: false,
        });
        if (res && res.status) {
          toast.success(get(res, "message"));
          window.location.href = this.state.isLocationUS
            ? get(this.context, "getContractUrl.nonInternationalUrl", "")
            : get(this.context, "getContractUrl.internationalUrl", "");
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ submitLoader: false });
        toast.error(getErrorMessage(err));
      });
  };

  getButtons = () => (
    <>
      <button
        onClick={this.emailOffer}
        className={styles.secondary}
        disabled={this.state.loading}
        data-testid="emailPdf"
      >
        Email This Offer
      </button>
      <button
        onClick={this.downloadOffer}
        className={styles.secondary}
        disabled={this.state.loading || !this.state.pdfUrl}
        data-testid="downloadPdf"
      >
        Download This Offer
      </button>
      <button
        onClick={this.handleSubmit}
        className={styles.primary}
        data-testid="getContract"
      >
        Get Contract
      </button>
    </>
  );

  pdfLoading = () => (
    <div className={`d-flex justify-content-center`}>
      <div className={`spinner-border spinner-border-sm`} />
    </div>
  );

  handleContactToggle = () => {
    this.setState({ isContactOpen: !this.state.isContactOpen });
  };

  render() {
    return (
      <>
        <LoginHeader />
        <div className={styles.pageContainer}>
          <MenuBar
            offerStage={this.state.offerStage}
            hideSidebar
            {...this.props}
          />
          <div className={styles.mainContainer}>
            <HorizontalMenu
              offerStage={this.state.offerStage}
              className={styles.menuContainer}
              isApprovedFlow
              {...this.props}
            />
            <div className={styles.scrollContainer}>
              <div className={styles.container}>
                <div className={styles.title}>
                  <h1>
                    Accept Offer
                    <i
                      className={styles.infoIcon}
                      data-tip={ACCEPT_OFFER_TOOLTIP}
                      data-for="acceptOfferTooltip"
                      data-place="top"
                    ></i>
                  </h1>
                  <ReactTooltip
                    class={styles.tooltip}
                    effect="solid"
                    place="top"
                    id="acceptOfferTooltip"
                  />
                </div>
                <p className={styles.subtitle}>
                  Please carefully read this offer summary. It's likely to
                  answer many of your questions. Then click one of the buttons
                  below.
                  <br />
                </p>
                <div
                  className={`${styles.detailsContainer} ${
                    this.state.loading ? "d-none" : ""
                  }`}
                >
                  {this.state.pdfLoading && (
                    <div className={styles.pdfContainer}>
                      {this.pdfLoading()}
                    </div>
                  )}
                  <iframe
                    src={`${this.state.pdfUrl}`}
                    title="Offer Pdf"
                    width="100%"
                    className={`${styles.pdfContainer} ${
                      this.state.pdfLoading ? "d-none" : ""
                    }`}
                  ></iframe>
                </div>
                <div className={`${styles.formMainButtons}`}>
                  <div className={`${styles.buttonContainer}`}>
                    {this.getButtons()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.submitLoader && <Loader light backgroundNone />}
        {this.state.loading && <Loader light backgroundNone />}
        <ContactPopup
          isOpen={this.state.isContactOpen}
          onRequestClose={this.handleContactToggle}
        />
      </>
    );
  }
}

AcceptOffer.contextType = ThemeContext;
export default AcceptOffer;
