import React, { Component } from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { Form } from "formik";
import * as Yup from "yup";

import Header from "../../../../component/Header";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import styles from "../Auth.module.scss";
import { API_URL, AUTH, LOGIN, FORGOT_PASS_API } from "../../constants";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import { EMAIL_REGEX } from "../constants";
import request from "../../../../utils/request";
import { getErrorMessage, forbiddenCheck } from "../../helper";
import { userExists } from "../../../../utils/Helper";
import LoginHeader from "../../../../component/LoginHeader";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      successCall: false,
      identifier: "",
    };
  }
  componentDidMount() {
    forbiddenCheck();
  }

  componentDidUpdate() {
    setTitle("Forgot Password", this.context);
  }

  validationSchema = Yup.object().shape({
    identifier: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
  });

  handelSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      emailOrPhone: values.identifier,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${FORGOT_PASS_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        res.status
          ? this.setState({
              successCall: true,
              identifier: EMAIL_REGEX.test(values.identifier)
                ? "email"
                : "phone",
            })
          : toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  render() {
    return (
      <div className="theme-bg-dark">
        {userExists() ? <LoginHeader /> : <Header />}
        <div className={styles.loginContent}>
          <FormikForm
            initialValues={{ identifier: "" }}
            validationSchema={this.validationSchema}
            onSubmit={this.handelSubmit}
          >
            <div className={styles.formContainer}>
              <Form>
                <h1>Get a new password.</h1>
                <p className={styles.subTitle}>
                  {this.state.successCall &&
                    "Instructions for creating a new password have been mailed to you."}
                </p>
                {!this.state.successCall ? (
                  <>
                    <div className="form-group form-group-m0 mb-0">
                      <FormField
                        name="identifier"
                        placeholder="Email address"
                        label="Email address"
                      />
                    </div>
                    <div className="form-group mb-0">
                      <button type="submit" disabled={this.state.loading}>
                        Send me a new password
                      </button>
                      {!userExists() && (
                        <Link className={styles.formLink} to={LOGIN}>
                          Return to login
                        </Link>
                      )}
                    </div>
                  </>
                ) : (
                  <div className={styles.resendContainer}>
                    <span>Did not receive any text or mail? </span>
                    <button type="submit">Resend</button>
                  </div>
                )}
              </Form>
            </div>
          </FormikForm>
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

ForgotPassword.contextType = ThemeContext;
export default ForgotPassword;
