import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import ReactTooltip from "react-tooltip";
import styles from "./MenuBar.module.scss";
import StorageService from "../../utils/StorageService";
import Navigator from "../Navigation/Navigator";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";
import { VERIFIED } from "./constants";
import Image from "../Image";
import { IMAGE_TYPE } from "../Image/constants";
import moment from "moment";
import HTMLReactParser from "html-react-parser";
import { SEND_REPORTS } from "../../routes/pages/constants";

let profileImg;
let activeArtist;
class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileImg: "",
      activeArtist: "",
      pathname: "",
      moreOrLess: "more",
    };
  }
  componentDidMount() {
    this.setState({ pathname: this.props.history.location.pathname });
  }
  componentDidUpdate() {
    profileImg = StorageService.get("profileImg");
    activeArtist = StorageService.get("activeArtist");
  }

  getRoyaltyNumber = (data) => parseInt(get(data, "royalty", 0));

  getEstimatedDigits = () => {
    if (this.props.currentData) {
      return (
        <>
          <div className={styles.odometerContainer}>
            $
            <Odometer
              value={this.props.currentData.total_advance}
              format="(,ddd).dd"
              duration={400}
            />
            <span className={`${styles.countText} ${styles.textBig}`}>
              total
            </span>
          </div>
          <div className={styles.otherValues}>
            <ul>
              <li
                className={`${
                  !this.props.currentData.signing_advance ? "d-none" : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.currentData.signing_advance}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>Upfront</span>
              </li>
              <li
                className={`${
                  !this.props.currentData.upon_release ? "d-none" : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.currentData.upon_release}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>New release advance</span>
              </li>
              <li
                className={`${
                  !this.props.currentData.option_advance ? "d-none" : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.currentData.option_advance}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>Option advance</span>
              </li>
              <li
                className={`${
                  !parseFloat(this.props.currentData.royalty) ? "d-none" : ""
                }`}
              >
                <div>
                  {}
                  <Odometer
                    value={this.getRoyaltyNumber(this.props.currentData)}
                    format="(,ddd).dd"
                    duration={400}
                  />
                  %
                </div>
                <span className={styles.countText}>
                  Recoupment rate{" "}
                  <i
                    className={`${styles.infoIcon} ml-2`}
                    data-tip
                    data-for="royaltyPercent"
                  ></i>
                  <ReactTooltip
                    className={styles.tooltip}
                    delayHide={200}
                    id="royaltyPercent"
                    effect="solid"
                  >
                    <span className={styles.royaltyTooltip}>
                      This is the percentage of your distribution revenue that
                      we collect that goes toward recouping your advance.
                    </span>
                  </ReactTooltip>
                </span>
              </li>
            </ul>
          </div>
        </>
      );
    }
    if (
      (this.props.isDemo &&
        !!get(this.props.offerStage, "defaultOffer") &&
        get(this.props, "history.location.pathname") === SEND_REPORTS) ||
      get(this.props.offerStage, "verificationStatus.streamingData.value") ===
        VERIFIED
    ) {
      return (
        <>
          <div className={styles.odometerContainer}>
            $
            <Odometer
              value={this.props.offerStage.defaultOffer.totalAdvance}
              format="(,ddd).dd"
              duration={400}
            />
            <span className={`${styles.countText} ${styles.textBig}`}>
              total
            </span>
          </div>
          <div className={styles.otherValues}>
            <ul>
              <li
                className={`${
                  !this.props.offerStage.defaultOffer.upfront ? "d-none" : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.offerStage.defaultOffer.upfront}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>Upfront</span>
              </li>
              <li
                className={`${
                  !this.props.offerStage.defaultOffer.newRelease ? "d-none" : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.offerStage.defaultOffer.newRelease}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>New release advance</span>
              </li>
              <li
                className={`${
                  !this.props.offerStage.defaultOffer.optionalRelease
                    ? "d-none"
                    : ""
                }`}
              >
                <div>
                  $
                  <Odometer
                    value={this.props.offerStage.defaultOffer.optionalRelease}
                    format="(,ddd).dd"
                    duration={400}
                  />
                </div>
                <span className={styles.countText}>Option advance</span>
              </li>
              <li
                className={`${
                  !parseFloat(this.props.offerStage.defaultOffer.royalty)
                    ? "d-none"
                    : ""
                }`}
              >
                <div>
                  <Odometer
                    value={this.getRoyaltyNumber(
                      this.props.offerStage.defaultOffer
                    )}
                    format="(,ddd).dd"
                    duration={400}
                  />
                  %
                </div>
                <span className={styles.countText}>
                  Recoupment rate{" "}
                  <i
                    className={`${styles.infoIcon} ml-2`}
                    data-tip
                    data-for="royaltyPercent"
                  ></i>
                  <ReactTooltip
                    className={styles.tooltip}
                    delayHide={200}
                    id="royaltyPercent"
                    effect="solid"
                  >
                    <span className={styles.royaltyTooltip}>
                      This is the percentage of your distribution revenue that
                      we collect that goes toward recouping your advance.
                    </span>
                  </ReactTooltip>
                </span>
              </li>
            </ul>
          </div>
        </>
      );
    }
    return <div className={styles.odometerContainer}>$--,--- Total</div>;
  };

  toggleSeeMore = () => {
    this.setState((prevState) => ({
      moreOrLess: prevState.moreOrLess === "more" ? "less" : "more",
    }));
    document
      .getElementsByClassName(`${styles.digitDetails}`)[0]
      .classList.toggle(`${styles.showMore}`);
    document
      .getElementsByClassName(`${styles.seeMoreLnk}`)[0]
      .classList.toggle(`${styles.showMore}`);
  };

  getArtistShareData = () =>
    this.props.currentData && (
      <div className={`${styles.menuItems}`}>
        <div className={styles.artistShare}>
          <div>
            <p className={styles.offerTitle}>
              If advance is recouped before term, Artist gets:
            </p>
            <li>
              <span>
                Catalog Income:{" "}
                {this.props.currentData.income_catalog_post_recoupment}%
              </span>
            </li>
            {this.props.currentData.income_future_post_recoupment && (
              <li>
                <span>
                  New Music Income:{" "}
                  {this.props.currentData.income_future_post_recoupment}%
                </span>
              </li>
            )}
          </div>
          <div>
            <p className={styles.offerTitle}>
              After term and advance recouped:
            </p>
            <li>
              <span>Artist keeps 100% of Income</span>
            </li>
          </div>
        </div>
      </div>
    );

  renderSidebarText = () => {
    return (
      <div className={styles.sidebarTextContainer}>
        {HTMLReactParser(get(this.context, "sidebarText", ""))}
      </div>
    );
  };

  render() {
    return (
      <div
        className={`${styles.container} ${
          this.props.hideSidebar ? styles.summaryContainer : ""
        }`}
      >
        <div className={styles.profile} title={activeArtist}>
          <div>
            {profileImg && (
              <Image
                src={profileImg}
                alt="Profile"
                imageType={IMAGE_TYPE.PROFILE}
              />
            )}
          </div>
          <div>
            <p className={`mb-0`}>{activeArtist}</p>
          </div>
        </div>
        <div className={styles.digitDetails}>
          <div className={styles.menuToolTip}>
            <p className={`mb-0`}>
              {this.props.isAdjustTerm
                ? `Confirmed Offer`
                : `Estimated Funding`}
            </p>
            <i
              className={`${styles.infoIcon}`}
              data-tip
              data-for="estimatedFunding"
            ></i>
            <ReactTooltip
              className={styles.tooltip}
              delayHide={200}
              id="estimatedFunding"
              type="dark"
              effect="solid"
              place="right"
            >
              {this.props.isAdjustTerm ? (
                <div className={`textSize14`}>
                  Your data has been validated and these amounts are confirmed.
                  The amounts will change as you adjust the sliders on this
                  page.
                </div>
              ) : (
                <div className={`textSize14`}>
                  Many artists get even more than their estimates, once we
                  validate your official distribution reports.
                </div>
              )}
            </ReactTooltip>
            {get(this.props.currentData, "expire") && (
              <span className={styles.validThrough}>
                Valid through{" "}
                {moment(get(this.props.currentData, "expire")).format(
                  "MM/DD/YYYY"
                )}
              </span>
            )}
            {get(
              this.props.offerStage,
              "verificationStatus.streamingData.value"
            ) === VERIFIED &&
              get(this.props.offerStage.defaultOffer, "expire") &&
              !get(this.props.currentData, "expire") && (
                <span className={styles.validThrough}>
                  Valid through{" "}
                  {moment(
                    get(this.props.offerStage.defaultOffer, "expire")
                  ).format("MM/DD/YYYY")}
                </span>
              )}
          </div>
          <div className={styles.youraccountCount}>
            {this.getEstimatedDigits()}
          </div>
          {this.getArtistShareData()}
          {get(this.context, "sidebarText", "").length > 0 &&
            this.renderSidebarText()}
        </div>
        <div className={styles.menuContainer}>
          <Navigator
            {...this.props}
            customClassName={styles.hamburgerIcon}
            isArtistPage
          />
        </div>
        <div className={styles.seeMoreLnk} onClick={this.toggleSeeMore}>
          <p>Show {this.state.moreOrLess}</p>
          <span></span>
        </div>
      </div>
    );
  }
}

MenuBar.contextType = ThemeContext;
export default MenuBar;

MenuBar.contextTypes = {
  sidebarText: PropTypes.string,
};
