import styleVariables from "../../styles/variables.scss";
import varStyleGlobal from "../../styles/global.scss";
const selectStyle = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    maxWidth: 300,
    textAlign: "left",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: `#5d5d5d`,
    fontWeight: "600",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 2,
    padding: "3px 0",
    border: "none",
    boxShadow: "unset",
    borderBottom: `1px solid #7d7d7d`,
    height: 50,
    "&:hover": {
      borderBottom: `1px solid #5d5d5d`,
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 2,
    maxHeight: 200,
    zIndex: 5,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
  }),
};

export const selectFormStyle = {
  ...selectStyle,
  control: (provided) => ({
    ...provided,
    ...selectStyle.control(),
    borderBottom: `1px solid ${varStyleGlobal.darkGreyColor}`,
    "&:hover": {
      borderBottom: `1px solid ${varStyleGlobal.darkGreyColor}`,
    },
  }),
  container: (provided) => ({
    ...provided,
    maxWidth: "100%",
    color: styleVariables.black,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: styleVariables.grey,
  }),
};

export default selectStyle;
