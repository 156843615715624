import React from "react";
import { get } from "lodash";
import styles from "./SendReports.module.scss";

export const getRadioInformationText = (displayName) =>
  `Would you like ${displayName} to share your earnings reports?`;

export const getRadioText = (displayName) => {
  return {
    trueValue: `Yes, ${displayName} can share my reports.`,
    falseValue: `None of my music is on ${displayName}`,
  };
};

export const OTHER_DISTRIBUTOR = "other";

const getFormattedDate = (id) => {
  const date = new Date(parseInt(id.substring(0, 8), 16) * 1000);
  return `(${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()})`;
};

export const areBothFieldsExist = (context) =>
  get(context.reportFields, "label1", "") &&
  get(context.reportFields, "label2", "");
export const isOneFieldRequired = (context) =>
  get(context.reportFields, "isField1Required", false) ||
  get(context.reportFields, "isField2Required", false);

export const reportTableListRender = (
  listLoading,
  sentReports,
  handleDelete
) => (
  <div className={styles.reportListContainer}>
    <p>Previously uploaded reports:</p>
    {listLoading && (
      <div className={styles.noFiles}>
        <i className={`spinner-border spinner-border-sm`}></i>
      </div>
    )}
    {!listLoading && sentReports.length ? (
      <table className={`${styles.sentReportList}`}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Distributor</th>
            <th>Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sentReports.map((report) => (
            <tr
              key={report._id}
              className={report.isDeleted ? styles.deleted : ""}
            >
              <td>
                {report.originalName}
                {report.isDeleted && ` (Deleted)`}
              </td>
              <td>
                {report.isOther
                  ? `Other: ${report.distributorName}`
                  : report.distributorName}
              </td>
              <td>{getFormattedDate(get(report, "_id", ""))}</td>
              <td>
                {!report.isDeleted && (
                  <span
                    onClick={() => {
                      handleDelete(report);
                    }}
                  >
                    Delete
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : null}
    {!listLoading && !sentReports.length && (
      <div className={`${styles.noFiles}`}>No data to show</div>
    )}
  </div>
);

export const OVERALL_STATUS = [
  "complete",
  "failed_reports",
  "failed_tracks",
  "error",
  "no_reports_processed",
];

export const MUSIC_NOTE_ICON =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAmCAYAAABQ3RJZAAAAAXNSR0IArs4c6QAAAXFJREFUWEftl0EKhDAMRWfwEIIncuPCK4jgmQTxCi5E8ESCh5AZUujQGbT5HWOLUDduYvr6fxrT5+OGz/OGzI8I7cu14Eqv6/oax1Htt65riAcKklRQQw7DoNLO8/xJv20bxAMFuUITmPkNKUmQJuBeTq/QGlLbvKcisvHLoW02I4BBlE6S5KsE/gU1v7tcaVfoPM8VX1mW6l0UhXpnWebvIHLQBPkLqOnSNP00ADOPd6U1JCloQnFlEwx6WRYnUHMjwaBRW/dUj9BX1GJUmlNVQiHzD6pnEOo4XgcmlwNEwFVVhR+YUGgUmNxEuxA7mp61teu6V9M0UAWKQKMq2RbzCo0Cc7Z6hZZaDM1DnWSaJrZcSaTDIHQxTmlyzBw/j4q7bdvzF1tJaLqG2Q4jqdz3PTx0nVYatfVIBFdga3lI2/p7+dVl4jp/s9DStkLNGghiT6ukrQAPFMJCS9oKEQFBLDSQw3tIhPYleVQ6Km1R4A08x0g2NWL5sQAAAABJRU5ErkJggg==";

export const TYPE_PARTIAL = "partial";
export const TYPE_COMPLETE = "complete";
export const TYPE_MISSING = "missing";
export const DROPDOWN_OPTIONS = [
  { label: "Exclude this track", value: "Exclude this track" },
  { label: "Track included in other", value: "Track included in other" },
  { label: "Let me explain...", value: "" },
];

export const ROUTE_STATUS = [
  "success",
  "month-missing",
  "track-missing",
  "error",
];
