import { get } from "lodash";
import {
  FACEBOOK_PLATFORM,
  FAILED_TO_FETCH,
  INSTAGRAM_PLATFORM,
  WIKIPEDIA_PLATFORM,
  YOUTUBE_PLATFORM,
  API_URL,
} from "./constants";
import request from "../../utils/request";

export const getErrorMessage = (err) => {
  if (get(err, "message") === "Failed to fetch") {
    return FAILED_TO_FETCH;
  }
  return get(err, "message");
};

export const getSocialMediaPayload = (
  instagramUrl,
  facebookUrl,
  youtubeUrl,
  wikipediaUrl
) => {
  const socialMedia = [];
  if (instagramUrl) {
    socialMedia.push({
      platform: INSTAGRAM_PLATFORM,
      url: instagramUrl,
    });
  }
  if (facebookUrl) {
    socialMedia.push({
      platform: FACEBOOK_PLATFORM,
      url: facebookUrl,
    });
  }
  if (youtubeUrl) {
    socialMedia.push({
      platform: YOUTUBE_PLATFORM,
      url: youtubeUrl,
    });
  }
  if (wikipediaUrl) {
    socialMedia.push({
      platform: WIKIPEDIA_PLATFORM,
      url: wikipediaUrl,
    });
  }
  return socialMedia;
};

export const forbiddenCheck = () => {
  const data = {
    method: "GET",
  };
  const requestURL = `${API_URL}`;
  request(requestURL, data).catch();
};

export const hubSpotTracking = () => {
  const _hsq = window._hsq || [];
  _hsq.push([
    "setPath",
    `${window.location.pathname}${window.location.search}${window.location.hash}`,
  ]);
  _hsq.push(["trackPageView"]);
};
