import React from "react";
import "./Switch.css";

const Switch = ({ id, isOn, handleToggle, onColor, title, labelClassName }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={id}
        type="checkbox"
      />
      <label
        title={title}
        style={{ background: isOn && onColor }}
        className={`react-switch-label ${labelClassName}`}
        htmlFor={id}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;
