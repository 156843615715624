import React from "react";
import styles from "./Loader.module.scss";

const ShortCallLoader = ({ text, loaderTitle }) => {
  return (
    <div className={`${styles.container} ${styles.light}`}>
      <div className={styles.box}>
        <div className={styles.textContent}>
          <h3>
            {`${loaderTitle || "Just a moment."}`}
            <br />
            {`${text || "Generating your advance options."}`}
          </h3>
          <div className={styles.spinner}>
            <div className={styles.bounce1}></div>
            <div className={styles.bounce2}></div>
            <div className={styles.bounce3}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortCallLoader;
