import React from "react";
import { get, findIndex } from "lodash";
import * as Yup from "yup";
import moment from "moment";
import ReactModal from "react-modal";
import styles from "./SendReports.module.scss";
import { SOCIAL_NETWORKS, SEND_REPORTS } from "../constants";
import {
  MUSIC_NOTE_ICON,
  TYPE_PARTIAL,
  DROPDOWN_OPTIONS,
  TYPE_MISSING,
  ROUTE_STATUS,
} from "./constants";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
} from "@material-ui/core";
import Image from "../../../component/Image";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import { MUSIC_ICON } from "../../../component/MaterialIcons/constants";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { LineChart, Line } from "recharts";
import generalColors from "../../../styles/global.scss";
import { getShortNumber } from "../YourAdvance/helper";
import { Formik, Form } from "formik";
import FormField from "../../../component/FormField/FormField";
import Select from "react-select";

export const AckSuccessModal = ({ isOpen, history }) => (
  <ReactModal
    isOpen={isOpen}
    className={styles.deleteModal}
    onAfterOpen={() =>
      history.push(`${SEND_REPORTS}?status=${ROUTE_STATUS[0]}`)
    }
    overlayClassName={styles.modalOverlay}
  >
    <div className={styles.deleteModalContainer}>
      <h2 className="mb-4">Thanks! </h2>
      <p>
        It looks like we have what we need on the tracks you selected earlier in
        the process.
      </p>
      <p>
        We'll send you an email or text when we've reviewed your reports and
        have a final offer.
      </p>
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.btnPrimary} m-0`}
          data-testid="ackSuccessBtn"
          onClick={() => {
            history.push(SOCIAL_NETWORKS);
          }}
        >
          Got it
        </button>
      </div>
    </div>
  </ReactModal>
);

export const AckReportIssueModal = ({
  isOpen,
  reportData = [],
  failedReports = [],
  handleClose,
  ...otherProps
}) => {
  const missingReport = [],
    partialReport = [];
  reportData.forEach((distributor) => {
    distributor.report.forEach((report) => {
      if (report.type === TYPE_MISSING) {
        missingReport.push({
          ...report,
          distributor_name: distributor.distributor_name,
          distributor_logo: distributor.distributor_logo,
        });
      } else if (report.type === TYPE_PARTIAL) {
        partialReport.push({
          ...report,
          distributor_name: distributor.distributor_name,
          distributor_logo: distributor.distributor_logo,
        });
      }
    });
  });

  const detailsRender = (reportsList, displayFailed) => (
    <div className={styles.missingReport}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={styles.headerCell}>DISTRIBUTOR</TableCell>
              <TableCell className={styles.headerCell} align="right">
                <div className={styles.toolIcon}>
                  <span>MISSING ACTIVITY PERIOD</span>
                  <i
                    className={`${styles.infoIcon}`}
                    data-tip
                    data-for="royaltyPercent"
                  ></i>
                </div>
              </TableCell>

              <TableCell className={styles.headerCell} align="right">
                <div className={styles.toolIcon}>
                  <span>LIKELY REPORT DATES</span>
                  <i
                    className={`${styles.infoIcon} `}
                    data-tip
                    data-for="royaltyPercent"
                  ></i>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsList.map((report, index) => (
              <TableRow>
                <TableCell className={styles.bodyCell}>
                  <div className={styles.distLogo}>
                    {report.distributor_logo ? (
                      <img
                        src={report.distributor_logo}
                        alt="distributor_logo"
                      />
                    ) : (
                      <p>{report.distributor_name}</p>
                    )}
                  </div>
                </TableCell>
                <TableCell className={styles.bodyCell}>
                  <div className={styles.details}>
                    <p>{moment(report.activity_date).format("MMMM YYYY")}</p>
                    <p className={styles.errorText}>
                      {get(report, "display_message", "")}
                    </p>
                  </div>
                </TableCell>
                <TableCell className={styles.bodyCell}>
                  <div className={styles.details}>
                    <p>{moment(report.report_date).format("MMMM YYYY")}</p>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {displayFailed &&
              failedReports.map((reportName, index) => (
                <TableRow>
                  <TableCell>
                    <div className={styles.otherLogo}>
                      <img src={MUSIC_NOTE_ICON} alt="distributor_logo" />
                      <p>Other</p>
                    </div>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <div className={styles.details}>
                      <p>{reportName}</p>
                      <p className={styles.otherErrorText}>
                        We cannot machine read these, our team will reach out
                        with any questions.
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return otherProps.isAdmin ? (
    <ReactModal
      isOpen={isOpen}
      className={`${styles.deleteModal} ${styles.ackIssueModal}`}
      overlayClassName={styles.modalOverlay}
    >
      <div className={`${styles.deleteModalContainer}`}>
        <h2 className="mb-4 text-left">Thanks for uploading your reports</h2>
        <p>
          It looks like we need a few more reports covering a broader time
          period than what you’ve provided.
        </p>
        {!!missingReport.length && (
          <>
            <p className={styles.ackSubtitle}>We are missing these reports:</p>
            {detailsRender(missingReport, true)}
          </>
        )}
        {!!partialReport.length && (
          <>
            <p className={styles.ackSubtitle}>These looks incomplete:</p>
            {detailsRender(partialReport, !missingReport.length)}
          </>
        )}
        <span
          className={styles.helpText}
          onClick={otherProps.handleToggleContact}
        >
          Need Help?
        </span>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.btnPrimary} m-0`}
            data-testid="ackSuccessBtn"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </div>
    </ReactModal>
  ) : (
    <ReactModal
      isOpen={isOpen}
      className={styles.deleteModal}
      onAfterOpen={() =>
        otherProps.history.push(`${SEND_REPORTS}?status=${ROUTE_STATUS[1]}`)
      }
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.deleteModalContainer}>
        <h2 className="mb-4">Thanks! </h2>
        <p>
          We may reach out to you if we have any questions or need more
          information about your reports
        </p>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.btnPrimary} m-0`}
            data-testid="ackSuccessBtn"
            onClick={() => {
              otherProps.history.push(SOCIAL_NETWORKS);
            }}
          >
            Got it
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

const isCollapseOpen = (label) => label === DROPDOWN_OPTIONS[2].label;

export const RowData = ({
  value,
  columns,
  track,
  setFieldValue,
  rowIndex,
  selectedLabel,
  isAdmin,
}) => (
  <>
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.id}
          className={styles.trackTableCell}
          align={column.align}
        >
          {column.format(track, value, setFieldValue, rowIndex)}
        </TableCell>
      ))}
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse
          in={isCollapseOpen(selectedLabel)}
          timeout="auto"
          unmountOnExit
        >
          <div className={`form-group`}>
            <FormField
              name={`tracks.${rowIndex}.reason`}
              label="Explanation"
              placeholder={`Explanation for '${track.track_name}'`}
              disabled={isAdmin}
            />
          </div>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
);

export const AckTrackIssueModal = ({
  isOpen,
  trackData = [],
  handleClose,
  handleSubmitApi,
  ...otherProps
}) => {
  const albumRender = (value) => (
    <div className={styles.trackDetail}>
      {value.image_url ? (
        <Image
          src={value.image_url}
          alt="album"
          imageType={IMAGE_TYPE.PROFILE}
        />
      ) : (
        <SvgIcons icon={MUSIC_ICON} />
      )}
      <p>{value.track_name}</p>
    </div>
  );

  const chartRender = (value) => (
    <div className={styles.chartData}>
      <LineChart
        width={80}
        height={30}
        data={get(value, "trend_line_data", []).map((value) => ({ value }))}
      >
        <Line
          dataKey="value"
          stroke={generalColors.primaryColor}
          dot={false}
          strokeWidth={3}
          isAnimationActive={false}
        />
      </LineChart>
      <p>{getShortNumber(value.streams_last_month)}</p>
    </div>
  );

  const dropDownStyle = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      maxWidth: 300,
      textAlign: "right",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: `#5d5d5d`,
    }),
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: "flex-end",
      fontWeight: "600",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: 2,
      padding: "3px 0",
      border: "none",
      boxShadow: "unset",
      height: 38,
      background: "transparent",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 2,
      maxHeight: 200,
      zIndex: 5,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: 200,
    }),
  };

  const optionValue = (value) =>
    DROPDOWN_OPTIONS[0].value === value || DROPDOWN_OPTIONS[1].value === value
      ? { value, label: value }
      : DROPDOWN_OPTIONS[2];

  const getDropdownValue = (value) => (value ? optionValue(value) : null);

  const dropDownRender = (track, value, setFieldValue, rowIndex) => (
    <div className={styles.issueCell}>
      <p className={styles.missingError}>
        Missing Reports <span>!</span>
      </p>
      <Select
        isSearchable={false}
        isDisabled={otherProps.isAdmin}
        classNamePrefix="action"
        styles={dropDownStyle}
        placeholder={`Select Action`}
        menuPosition="fixed"
        menuPlacement="bottom"
        options={DROPDOWN_OPTIONS}
        defaultValue={getDropdownValue(value)}
        components={{ IndicatorSeparator: () => null }}
        onChange={(option) => {
          setFieldValue(`tracks.${rowIndex}.reason`, option.value);
          setFieldValue(`tracks.${rowIndex}.label`, option.label);
        }}
      />
    </div>
  );

  const columns = [
    { id: "track", label: "TRACK", align: "left", format: albumRender },
    {
      id: "streams_last_month",
      label: "STREAMS LAST MONTH",
      align: "center",
      format: chartRender,
    },
    {
      id: "issues",
      label: "ISSUES",
      align: "right",
      format: dropDownRender,
    },
  ];

  const validationSchema = Yup.object().shape({
    tracks: Yup.array().of(
      Yup.object().shape({
        reason: Yup.string().max(1000).label("Explanation"),
        label: Yup.string(),
      })
    ),
  });

  const getLabelValue = (reason) =>
    reason &&
    (findIndex(DROPDOWN_OPTIONS, { label: reason }) === -1
      ? DROPDOWN_OPTIONS[2].label
      : reason);

  const initialValues = {
    tracks: [
      ...trackData.map((track) => ({
        reason: track.reason,
        label: getLabelValue(track.reason),
      })),
    ],
  };

  return otherProps.isAdmin ? (
    <ReactModal
      isOpen={isOpen}
      className={`${styles.deleteModal} ${styles.ackTrackIssueModal}`}
      overlayClassName={styles.modalOverlay}
    >
      <div className={`${styles.deleteModalContainer}`}>
        <h2 className="mb-4 text-left">Thanks for uploading your reports</h2>
        <p>
          It looks like there may be some songs that you might want included in
          the deal that we don’t have reports for.
        </p>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmitApi}
          data-testid="ackForm"
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <TableContainer className={styles.trackTable}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          className={styles.headerCell}
                          align={column.align}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trackData.map((track, index) => (
                      <RowData
                        key={track.isrc}
                        track={track}
                        value={get(values, `tracks.${index}.reason`)}
                        selectedLabel={get(values, `tracks.${index}.label`)}
                        columns={columns}
                        setFieldValue={setFieldValue}
                        rowIndex={index}
                        data-testid="rawTrackDetails"
                        isAdmin={otherProps.isAdmin}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <span
                className={styles.helpText}
                onClick={otherProps.handleToggleContact}
              >
                Need Help?
              </span>
              <div className={styles.buttonContainer}>
                <button
                  className={`${styles.btnPrimary} m-0`}
                  onClick={handleClose}
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ReactModal>
  ) : (
    <ReactModal
      isOpen={isOpen}
      className={styles.deleteModal}
      onAfterOpen={() =>
        otherProps.history.push(`${SEND_REPORTS}?status=${ROUTE_STATUS[2]}`)
      }
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.deleteModalContainer}>
        <h2 className="mb-4">Thanks! </h2>
        <p>
          We may reach out to you if we have any questions or need more
          information about your reports
        </p>
        <div className={styles.buttonContainer}>
          <button
            className={`${styles.btnPrimary} m-0`}
            data-testid="ackSuccessBtn"
            onClick={() => {
              otherProps.history.push(SOCIAL_NETWORKS);
            }}
          >
            Got it
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export const AckErrorModal = ({ isOpen, history }) => (
  <ReactModal
    isOpen={isOpen}
    className={styles.deleteModal}
    onAfterOpen={() =>
      history.push(`${SEND_REPORTS}?status=${ROUTE_STATUS[3]}`)
    }
    overlayClassName={styles.modalOverlay}
  >
    <div className={styles.deleteModalContainer}>
      <h2 className="mb-4">Thanks! </h2>
      <p>
        We may reach out to you if we have any questions or need more
        information about your reports
      </p>
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.btnPrimary} m-0`}
          data-testid="ackErrorBtn"
          onClick={() => {
            history.push(SOCIAL_NETWORKS);
          }}
        >
          Got it
        </button>
      </div>
    </div>
  </ReactModal>
);
