import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "./AcknowledgementScreen.module.scss";
import { ReactComponent as BeatBreadLogo } from "../../../../img//beatBreadLogo.svg";
import StorageService from "../../../../utils/StorageService";
import Image from "../../../../component/Image";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import {
  getSubDomain,
  setTitle,
  getPartnerName,
} from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import Register from "../Register/Register";
import { CONTACT_EMAIL } from "../../constants";

class AcknowledgementScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      artistDetails: StorageService.get("ackArtistDetails", {
        localStorage: true,
      }),
      profileUrl: StorageService.get("ackArtistImg"),
    };
  }

  componentDidUpdate() {
    setTitle("", this.context);
  }

  goToURL = () => {
    window.location.href = get(this.context, "acknowledgementCTA.actionUrl");
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.subContainer}>
          {BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 && (
            <header>
              <BeatBreadLogo />
              <p>beatBread</p>
            </header>
          )}
          <div className={styles.details}>
            <div className={styles.ackInfo}>
              <div className={styles.profile}>
                <Image
                  src={this.state.profileUrl}
                  alt={get(this.state.artistDetails, "name")}
                  imageType={IMAGE_TYPE.PROFILE}
                />
              </div>
              <h2>We're on it!</h2>
              <div className={styles.text}>
                <p>
                  We are currently processing a range of advance estimates for{" "}
                  <span className={styles.bold}>
                    {get(this.state.artistDetails, "name")}
                  </span>
                  . Look out for an email from us in the next 90 minutes or
                  less.
                </p>
                <p>
                  If you do not receive an email from us within 24 hours, please
                  check your spam folder. If you still don’t see it, please send
                  us an email at{" "}
                  <a
                    href={`mailto:${CONTACT_EMAIL.replace(
                      "[DOMAIN]",
                      String(getPartnerName()).toLowerCase()
                    )}`}
                  >
                    {CONTACT_EMAIL.replace(
                      "[DOMAIN]",
                      String(getPartnerName()).toLowerCase()
                    )}
                  </a>
                </p>
              </div>
              {get(this.context, "acknowledgementCTA.buttonText") && (
                <p className={styles.btn} onClick={this.goToURL}>
                  {get(this.context, "acknowledgementCTA.buttonText")}
                </p>
              )}
            </div>
            <Register
              artistId={get(this.state.artistDetails, "artistId")}
              verificationCode={get(this.state.artistDetails, "code")}
              className={styles.registerContainer}
              userId={get(this.state.artistDetails, "userId")}
              ackScreen={true}
              {...this.props}
            />
          </div>
        </div>
      </div>
    );
  }
}

AcknowledgementScreen.contextType = ThemeContext;
export default AcknowledgementScreen;

AcknowledgementScreen.contextTypes = {
  acknowledgementCTA: PropTypes.object,
};
