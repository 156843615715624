import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./ReactRange.module.scss";
import generalColors from "../../styles/global.scss";
import { Range, getTrackBackground } from "react-range";
import { numberWithCommas } from "../../routes/pages/YourAdvance/helper";

class ReactRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props.values || [0],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.values !== prevProps.values) {
      this.setState({ values: this.props.values });
    }
  }

  onChangeRange = (values) => {
    this.setState({ values });
    this.props.onChange(values);
  };

  getFormattedValue = () =>
    this.props.currencyValue
      ? numberWithCommas(this.state.values[0])
      : this.state.values;

  renderTrack = ({ props, children }) => (
    <div
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{
        ...props.style,
      }}
      className={`${this.props.className} ${styles.rangeTrackContainer}`}
    >
      <div
        ref={props.ref}
        style={{
          background: getTrackBackground({
            values: this.state.values,
            colors: [generalColors.sliderColor, generalColors.light],
            min: this.props.min,
            max: this.props.max,
          }),
        }}
        className={styles.rangeTrack}
      >
        {children}
      </div>
    </div>
  );

  renderThumb = ({ props }) => (
    <div
      {...props}
      style={{
        ...props.style,
      }}
      className={
        this.props.yourAdvance ? styles.advancerangeThumb : styles.rangeThumb
      }
    >
      <div
        className={`${styles.textValue} ${
          this.props.yourAdvance && styles.noSpace
        } ${this.props.yourAdvance && "d-none"}`}
      >
        {this.props.thumbText
          ? `${this.props.thumbText[0]} ${this.getFormattedValue()}${
              this.props.thumbText[1]
            }`
          : `${this.state.values}%`}
      </div>
    </div>
  );

  getValueClassName = () => {
    if (this.props.yourAdvance) {
      return this.props.valueAlign
        ? styles[`${this.props.valueAlign}`]
        : styles.valueCenter;
    }
    return "d-none";
  };

  render() {
    return (
      <div className={styles.valueContainer}>
        <span className={this.getValueClassName()}>
          {this.props.thumbText
            ? `${this.props.thumbText[0]} ${this.getFormattedValue()}${
                this.props.thumbText[1]
              }`
            : `${this.state.values}%`}
        </span>
        <Range
          values={this.state.values}
          step={this.props.step}
          min={this.props.min}
          max={this.props.max}
          onFinalChange={this.props.onFinalChange}
          onChange={this.onChangeRange}
          renderTrack={this.renderTrack}
          renderThumb={this.renderThumb}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

ReactRange.propTypes = {
  values: PropTypes.array,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  onFinalChange: PropTypes.func,
  thumbText: PropTypes.array,
  className: PropTypes.string,
};

export default ReactRange;
