import AuthTokenService from "./AuthTokenService";
import StorageService from "./StorageService";

export function userExists() {
  if (AuthTokenService.exists()) {
    AuthTokenService.init();
    return true;
  }
  return false;
}

export function logout() {
  window.location.reload();
  localStorage.removeItem("siteTheme");
  StorageService.delete("role");
  StorageService.delete("isAdminInMaintenance");
  AuthTokenService.clear();
}
