import React, { Component } from "react";
import { toast } from "react-toastify";
import { get, debounce } from "lodash";
import styles from "./YourAdvance.module.scss";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  DASHBOARD,
  OFFERS_API,
  DATA_REVIEW,
  SOCIAL_NETWORKS,
  SEND_REPORTS,
  TUNE_MY_DATA,
  ADJUST_TERMS,
  OFFER_SUMMARY,
} from "../constants";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import MenuBar from "../../../component/MenuBar";
import request from "../../../utils/request";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";

import {
  getMaxValue,
  getCurrentCombinationValue,
  getNewReleaseLabel,
  getDefaultOffer,
  getAdvanceKeys,
  getMaxTermValue,
  FunnelCollapseGuide,
} from "./helper";
import {
  MIN_VALUE,
  MAX_VALUE,
  CATALOG_SLIDER,
  TERM_SLIDER,
  C_INC_SLIDER,
  R_INC_SLIDER,
  TERM_MIN,
} from "./constants";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";
import ReactTooltip from "react-tooltip";
import {
  INCLUDED_CONTENT,
  TERM,
  SHARE_OF_CATALOG,
  SHARE_OF_NEWRELEASE,
  YOUR_ADVANCEMSG,
  ADJUST_TERM_TOOLTIP,
} from "../infoIconMsg";
import InputRangeSlider from "../../../component/Range/InputRangeSlider";
import ContactPopup from "../../../component/ContactPopup";
import { getErrorMessage } from "../helper";
import { VERIFIED } from "../../../component/MenuBar/constants";
import HorizontalMenu from "../../../component/HorizontalMenu";
import { BB_SUBDOMAINS } from "../../../component/ThemeManager/constants";
import { getSubDomain, setTitle } from "../../../component/ThemeManager/helper";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import { ARROW_FORWARD } from "../../../component/MaterialIcons/constants";

class YourAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      shortLoading: false,
      newTracks: 0,
      term: 1,
      catalogIncome: 0,
      releaseIncome: 0,
      advanceData: {},
      streamingVolume: "",
      artistProfile: {},
      currentData: {},
      defaultOffer: {},
      isOpen: false,
      offerStage: {},
      dummyArtist: get(props.history.location, "state.isDemo", false),
      guideData: {},
    };
    this.getSaveFundingAdvance = debounce(this.saveFundingAdvance, 500);
  }

  componentDidMount() {
    this.getAdvanceData();
  }
  componentDidUpdate() {
    this.isAdjustTerm()
      ? setTitle("Adjust Terms", this.context)
      : setTitle("Funding", this.context);
  }

  isAdjustTerm = () => this.props.history.location.pathname === ADJUST_TERMS;

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          if (get(res.data, "dummy_artist", false)) {
            this.setState({
              offerStage: res.data.offerStage,
              dummyArtist: get(res.data, "dummy_artist", false),
              guideData: {
                funnelGuideCustomization: get(
                  res.data,
                  "funnelGuideCustomization",
                  ""
                ),
                funnelGuideSendReports: get(
                  res.data,
                  "funnelGuideSendReports",
                  ""
                ),
                funnelGuideVerification1: get(
                  res.data,
                  "funnelGuideVerification1",
                  ""
                ),
                funnelGuideVerification2: get(
                  res.data,
                  "funnelGuideVerification2",
                  ""
                ),
              },
            });
            this.getOffers(true);
            return true;
          }
          if (!this.isAdjustTerm()) {
            this.setState({
              streamingVolume: res.data.streaming_volume,
              offerStage: res.data.offerStage,
              artistProfile: {
                url: get(res.data, "profilePicture[2].url"),
                name: res.data.name,
              },
            });
            this.getOffersFirst(
              res.data,
              get(res.data, "offerStage.verificationStatus"),
              get(this.props, "location.state.fromDashboard")
            );
            return true;
          }
          this.setState({
            streamingVolume: res.data.streaming_volume,
            offerStage: res.data.offerStage,
            artistProfile: {
              url: get(res.data, "profilePicture[2].url"),
              name: res.data.name,
            },
          });
          this.getOffers(true);
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  getOffers = (loadDefault) => {
    this.setState({ loading: false, shortLoading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false, shortLoading: false });

        if (res.status) {
          this.setState(
            {
              advanceData: res.data.offers,
              defaultOffer: res.data.defaultOffer,
            },
            () => this.changeSliderCallback(loadDefault)
          );
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false, shortLoading: false });
        toast.error(getErrorMessage(err));
      });
  };

  goToPendingStep = (data, verificationStatus) => {
    if (get(verificationStatus, "streamingData.value") !== VERIFIED) {
      this.props.history.push({
        pathname: DATA_REVIEW,
        state: { reviewData: data },
      });
    } else if (get(verificationStatus, "funding.value") !== VERIFIED) {
      this.setState({ loading: true });
      this.getOffers(true);
    } else if (!get(verificationStatus, "reports")) {
      this.props.history.push({
        pathname: SEND_REPORTS,
      });
    } else if (!get(verificationStatus, "publicProfile")) {
      this.props.history.push({
        pathname: SOCIAL_NETWORKS,
      });
    } else {
      this.setState({ loading: true });
      this.getOffers(true);
    }
  };

  getOffersFirst = (data, verificationStatus, fromDashboard) => {
    if (fromDashboard) {
      this.goToPendingStep(data, verificationStatus);
      return true;
    }
    if (get(verificationStatus, "streamingData.value") === VERIFIED) {
      this.setState({ loading: true });
      this.getOffers(true);
      if (get(this.props.history.location, "state.redirectMsg")) {
        toast.error(get(this.props.history.location, "state.redirectMsg"));
        const state = { ...this.props.history.location.state };
        delete state.redirectMsg;
        this.props.history.replace({ ...this.props.history.location, state });
      }
      return true;
    }
    this.props.history.push({
      pathname: DATA_REVIEW,
      state: {
        reviewData: data,
        redirectMsg: "First, please verify your streaming data.",
      },
    });
  };

  saveFundingAdvance = () => {
    this.setState({ loading: true });
    const payload = {
      offer: this.state.currentData,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OFFERS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          if (this.state.dummyArtist) {
            this.props.history.push({
              pathname: SEND_REPORTS,
              state: { isDemo: true },
            });
            return true;
          }
          const statusUpdate = this.state.offerStage;
          statusUpdate.verificationStatus.funding = VERIFIED;
          this.setState({
            offerStage: statusUpdate,
          });
          this.isAdjustTerm()
            ? this.props.history.push(OFFER_SUMMARY)
            : this.props.history.push(SEND_REPORTS);
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ shortLoading: false, loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };

  changeSliderCallback = (loadDefault) => {
    const data = getCurrentCombinationValue(
      this.state.advanceData,
      this.state.newTracks,
      this.state.term,
      this.state.catalogIncome,
      this.state.releaseIncome
    );
    this.setState({
      currentData: data,
      term: data.term_length,
      catalogIncome: data.catalog,
      releaseIncome: data.future,
    });
    if (loadDefault) {
      const { newTracks, term, catalogIncome, releaseIncome } = getDefaultOffer(
        this.state.defaultOffer,
        this.state.advanceData
      );
      this.setState(
        { newTracks, term, catalogIncome, releaseIncome },
        this.changeSliderCallback
      );
    }
  };

  newReleaseLabel = (data) =>
    getNewReleaseLabel(data, this.state.currentData.future);

  getTitle = () => (
    <div className={styles.title}>
      {this.isAdjustTerm() ? (
        <h1>
          Adjust Terms
          <i
            className={styles.infoIcon}
            data-tip={ADJUST_TERM_TOOLTIP}
            data-for="your_advance"
            data-place="top"
          ></i>
        </h1>
      ) : (
        <h1>
          Customize Funding
          <i
            className={styles.infoIcon}
            data-tip={YOUR_ADVANCEMSG}
            data-for="your_advance"
            data-place="top"
          ></i>
        </h1>
      )}
      <ReactTooltip
        delayHide={100}
        class={styles.tooltip}
        effect="solid"
        place="top"
        id="your_advance"
      />
    </div>
  );

  toggleFinePrintText = () => {
    document
      .getElementsByClassName(styles.footerText)[0]
      .classList.toggle(styles.footerHide);
  };

  goBack = () => {
    this.props.history.push(TUNE_MY_DATA);
  };

  getMainButtons = () => {
    if (this.state.dummyArtist)
      return (
        <button
          data-testid="VerifiedButton"
          className={styles.demoBtn}
          onClick={this.saveFundingAdvance}
        >
          How Do You Send Reports to BeatBread?
          <SvgIcons icon={ARROW_FORWARD} />
        </button>
      );
    if (this.isAdjustTerm())
      return (
        <button
          data-testid="saveFunding"
          className={`${styles.primary} ${styles.longBtn}`}
          onClick={this.saveFundingAdvance}
        >
          Confirm and view offer summary
        </button>
      );
    return get(this.state.offerStage, "verificationStatus.publicProfile") ? (
      <button
        data-testid="saveFunding"
        className={styles.primary}
        onClick={this.saveFundingAdvance}
      >
        Save funding options
      </button>
    ) : (
      <>
        <button className={styles.secondary} onClick={this.goBack}>
          Back
        </button>
        <button className={styles.primary} onClick={this.saveFundingAdvance}>
          Next
        </button>
      </>
    );
  };

  finePrintTextRender = () =>
    this.isAdjustTerm() ? (
      <p className={styles.lastFooter}>
        Artist royalties apply to streaming income and SoundExchange revenues,
        but do not apply to revenue from physical formats (vinyl, CD,
        cassettes).
      </p>
    ) : (
      <p className={styles.lastFooter}>
        Advance terms are estimated based on publicly available data and
        ownership information provided. Final terms will be made after
        confirming key information with your distributor. Advance terms may
        increase or decrease after this confirmation step. Artist royalties
        apply to streaming income and SoundExchange revenues, but do not apply
        to revenue from physical formats (vinyl, CD, cassettes).
      </p>
    );

  render() {
    return (
      <>
        <LoginHeader isDemo={this.state.dummyArtist} />
        <div className={styles.pageContainer}>
          <MenuBar
            currentData={this.state.currentData}
            offerStage={this.state.offerStage}
            isDemo={this.state.dummyArtist}
            {...this.props}
            isAdjustTerm={this.isAdjustTerm()}
          />
          <div className={styles.mainContainer}>
            <HorizontalMenu
              offerStage={this.state.offerStage}
              isDemo={this.state.dummyArtist}
              isApprovedFlow={this.isAdjustTerm()}
              {...this.props}
            />
            <div className={styles.scrollContainer}>
              <div className={`${styles.container}`}>
                {!!Object.keys(this.state.advanceData).length && (
                  <>
                    {this.getTitle()}
                    {this.isAdjustTerm() ? (
                      <p className={styles.subtitle}>
                        Adjust the sliders to choose your funding options. All
                        offer options on this page are confirmed. <br />
                      </p>
                    ) : (
                      <p className={styles.subtitle}>
                        Adjust the sliders to choose your funding options.{" "}
                        <br />
                      </p>
                    )}
                    <div className={styles.sliderContainer}>
                      <div>
                        <div className={styles.sliderBox}>
                          <p className={styles.contentname}>
                            <div className={styles.sliderData}>
                              <p>Included Music</p>
                              <span> {this.state.currentData.works}</span>
                            </div>
                            <i
                              className={styles.infoIcon}
                              data-tip={INCLUDED_CONTENT}
                              data-for="included_content"
                              data-place="top"
                            ></i>
                          </p>
                          <ReactTooltip
                            delayHide={100}
                            class={styles.tooltip}
                            effect="solid"
                            place="top"
                            id="included_content"
                          />
                          <InputRangeSlider
                            min={MIN_VALUE}
                            max={getMaxValue(
                              this.state.advanceData,
                              CATALOG_SLIDER
                            )}
                            dots
                            defaultValue={this.state.newTracks}
                            value={this.state.newTracks}
                            onAfterChange={(val) => {
                              this.setState(
                                { newTracks: val },
                                this.changeSliderCallback
                              );
                            }}
                            disabled={
                              !getMaxValue(
                                this.state.advanceData,
                                CATALOG_SLIDER
                              )
                            }
                          />
                        </div>

                        <div className={styles.sliderBox}>
                          <p className={styles.contentname}>
                            <div className={styles.sliderData}>
                              <p>Length of Contract</p>
                              <span> {this.state.term} years</span>
                            </div>
                            <i
                              className={styles.infoIcon}
                              data-tip={TERM}
                              data-for="term"
                              data-place="top"
                            ></i>
                          </p>
                          <ReactTooltip
                            delayHide={100}
                            class={styles.tooltip}
                            effect="solid"
                            place="top"
                            id="term"
                          />
                          <InputRangeSlider
                            min={TERM_MIN}
                            max={getMaxTermValue(
                              this.state.advanceData,
                              this.state.newTracks
                            )}
                            marks={getAdvanceKeys(
                              this.state.advanceData,
                              TERM_SLIDER,
                              this.state.newTracks
                            )}
                            defaultValue={this.state.term}
                            step={null}
                            value={this.state.term}
                            onAfterChange={(val) => {
                              this.setState(
                                { term: val },
                                this.changeSliderCallback
                              );
                            }}
                            disabled={
                              !getMaxValue(
                                this.state.advanceData,
                                TERM_SLIDER,
                                this.state.newTracks
                              )
                            }
                          />
                        </div>

                        <div className={styles.sliderBox}>
                          <p className={styles.contentname}>
                            <div className={styles.sliderData}>
                              <p>Catalog Income you keep</p>
                              <span> {this.state.catalogIncome}%</span>
                            </div>
                            <i
                              className={styles.infoIcon}
                              data-tip={SHARE_OF_CATALOG}
                              data-for="share_of_catalog"
                              data-place="top"
                            ></i>
                          </p>
                          <ReactTooltip
                            delayHide={100}
                            class={styles.tooltip}
                            effect="solid"
                            place="top"
                            id="share_of_catalog"
                          />
                          <InputRangeSlider
                            min={MIN_VALUE}
                            max={MAX_VALUE}
                            marks={getAdvanceKeys(
                              this.state.advanceData,
                              C_INC_SLIDER,
                              this.state.newTracks,
                              this.state.term
                            )}
                            defaultValue={this.state.catalogIncome}
                            step={null}
                            value={this.state.catalogIncome}
                            onAfterChange={(val) => {
                              this.setState(
                                { catalogIncome: val },
                                this.changeSliderCallback
                              );
                            }}
                            disabled={
                              !getMaxValue(
                                this.state.advanceData,
                                C_INC_SLIDER,
                                this.state.newTracks,
                                this.state.term
                              )
                            }
                          />
                        </div>
                        {this.state.currentData.future !== null && (
                          <div className={styles.sliderBox}>
                            <p className={styles.contentname}>
                              <div className={styles.sliderData}>
                                <p>New Music Income you keep</p>
                                <span> {this.state.releaseIncome}%</span>
                              </div>
                              <i
                                className={styles.infoIcon}
                                data-tip={SHARE_OF_NEWRELEASE}
                                data-for="share_of_newrelease"
                                data-place="top"
                              ></i>
                            </p>
                            <ReactTooltip
                              delayHide={100}
                              class={styles.tooltip}
                              effect="solid"
                              place="top"
                              id="share_of_newrelease"
                            />
                            <InputRangeSlider
                              min={MIN_VALUE}
                              max={MAX_VALUE}
                              marks={getAdvanceKeys(
                                this.state.advanceData,
                                R_INC_SLIDER,
                                this.state.newTracks,
                                this.state.term,
                                this.state.catalogIncome
                              )}
                              defaultValue={this.state.releaseIncome}
                              step={null}
                              value={this.state.releaseIncome}
                              onAfterChange={(val) => {
                                this.setState(
                                  { releaseIncome: val },
                                  this.changeSliderCallback
                                );
                              }}
                              disabled={
                                !getMaxValue(
                                  this.state.advanceData,
                                  R_INC_SLIDER,
                                  this.state.newTracks,
                                  this.state.term,
                                  this.state.catalogIncome
                                )
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!!Object.keys(this.state.advanceData).length && (
              <>
                <div
                  className={`${styles.fundingSave} ${
                    this.state.dummyArtist ? styles.atBottom : ""
                  }`}
                >
                  <div className={`${styles.buttonContainer} `}>
                    {this.getMainButtons()}
                  </div>
                </div>
                {!this.state.dummyArtist && (
                  <p
                    className={styles.readBtn}
                    onClick={this.toggleFinePrintText}
                  >
                    <i className={`${styles.infoIcon} ${styles.infoWhite}`}></i>{" "}
                    Read the fine print
                  </p>
                )}
                <div className={`${styles.footerText} ${styles.footerHide}`}>
                  <span
                    className={styles.close}
                    onClick={this.toggleFinePrintText}
                  >
                    ×
                  </span>
                  {BB_SUBDOMAINS.indexOf(getSubDomain()) !== -1 ? (
                    <>
                      {this.finePrintTextRender()}
                      <p>
                        A fee of $228 plus 2.8% of your advance will be added to
                        you advance balance to be recouped out of your streaming
                        income
                      </p>
                    </>
                  ) : (
                    <p className={styles.whitelabelFinePrint}>
                      {get(this.context, "finePrintText")}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        {this.state.loading && <Loader light backgroundNone />}
        {this.state.shortLoading && <ShortCallLoader />}
        {this.state.dummyArtist && (
          <FunnelCollapseGuide
            guideData={this.state.guideData}
            {...this.props}
          />
        )}
        <ContactPopup
          isOpen={this.state.isOpen}
          onRequestClose={this.handleClose}
        />
      </>
    );
  }
}
YourAdvance.contextType = ThemeContext;

export default YourAdvance;
