import React, { Component } from "react";
import { get } from "lodash";
import styles from "./HorizontalMenu.module.scss";
import { Link } from "react-router-dom";
import { VERIFIED, UPDATED } from "../MenuBar/constants";
import {
  TUNE_MY_DATA,
  FUNDING,
  SEND_REPORTS,
  SOCIAL_NETWORKS,
  DATA_REVIEW,
  OFFER_SUMMARY,
  ADJUST_TERMS,
  ACCEPT_OFFER,
} from "../../routes/pages/constants";

class HorizontalMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathname: "",
    };
  }

  componentDidMount() {
    this.setState({ pathname: this.props.history.location.pathname });
  }

  getStreamingStatus = () => {
    if (this.props.isDemo) return null;
    const status = get(
      this.props.offerStage,
      "verificationStatus.streamingData.value"
    );
    if (status === VERIFIED) {
      return (
        <p className={styles.visited}>
          <i className={styles.checkIcon} />
          Verified
        </p>
      );
    }
    if (status === UPDATED) {
      return <p className={styles.updated}>Review updates</p>;
    }
    return <p className={styles.notVisited}>Not verified</p>;
  };

  getFundingStatus = () => {
    if (this.props.isDemo) return null;
    const status = get(
      this.props.offerStage,
      "verificationStatus.funding.value"
    );
    if (status === VERIFIED) {
      return (
        <p className={styles.visited}>
          <i className={styles.checkIcon} />
          Customized
        </p>
      );
    }
    if (status === UPDATED) {
      return <p className={styles.updated}>Review updates</p>;
    }
    return <p className={styles.notVisited}>Not customized</p>;
  };

  getReportStatus = () => {
    if (this.props.isDemo) return null;
    return get(this.props.offerStage, "verificationStatus.reports") ? (
      <p className={styles.visited}>
        <i className={styles.checkIcon} />
        Sent
      </p>
    ) : (
      <p className={styles.notVisited}>Not sent</p>
    );
  };

  getProfileStatus = () =>
    get(this.props.offerStage, "verificationStatus.publicProfile") ? (
      <p className={styles.visited}>
        <i className={styles.checkIcon} />
        Edited
      </p>
    ) : (
      <p className={styles.notVisited}>Not edited</p>
    );

  render() {
    const { pathname } = this.state;
    return (
      <div className={`${styles.container} ${this.props.className}`}>
        <div
          className={`${styles.menuContainer} ${
            this.props.isApprovedFlow ? styles.approvedMenu : ""
          }`}
        >
          {this.props.isApprovedFlow ? (
            <>
              <Link
                to={OFFER_SUMMARY}
                className={`${styles.menuItem} ${
                  pathname === OFFER_SUMMARY && styles.active
                }`}
              >
                <div>
                  <p>Offer Summary</p>
                </div>
              </Link>
              <Link
                to={ADJUST_TERMS}
                className={`${styles.menuItem} ${
                  pathname === ADJUST_TERMS && styles.active
                }`}
              >
                <div>
                  <p>Adjust Terms</p>
                </div>
              </Link>
              <Link
                to={ACCEPT_OFFER}
                className={`${styles.menuItem} ${
                  pathname === ACCEPT_OFFER && styles.active
                }`}
              >
                <div>
                  <p>Accept Offer</p>
                </div>
              </Link>
            </>
          ) : (
            <>
              <Link
                to={TUNE_MY_DATA}
                className={`${styles.menuItem} ${
                  (pathname === TUNE_MY_DATA || pathname === DATA_REVIEW) &&
                  styles.active
                }`}
              >
                <div>
                  <p>Verify Streaming Data</p>
                  {this.getStreamingStatus()}
                </div>
              </Link>
              <Link
                to={FUNDING}
                className={`${styles.menuItem} ${
                  pathname === FUNDING && styles.active
                }`}
              >
                <div>
                  <p>Customize Funding</p>
                  {this.getFundingStatus()}
                </div>
              </Link>
              <Link
                to={SEND_REPORTS}
                className={`${styles.menuItem} ${
                  pathname === SEND_REPORTS && styles.active
                }`}
              >
                <div>
                  <p>Send Reports</p>
                  {this.getReportStatus()}
                </div>
              </Link>
              {!this.props.isDemo && (
                <Link
                  to={SOCIAL_NETWORKS}
                  className={`${styles.menuItem} ${
                    pathname === SOCIAL_NETWORKS && styles.active
                  }`}
                >
                  <div>
                    <p>Your Profile</p>
                    {this.getProfileStatus()}
                  </div>
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default HorizontalMenu;
