import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import styles from "../../Auth/Auth.module.scss";
import { setTitle } from "../../../../component/ThemeManager/helper";
import Header from "../../../../component/Header";
import { MAINTENANCE_PAGE, LOGIN } from "../../constants";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";

class SiteMaintenancePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate() {
    setTitle("Site Maintenance", this.context);
    if (
      !(
        get(this.context, "maintenanceMode.isUnderMaintenance") &&
        get(this.context, "maintenanceMode.allApps.status")
      )
    ) {
      this.props.history.push(LOGIN);
    }
  }
  render() {
    return (
      <div className="theme-bg-dark">
        <Header active={MAINTENANCE_PAGE} />
        <div className={styles.loginContent}>
          <div className={styles.formContainer}>
            <h1 className={styles.maintenanceTitle}>
              {get(
                this.context,
                "maintenanceMode.allApps.headerText",
                "Site Maintenance"
              )}
            </h1>
            <p className={styles.maintenanceDetail}>
              {get(
                this.context,
                "maintenanceMode.allApps.detailText",
                "We are currently going through some site maintenance. Please come back within 24 hours."
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
SiteMaintenancePage.contextType = ThemeContext;
export default SiteMaintenancePage;
SiteMaintenancePage.contextTypes = {
  maintenanceMode: PropTypes.object,
};
