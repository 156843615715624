import React, { Component } from "react";
import { get } from "lodash";
import { Form } from "formik";
import * as Yup from "yup";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import styles from "../Auth.module.scss";
import {
  API_URL,
  AUTH,
  REGISTER_API,
  DATA_REVIEW,
  USER_API,
  ARTIST_API,
  ACTIVATE_API,
  DASHBOARD,
} from "../../constants";
import Loader from "../../../../component/Loader";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import StorageService from "../../../../utils/StorageService";
import request from "../../../../utils/request";
import { getErrorMessage } from "../../helper";
import { PHONE_REGEX } from "../../Auth/constants";
import AuthTokenService from "../../../../utils/AuthTokenService";
import { BB_SUBDOMAINS } from "../../../../component/ThemeManager/constants";
import { getSubDomain } from "../../../../component/ThemeManager/helper";
import Image from "../../../../component/Image";
import { IMAGE_TYPE } from "../../../../component/Image/constants";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";
import { ROLE_OPTIONS } from "../../MyAccount/constants";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      identifier: "",
      _id: "",
      error: false,
      fname: "",
      lname: "",
      checkValue: false,
    };
  }

  passwordRegexUpperLower = /(?=.*[a-z])(?=.*[A-Z])/;
  passwordRegexNumSpecial = /(?=.*\d)(?=.*[@$!%*?&])/;
  nameRegex = /^[a-zA-Z0-9. '`]+$/;

  validationSchema = Yup.object().shape({
    textMsg: Yup.string().test(
      "err-test",
      "Please enter at least one of the above URLs",
      function () {
        return true;
      }
    ),
    phone: Yup.string()
      .matches(PHONE_REGEX, "Please enter valid phone number")
      .label("Phone number"),
    fname: Yup.string()
      .required()
      .min(2, "Please enter valid First Name")
      .max(255)
      .matches(this.nameRegex, "Please enter First Name")
      .label("First name"),
    lname: Yup.string()
      .required()
      .min(2, "Please enter valid Last Name")
      .max(255)
      .matches(this.nameRegex, "Please enter Last Name")
      .label("Last name"),
    role: Yup.string().required().label("Role"),
    password: Yup.string()
      .required()
      .min(8, "Please enter valid password between 8 to 20 characters")
      .max(20, "Please enter valid password between 8 to 20 characters")
      .matches(
        this.passwordRegexUpperLower,
        "Password should have at least 1 uppercase letter & 1 lowercase letter."
      )
      .matches(
        this.passwordRegexNumSpecial,
        "Password should have at least 1 number & 1 special character."
      )
      .label("Password"),
  });

  handelSubmit = (values) => {
    let phone = "";
    if (values.phone) {
      const regExp = new RegExp(values.countryCode);
      phone = values.phone.replace(regExp, "");
    }
    this.setState({ loading: true });
    const payload = {
      firstName: values.fname,
      lastName: values.lname,
      _id: this.props.userId,
      password: values.password,
      artistId:
        get(this.props, "location.state.data.artistId") || this.props.artistId,
      verificationCode:
        get(this.props, "location.state.data.verificationCode") ||
        this.props.verificationCode,
      countryCode: values.countryCode,
      role: values.role,
      phone,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${REGISTER_API}`;
    request(requestURL, data)
      .then((res) => {
        if (!res.status) {
          this.setState({ loading: false });
          toast.error(get(res, "message"));
          return [];
        }
        localStorage.removeItem("ackArtistDetails");
        const artists = get(res.data, "linkedArtist", [{}]);
        AuthTokenService.storeToken(res.data.accessToken);
        if (artists.pop().active) {
          this.activateApiCall(res);
        } else {
          this.props.history.push(DASHBOARD);
        }
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
        this.setState({ loading: false });
      });
    return true;
  };

  handleInputChange = (e) => {
    if (e.target.name !== "password" && e.target.name !== undefined) {
      this.setState({
        [e.target.name]: e.target.value,
      });
      StorageService.set([e.target.name], e.target.value);
    }
  };

  activateApiCall(response) {
    const payload = { id: response.data.artistId };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${ACTIVATE_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (!res.status) {
          toast.error(get(res, "message"));
          return [];
        }
        AuthTokenService.storeToken(res.data.accessToken);
        this.props.history.push(DATA_REVIEW);
      })
      .catch((error) => {
        toast.error(getErrorMessage(error));
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <>
        <FormikForm
          initialValues={{
            fname: "",
            lname: "",
            password: "",
            role: "",
            textMsg: "",
            phone: "",
            countryCode: "",
          }}
          validationSchema={this.validationSchema}
          onSubmit={this.handelSubmit}
        >
          <Form
            onChange={(e) => this.handleInputChange(e, this)}
            className={this.props.className}
          >
            {BB_SUBDOMAINS.indexOf(getSubDomain()) === -1 && (
              <div className={styles.poweredBy}>
                <Image
                  src={get(this.context, "appLogo")}
                  alt={"logo"}
                  imageType={IMAGE_TYPE.LOGO}
                />
              </div>
            )}
            <div
              className={`${styles.formgroup} form-group form-group-m0 mb-0`}
            >
              <FormField
                name="fname"
                placeholder="First Name"
                label="First name"
              />
            </div>
            <div
              className={`${styles.formgroup} form-group form-group-m0 mb-0`}
            >
              <FormField
                name="lname"
                placeholder="Last Name"
                label="Last name"
              />
            </div>
            <div
              className={`${styles.formgroup} form-group form-group-m0 mb-0`}
            >
              <FormField
                name="role"
                as="select"
                placeholder="Select a role"
                label="I am..."
                options={ROLE_OPTIONS}
              />
            </div>
            <div
              className={`${styles.formgroup} form-group form-group-m0 mb-0`}
            >
              <FormField
                name="phone"
                as="phone"
                placeholder="Phone Number"
                label="Phone number (Optional)"
              />
            </div>
            <div
              className={`${styles.formgroup} ${styles.registerContent} form-group mb-0`}
            >
              <i
                className={`infoIcon ${styles.infoIcon}`}
                data-tip
                data-for="info"
              ></i>
              <ReactTooltip
                className={styles.tooltip}
                id="info"
                type="dark"
                effect="solid"
                place="top"
              >
                <div className={`textSize14`}>
                  <span className="font-weight-bold">
                    Password should fulfil following conditions:
                  </span>
                  <li>Minimum 8 and Maximum 20 characters</li>
                  <li>
                    At least 1 uppercase and 1 lowercase alphabet is mandatory
                  </li>
                  <li>
                    At least 1 special character and 1 number is mandatory
                  </li>
                </div>
              </ReactTooltip>
              <FormField
                name="password"
                placeholder="Password"
                as="password"
                label="Password"
              />
            </div>

            <div className={styles.checkboxAlign}>
              <FormField
                className={styles.checkboxinput}
                name="checkagreement"
                type="checkbox"
                as="checkbox"
                data-testid="CheckAgreement"
                onChange={() =>
                  this.setState({ checkValue: !this.state.checkValue })
                }
              />
            </div>

            <div
              className={`form-group ${styles.registerContent} mb-0 mt-auto `}
            >
              <button
                type="submit"
                className={`${styles.btnregister}`}
                disabled={!this.state.checkValue || this.state.loading}
              >
                {this.props.ackScreen
                  ? `Create account`
                  : `See funding options`}
              </button>
            </div>
          </Form>
        </FormikForm>
        {this.state.loading && <Loader />}
      </>
    );
  }
}
Register.contextType = ThemeContext;

export default Register;
