export const STATUS_FAILED = "failed";
export const STATUS_BIG = "big";
export const STATUS_SMALL = "small";
export const STATUS_PENDING = "pending";
export const STATUS_NEW = "new";
export const STATUS_ERR = "error";
export const STATUS_IN_RANGE_1 = "in_range1";
export const STATUS_IN_RANGE_2 = "in_range2";
export const STATUS_IN_RANGE_3 = "in_range3";
export const STATUS_COMPLETED = "completed";
