import React, { Component } from "react";
import { get, debounce } from "lodash";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "./index.scss";
import Select from "react-select/async";
import { Form } from "formik";
import FormikForm from "../../../../component/Form/FormikForm";
import styles from "./LinkAnotherArtist.module.scss";
import {
  API_URL,
  USER_API,
  DASHBOARD,
  ARTIST_API,
  ADD_ARTIST,
} from "../../constants";
import { toast } from "react-toastify";
import Loader from "../../../../component/Loader";
import request from "../../../../utils/request";
import autofillStyle from "../Getfunded/autofillStyle";
import { formatOptionLabel } from "../Getfunded/formatOptionLabel";
import {
  NoOptionsMessage,
  LoadingMessage,
  SingleValue,
  getLabel,
  getOptions,
  handleChange,
  handleRemoveArtist,
  onSearchTypeChange,
} from "../Getfunded/helper";
import LoginHeader from "../../../../component/LoginHeader";
import Navigator from "../../../../component/Navigation/Navigator";
import { getErrorMessage } from "../../helper";
import { setTitle } from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";

class LinkAnohterArtist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      identifier: "phone",
      selectOptions: [],
      id: "",
      name: "",
      image: "",
      searchType: "name",
    };
    this.getOptions = debounce(getOptions.bind(this), 600);
  }

  componentDidUpdate() {
    setTitle("Link Another Artist", this.context);
  }

  handelSubmit = () => {
    this.setState({ loading: true });
    const payload = {
      spotifyId: this.state.id,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${ADD_ARTIST}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (!res.status) {
          toast.error(get(res, "message"));
          return [];
        }
        toast.success(get(res, "message"));
        this.props.history.replace(DASHBOARD);
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
        this.setState({ loading: false });
      });
  };
  getStateValue = () => {
    return this.state.id && this.state.name
      ? {
          value: this.state.id,
          label: this.state.name,
          imageUrl: this.state.image,
        }
      : null;
  };

  validationSchema = Yup.object().shape({
    identifier: Yup.string().label("Artist Name"),
  });

  render() {
    return (
      <div className="theme-bg-dark">
        <LoginHeader />
        <div className={styles.mainContainer}>
          <div className={styles.title}>
            <h2>Link Another Artist</h2>
            <Navigator {...this.props} />
          </div>
          <FormikForm
            initialValues={{
              identifier: "",
            }}
            validationSchema={this.validationSchema}
            onSubmit={this.handelSubmit}
          >
            <div className={styles.formContainer}>
              <div className={styles.title}>
                <h1>Link Another Artist</h1>
              </div>
              <Form>
                <div className="form-group mb-0">
                  <label htmlFor="identifier" className={styles.labelText}>
                    {this.state.searchType === "id" && "Spotify"} Artist{" "}
                    {getLabel(this.state.searchType)}
                  </label>
                  <div className={styles.spotifySearch}>
                    <Select
                      maxMenuHeight={176}
                      id="artistSelect"
                      data-test="SpotifySelect"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isClearable={false}
                      value={this.getStateValue()}
                      loadOptions={(inputValue, callback) =>
                        this.getOptions.call(this, inputValue, callback)
                      }
                      onChange={(e) => handleChange.call(this, e)}
                      formatOptionLabel={formatOptionLabel}
                      name="identifier"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        SingleValue: (prop) =>
                          SingleValue(
                            prop,
                            (props) => handleRemoveArtist.call(this, props),
                            this.state.image
                          ),
                        NoOptionsMessage,
                        LoadingMessage,
                      }}
                      styles={autofillStyle}
                      placeholder={`${
                        this.state.searchType === "id" ? "Spotify" : ""
                      } Artist ${getLabel(this.state.searchType)}`}
                    />
                  </div>

                  <Link className={styles.emailorphone} to={"#"}>
                    <span
                      data-test="SearchType"
                      onClick={() => onSearchTypeChange.call(this)}
                    >
                      {this.state.searchType === "name"
                        ? "Use Spotify artist ID"
                        : "I prefer artist name"}
                    </span>
                  </Link>
                </div>

                <div className="form-group mb-0 mt-5">
                  <button
                    className={styles.btn}
                    disabled={this.state.id !== "" ? false : true}
                    type="submit"
                    name="submit"
                  >
                    Add Artist
                  </button>
                </div>
              </Form>
            </div>
          </FormikForm>
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

LinkAnohterArtist.contextType = ThemeContext;
export default LinkAnohterArtist;
