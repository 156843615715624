/* Constants for API */
export const API_URL = process.env.REACT_APP_API_URL;
export const AUTH = "/auth";
export const SIGNIN_API = "/signin";
export const CODE_API = "/code";
export const CHANGE_PASS_API = "/change-password";
export const VALIDATE_AUTH_API = "/validateAuthentication";
export const REGISTER_API = "/register";
export const FORGOT_PASS_API = "/forgot-password";
export const GETTOKEN_API = "/social-media/url";
export const VALIDATEAUTH_API = "/validateAuthentication";
export const USER_API = "/user";
export const SPOTIFY_USER_API = "/spotify-users";
export const SIGNUP_API = "/signup";
export const USERADD = "/social-media/add";
export const USERREMOVE = "/social-media/remove";
export const ME = "/me";
export const ADMIN = "/admin";
export const ARTIST_API = "/artist";
export const ACTIVATE_API = "/activate";
export const STATUS_API = "/status";
export const CHANGE_VISIT_STATUS = "/change-visit-status";
export const VERIFICATION_RESEND = "/verification/resend";
export const RESEND_VERIFICATION = "/resend-verification";
export const REPORTS = "/reports";
export const VERIFICATION = "/verification";
export const PROVISIONAL = "/add/provisional";
export const ARTIST_SOCIALMEDIA = "/add/social-media";
export const ADD_ARTIST = "/add";
export const OFFERS_API = "/offers";
export const ADMIN_USERS = "/admin/users";
export const ADMIN_USER = "/admin/user";
export const ADMIN_ARTIST_API = "/admin/artist";
export const REPROCESS_API = "/reprocess";
export const REVOKE_APPROVAL_API = "/revoke-approval";
export const ADMIN_ACTIVATE_USER = "/admin/user/activate";
export const CHAT_API = "/chat";
export const NOTIFY_APPOINTMENT = "/notify-appointment";
export const SOCIAL_NETWORK = "/social-networks";
export const EMAIL_PREFERENCES_API = "/email-preferences";
export const ADMIN_WHITELABEL_API = "/admin/whitelabel";
export const ADMIN_WHITELABEL_ACCOUNTS = "/admin/whitelabel/accounts";
export const ADMIN_MAINTENANCE_MODE_API = "/admin/maintenance-mode";
export const DISTRIBUTORS_API = "/distributor";
export const INFO_API = "/info";
export const ACTIVE_DISTRIBUTORS = "/active-distributors";
export const DISTRIBUTOR_REPORTS_API = "/distributor-reports";
export const OTHER_REPORTS_API = "/other-reports";
export const CHANGE_STATE = "/change-state";
export const INSTANCE = "/instance";
export const ADMIN_IP_API = "/admin/ip";
export const USER_GUIDE = "/user-guide";
export const REPORT = "/report";
export const SEND_REPORTS_API = "/send-reports";
export const OFFER_CONTRACT_PDF = "/offer-contract-pdf";
export const DOWNLOAD_REPORTS = "/download-reports";
export const OFFER_SUMMARY_API = "/offer-summary";
export const EMAIL_OFFER_API = "/email-offer";
export const EXIT_DEMO = "/exit-demo";
export const DEMO_ARTIST_DATASET = "/demo-artist-data-sets";
export const DEMO_ARTIST_API = "/demo-artist";
export const SAVE_DEMO_ARTIST = "/save-demo-artist";
export const ACTIVE_DUMMY_ARTIST = "/active-dummy-artist";
export const TRACK_REASON_UPDATE = "/track-reason-update";
/* Constants for Routes */

export const HOME = "/";
export const ERROR_PAGE = "/error-404";
export const ERROR_SERVER_DOWN = "/error-503";
export const ERROR_FORBIDDEN = "/error-403";
export const MAINTENANCE_PAGE = "/maintenance";
export const LOGIN = "/login";
export const ADMIN_LOGIN = "/admin/login";
export const FORGOT_PASS = "/forgot-password";
export const CHANGE_PASS = "/change-password";
export const REGISTER = "/register";
export const VERIFY_USER = "/verify-user";
export const STATS = "/stats";
export const ARTISTSTATS = "/artist/stats";
export const TOP_TRACK = "/top-tracks";
export const SPOTIFY_STATS = "/spotify-stats";
export const SOUNDCLOUD_STATS = "/soundcloud-stats";
export const YOUTUBE_STATS = "/youtube-stats";
export const INSTAGRAM_STATS = "/instagram-stats";
export const GETFUNDED = "/get-funded";
export const GETFUNDED_AUTH = "/register";
export const MY_ACCOUNT = "/my-account";
export const DASHBOARD = "/dashboard";
export const ADMIN_DASHBOARD = "/admin/users";
export const ADMIN_BLACKLIST = "/admin/blacklist";
export const ADMIN_WHITELABEL = "/admin/whitelabel";
export const ADMIN_WHITELABEL_NEW_ACCOUNT = "/admin/whitelabel/create";
export const ADMIN_WHITELABEL_EDIT_ACCOUNT = "/admin/whitelabel/edit";
export const ADMIN_MAINTENANCE_MODE = "/admin/maintenance";
export const DISTRIBUTORS = "/admin/distributors";
export const DISTRIBUTORS_CREATE = "/admin/distributors/create";
export const DISTRIBUTORS_EDIT = "/admin/distributors/edit";
export const DEMO_ARTIST = "/admin/demo-artists";
export const DEMO_ARTIST_CREATE = "/admin/demo-artists/create";
export const DEMO_ARTIST_EDIT = "/admin/demo-artists/edit";
export const ACTIVE_API = "/active";
export const DATA_REVIEW = "/data-review";
export const FUNDING = "/customize-funding";
export const SEND_REPORTS = "/send-reports";
export const VERIFY = "/verify";
export const LINK_ANOTHER_ARTIST = "/link-another-artist";
export const CONTACT_BEATBREAD_GET_FUNDED = "/contact-for-funding";
export const SUCCESS = "/success";
export const WHY_BEATBREAD = "/why-beatbread";
export const SOCIAL_NETWORKS = "/edit-profile";
export const MANAGE_BLACKLIST = "/manage-blacklist";
export const EMAIL_PREFERENCES = "/email-preferences";
export const TUNE_MY_DATA = "/verify-data";
export const ACK_IN_RANGE_1 = "/acknowledgement-screen-0-1";
export const ACK_IN_RANGE_2 = "/acknowledgement-screen-0-2";
export const ACK_IN_RANGE_3 = "/acknowledgement-screen-0-3";
export const ACK_HIGH = "/acknowledgement-screen-1";
export const ACK_LOW = "/acknowledgement-screen-2";
export const ACK_ERROR = "/acknowledgement-screen-3";
export const ACK_NEW = "/acknowledgement-screen-4";
export const ACK_REDIRECT = "/redirect";
export const OFFER_SUMMARY = "/offer-summary";
export const ADJUST_TERMS = "/adjust-terms";
export const ACCEPT_OFFER = "/accept-offer";
export const ARTIST_VERIFY = "/artist-verify";
export const DEMO_DASHBOARD = "/demo-dashboard";
/* Contact mail */

export const CONTACT_EMAIL = "hello@[DOMAIN].com";
export const INVESTOR_LINK = "https://beatbread.com/investors/";
export const OUR_ARTIST = "https://beatbread.com/artists/";
export const PARTNER_LINK = "https://beatbread.com/partners/";
export const ABOUT_US_LINK = "https://beatbread.com/about/";
export const FAQ_LINK = "https://beatbread.com/#faqs";
export const BB_LINK = "https://beatbread.com";
export const BB_DEV_LINK = "https://stgwp.beatbread.com/demo-page";
export const BB_DEMO_LINK = "https://stgwp.beatbread.com";
export const CHORDCASH_PRIVACY = "https://chordcash.com/privacy-policy";
export const CHORDCASH_CALENDLY_LINK =
  "https://calendly.com/conner-davis-1/30-minute-meeting";
export const BEATBREAD_CALENDLY_LINK =
  "https://calendly.com/d/ckr-fq9-vm7/review-your-deal-terms-with-beatbread";
export const FAILED_TO_FETCH =
  "We're experiencing higher than normal traffic, which is causing an issue. Please try again in a moment.";

/* Social media platforms */
export const INSTAGRAM_PLATFORM = "instagram";
export const YOUTUBE_PLATFORM = "youtube";
export const FACEBOOK_PLATFORM = "facebook";
export const WIKIPEDIA_PLATFORM = "wikipedia";

/* Intercom Constants */
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
