export const MAPPING_FIELDS = {
  VALIDATION_SCHEMA: "validation_schema",
  VALIDATION_MESSAGE: "validation_message",
  INITIAL_VALUES: "initial_values",
  API_VALUES: "api_values",
};
export const WHITELABEL_TABLE_COLUMN = [
  {
    name: "",
    key: "emptySpace",
  },
  {
    name: "",
    key: "appLogo",
  },
  {
    name: "Source",
    key: "source",
  },
  {
    name: "Action",
    key: "action",
  },
];

export const COLOR_CODES = [
  {
    label: "General",
    key: "colorCodes.general",
    subFields: [
      {
        label: "Primary Color",
        key: "colorCodes.general.primaryColor",
      },
    ],
  },
  {
    label: "Email",
    key: "colorCodes.email",
    subFields: [
      {
        label: "Header and Footer Color",
        key: "colorCodes.email.headerFooterColor",
      },
      {
        label: "Call To Action Color",
        key: "colorCodes.email.ctaColor",
      },
      {
        label: "Font Color 1",
        key: "colorCodes.email.fontColorOne",
      },
      {
        label: "Font Color 2",
        key: "colorCodes.email.fontColorTwo",
      },
      {
        label: "Box Color",
        key: "colorCodes.email.boxColor",
      },
      {
        label: "Icon Color",
        key: "colorCodes.email.iconColor",
      },
    ],
  },
  {
    label: "Register",
    key: "colorCodes.register",
    subFields: [
      {
        label: "Main Background Color",
        key: "colorCodes.register.mainBackgroundColor",
      },
      {
        label: "Header Background Color",
        key: "colorCodes.register.headerBackgroundColor",
      },
      {
        label: "Header Font Color",
        key: "colorCodes.register.headerFontColor",
      },
      {
        label: "Accent Text Color",
        key: "colorCodes.register.accentTextColor",
      },
      {
        label: "Call To Action Color",
        key: "colorCodes.register.ctaColor",
      },
    ],
  },
  {
    label: "Main Funnel",
    key: "colorCodes.mainFunnel",
    subFields: [
      {
        label: "Sidebar",
        key: "colorCodes.mainFunnel.sidebar",
        subFields: [
          {
            label: "Header, Footer and Accent Color",
            key: "colorCodes.mainFunnel.sidebar.headerFooterAccentColor",
          },
          {
            label: "Primary Color",
            key: "colorCodes.mainFunnel.sidebar.primaryColor",
          },
          {
            label: "Font Color One",
            key: "colorCodes.mainFunnel.sidebar.fontColorOne",
          },
          {
            label: "Font Color Two",
            key: "colorCodes.mainFunnel.sidebar.fontColorTwo",
          },
          {
            label: "Tooltip Icon Color",
            key: "colorCodes.mainFunnel.sidebar.toolTipIconColor",
          },
        ],
      },
      {
        label: "Progression Status",
        key: "colorCodes.mainFunnel.progressionStatus",
        subFields: [
          {
            label: "Accent Color",
            key: "colorCodes.mainFunnel.progressionStatus.accentColor",
          },
          {
            label: "Review Updates Color",
            key: "colorCodes.mainFunnel.progressionStatus.reviewUpdatesColor",
          },
        ],
      },
      {
        label: "Alerts and Tooltips",
        key: "colorCodes.mainFunnel.alertsAndToolTips",
        subFields: [
          {
            label: "Tooltip Background Color",
            key: "colorCodes.mainFunnel.alertsAndToolTips.toolTipsBackground",
          },
          {
            label: "Tooltip Font Color",
            key: "colorCodes.mainFunnel.alertsAndToolTips.toolTipsFontColor",
          },
          {
            label: "Alert Background Color",
            key: "colorCodes.mainFunnel.alertsAndToolTips.alertBackground",
          },
          {
            label: "Alert Font Color",
            key: "colorCodes.mainFunnel.alertsAndToolTips.alertFontColor",
          },
        ],
      },
      {
        label: "Content",
        key: "colorCodes.mainFunnel.content",
        subFields: [
          {
            label: "Slider Color",
            key: "colorCodes.mainFunnel.content.sliderColor",
          },
          {
            label: "Help Text Color",
            key: "colorCodes.mainFunnel.content.helpTextColor",
          },
        ],
      },
    ],
  },
];

export const FIELD_NAMES = {
  DISPLAY_NAME: {
    NAME: "displayName",
    LABEL: "Display name",
    PLACEHOLDER: "Enter Display Name",
  },
  SLUG_NAME: {
    NAME: "slugName",
    LABEL: "Slug Name",
    PLACEHOLDER: "Enter Slug Name",
    EXTRA_TEXT: "all lowercase, no special characters",
  },
  CTA_DEFAULT_VALUE: {
    NAME: "ctaDefaultValue",
    LABEL: "Paragraph text for pop-up",
    PLACEHOLDER: "Enter paragraph text for pop-up",
    EXTRA_TEXT: "",
  },
  CTA_BUTTON_TEXT: {
    NAME: "ctaButtonText",
    LABEL: "Button Text",
    PLACEHOLDER: "Enter button label",
  },
  CTA_ACTION_URL: {
    NAME: "ctaActionURL",
    LABEL: "Action URL",
    PLACEHOLDER:
      "Please provide the web address as http(s)://example.com or http(s)://www.example.com",
    EXTRA_TEXT: "",
  },
  ACK_SCREEN_CTA_TEXT: {
    NAME: "acknowledgementCtaButtonText",
    LABEL: "Button Text",
    PLACEHOLDER: "Enter button text",
  },
  ACK_SCREEN_CTA_URL: {
    NAME: "acknowledgementCtaActionURL",
    LABEL: "Action URL",
    PLACEHOLDER:
      "Please provide the web address as http(s)://example.com or http(s)://www.example.com",
  },
  LANDING_PAGE_URL: {
    NAME: "landingPageURL",
    LABEL: "Landing Page URL",
    PLACEHOLDER:
      "Please provide the web address as http(s)://example.com or http(s)://www.example.com",
    EXTRA_TEXT: "",
    LABEL_TO_DISPLAY: "Add URL",
  },
  IS_DISTRIBUTOR: {
    NAME: "isDistributor",
    EXTRA_TEXT: "Distributor  ",
    ERROR_TEXT:
      "Cannot have an empty label. Either remove the corresponding tooltip or ensure both label and tooltip are added.",
  },
  REPORT_ADDITIONAL_FIELDS: {
    LABEL: "Report Additional Fields",
    EXTRA_TEXT: `Be sure to add either label OR both label and tooltip`,
    FIELDS: {
      LABEL: {
        PLACEHOLDER: "Enter text for field label",
      },
      TOOLTIP: {
        PLACEHOLDER: "Enter text for tooltip",
      },
      INDIVIDUAL_FIELDS: [
        {
          LABEL: {
            NAME: "fieldOneLabel",
            LABEL: "Field 1 Label",
          },
          TOOLTIP: {
            NAME: "fieldOneTooltip",
            LABEL: "Field 1 Tooltip",
          },
          REQUIRED: {
            NAME: "isField1Required",
            LABEL: "Field 1 Required?",
          },
        },
        {
          LABEL: {
            NAME: "fieldTwoLabel",
            LABEL: "Field 2 Label",
          },
          TOOLTIP: {
            NAME: "fieldTwoTooltip",
            LABEL: "Field 2 Tooltip",
          },
          REQUIRED: {
            NAME: "isField2Required",
            LABEL: "Field 2 Required?",
          },
        },
      ],
    },
  },
  FINE_PRINT_TEXT: {
    NAME: "finePrintText",
    LABEL: "Fine Print Text",
    PLACEHOLDER: "Enter text for the fine print",
    EXTRA_TEXT: "",
    LABEL_TO_DISPLAY: "Add text for the fine print",
  },
  SIDEBAR_TEXT: {
    NAME: "sidebarText",
    LABEL: "Sidebar Text",
    PLACEHOLDER: "Enter text for the sidebar",
    EXTRA_TEXT: "",
    LABEL_TO_DISPLAY: "Add text for the sidebar",
  },
  WHITE_LISTED_URLS: {
    NAME: "whitelistUrl",
    LABEL: "URL",
    PLACEHOLDER: "Enter Whitelisted URL",
    EXTRA_TEXT: "Add Whitelisted URLs",
    ADD_BUTTON_TEXT: "Add URL",
    SUB_FIELD_NAME: "url",
    SUB_FIELD_INITIAL_VALUE: "",
    MAX_URLS: 10,
    UNIQUE_URL_MESSAGE: "All URLs should be unique",
    UNIQUE_URL_VALIDATION_NAME: "Unique_URL",
    VALID_URL_VALIDATION_NAME: "Valid_URL",
  },
  ADMIN_PASSWORD: {
    NAME: "adminPassword",
    LABEL: "Password",
    PLACEHOLDER: "Password",
  },
  OFFER_SUMMARY_TEXT: {
    NAME: "offerSummaryText",
    LABEL: "Offer Summary Text",
    PLACEHOLDER: "Enter text for the offer summary",
    EXTRA_TEXT: "",
    LABEL_TO_DISPLAY: "Add text for the offer summary",
  },
  GET_CONTRACT_US_URL: {
    NAME: "nonInternationalUrl",
    LABEL: "Non international URL",
    PLACEHOLDER: "Enter URL",
    EXTRA_TEXT: "Get Contract CTA",
    LABEL_TO_DISPLAY: "For non-international (US only)",
  },
  GET_CONTRACT_INTERNATIONAL_URL: {
    NAME: "internationalUrl",
    LABEL: "International URL",
    PLACEHOLDER: "Enter URL",
    EXTRA_TEXT: "",
    LABEL_TO_DISPLAY: "For international",
  },
};

export const GENERAL_FIELD_VALIDATION_MESSAGE = `Invalid input for `;

export const FIELD_MAX_CHARACTERS = {
  DISPLAY_NAME: 255,
  SLUG_NAME: 100,
  CTA_DEFAULT_VALUE: 255,
  CTA_BUTTON_TEXT: 50,
  CTA_ACTION_URL: 140,
  LANDING_PAGE_URL: 140,
  WHITE_LISTED_URLS: 140,
  FINE_PRINT_TEXT: 1000,
  SIDEBAR_TEXT: 1000,
  OFFER_SUMMARY_TEXT: 6000,
  REPORT_ADDITIONAL_FIELDS: {
    FIELDS: {
      LABEL: 50,
      TOOLTIP: 300,
    },
  },
};

export const FIELD_REGEX = {
  SLUG_NAME: "^[a-z0-9]+[a-z0-9-_]*[a-z0-9]+$",
  CTA_BUTTON_TEXT: "[^s*]([a-zA-z0-9])",
  CTA_ACTION_URL:
    "^http(s)?://(www.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
  HEX_CODE: "^#([0-9a-fA-F]{3}){1,2}$",
  WHITE_LISTED_URLS:
    "[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:.[a-zA-Z]{2,})+",
  UNIQUE_DOMAIN_NAME: /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?=]+)/im,
};

export const ASSETS_IDS = {
  iconMonitor: "icon-monitor-asset",
  iconSlider: "icon-slider-asset",
  iconDiamond: "icon-diamond-asset",
  iconLocker: "icon-locker-asset",
  iconCash: "icon-cash-asset",
  iconPunctuation: "icon-punctuation-asset",
};

export const REVOKE_OPTIONS = [
  { label: "Offer Expired", value: "offerExpired" },
  { label: "More Reports Needed", value: "moreReportsNeeded" },
  { label: "Other", value: "other" },
  { label: "Do not wish to say", value: "doNotWishToSay" },
];
