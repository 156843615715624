import React, { Component } from "react";
import { toast } from "react-toastify";
import _, { get } from "lodash";
import ReactTooltip from "react-tooltip";
import { LineChart, Line } from "recharts";
import styles from "./YourAdvance.module.scss";
import {
  getShortNumber,
  numberWithCommas,
  formatTrendData,
  FunnelCollapseGuide,
} from "./helper";
import {
  MIN_VALUE,
  MAX_VALUE,
  RANGE_STEP,
  MIN_MULTIPLIER_VALUE,
  MAX_MULTIPLIER_VALUE,
  INCOME_SLIDER_STEP,
  INIT_TREND_DATA,
  CUMULATIVE_MAX_PERCENT,
  MIN_TRACK_DISPLAY,
  OTHER_ISRC,
} from "./constants";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  TOP_TRACK,
  DASHBOARD,
  FUNDING,
} from "../constants";
import { TUNE_YOUR_DATA } from "../infoIconMsg";
import generalColors from "../../../styles/global.scss";
import classes from "../Auth/Auth.module.scss";
import { getErrorMessage } from "../helper";
import request from "../../../utils/request";
import LoginHeader from "../../../component/LoginHeader";
import MenuBar from "../../../component/MenuBar";
import ReactRange from "../../../component/Range/ReactRange";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import {
  MUSIC_ICON,
  ARROW_FORWARD,
} from "../../../component/MaterialIcons/constants";
import Loader from "../../../component/Loader";
import HorizontalMenu from "../../../component/HorizontalMenu";
import ContactPopup from "../../../component/ContactPopup";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import Image from "../../../component/Image";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";

class TuneMyData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFirstTime: false,
      firstName: "",
      topTracks: [],
      resetTopTracks: [],
      streamingVolume: "",
      streamingIncome: "",
      soundExchangeIncome: "",
      resetSoundExchangeIncome: "",
      streamingIncomeMax: "",
      streamingIncomeMin: "",
      lastMonthIncome: "",
      artistProfile: {},
      offerStage: {},
      isContactOpen: false,
      shortLoading: false,
      dummyArtist: get(props.history.location, "state.isDemo", false),
      guideData: {},
      cumulativeVisibleTrack: 0,
      minDisplayIndex: 0,
      othersTrackIndex: 0,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    if (get(history.location, "state.tuneData")) {
      const data = get(history.location, "state.tuneData");
      this.setState(
        {
          isFirstTime: data.isFirstTime,
          firstName: data.firstName,
          topTracks: data.top_tracks,
          resetTopTracks: data.top_tracks.map((track) => ({
            ...track,
          })),
          streamingVolume: data.streaming_volume,
          streamingIncome: data.streaming_income,
          soundExchangeIncome: data.sound_exchange_income,
          resetSoundExchangeIncome: data.sound_exchange_income,
          lastMonthIncome: data.last_month_income,
          artistProfile: {
            url: get(data, "profilePicture[2].url"),
            name: data.name,
          },
          offerStage: data.offerStage,
          othersTrackIndex: _.findIndex(data.top_tracks, ["isrc", OTHER_ISRC]),
        },
        this.changeTuneDataSlider
      );
      const state = { ...history.location.state };
      delete state.tuneData;
      history.replace({ ...history.location, state });
      return null;
    }
    this.getAdvanceData();
  }

  componentDidUpdate() {
    setTitle("Verify Data", this.context);
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=true`;

    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          this.setState(
            {
              isFirstTime: res.data.isFirstTime,
              firstName: res.data.firstName,
              topTracks: res.data.top_tracks,
              resetTopTracks: res.data.top_tracks.map((track) => ({
                ...track,
              })),
              streamingVolume: res.data.streaming_volume,
              streamingIncome: res.data.streaming_income,
              soundExchangeIncome: res.data.sound_exchange_income,
              resetSoundExchangeIncome: res.data.sound_exchange_income,
              lastMonthIncome: res.data.last_month_income,
              artistProfile: {
                url: get(res.data, "profilePicture[2].url"),
                name: res.data.name,
              },
              offerStage: res.data.offerStage,
              sliderStreams: res.data.slider_streams,
              dummyArtist: get(res.data, "dummy_artist", false),
              guideData: {
                funnelGuideCustomization: get(
                  res.data,
                  "funnelGuideCustomization",
                  ""
                ),
                funnelGuideSendReports: get(
                  res.data,
                  "funnelGuideSendReports",
                  ""
                ),
                funnelGuideVerification1: get(
                  res.data,
                  "funnelGuideVerification1",
                  ""
                ),
                funnelGuideVerification2: get(
                  res.data,
                  "funnelGuideVerification2",
                  ""
                ),
              },
              othersTrackIndex: _.findIndex(res.data.top_tracks, [
                "isrc",
                OTHER_ISRC,
              ]),
            },
            this.changeTuneDataSlider
          );
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  getLineChart = (data) => (
    <LineChart width={80} height={30} data={formatTrendData(data)}>
      <Line
        dataKey="value"
        stroke={generalColors.primaryColor}
        dot={false}
        strokeWidth={3}
        isAnimationActive={false}
      />
    </LineChart>
  );

  getOtherTracksInfo = () => {
    let otherStreams = 0;
    const {
      othersTrackIndex,
      topTracks,
      minDisplayIndex,
      cumulativeVisibleTrack,
    } = this.state;
    const trendLine = INIT_TREND_DATA;
    topTracks.forEach((track, index) => {
      if (
        track.cumulativeSum > cumulativeVisibleTrack &&
        minDisplayIndex < index &&
        MIN_TRACK_DISPLAY < index + 1
      ) {
        otherStreams += track.streams_last_month;
        for (let i = 0; i < trendLine.length; i++) {
          trendLine[i] += track.trend_line_data[i];
        }
      }
    });
    return (
      (othersTrackIndex !== -1 || !!otherStreams) && (
        <tr className={styles.partitionTitle}>
          <td>
            <div className={styles.trackProfile}>
              <div className={styles.trackName}>
                <p>All other tracks</p>
                <p className={styles.mobLastMonth}>
                  Streams last month
                  {othersTrackIndex !== -1 ? (
                    <span>
                      {topTracks[othersTrackIndex] &&
                        getShortNumber(
                          topTracks[othersTrackIndex].streams_last_month
                        )}
                    </span>
                  ) : (
                    <span>{getShortNumber(otherStreams)}</span>
                  )}
                </p>
              </div>
            </div>
          </td>
          <td>
            {othersTrackIndex !== -1 ? (
              <div className={styles.chartData}>
                <p>
                  {getShortNumber(
                    get(topTracks, [othersTrackIndex, "streams_last_month"], 0)
                  )}
                </p>
                {this.getLineChart(
                  get(topTracks, [othersTrackIndex, "trend_line_data"], [])
                )}
              </div>
            ) : (
              <div className={styles.chartData}>
                <p>{getShortNumber(otherStreams)}</p>
                {this.getLineChart(trendLine)}
              </div>
            )}
          </td>
          <td></td>
        </tr>
      )
    );
  };

  changeTuneDataSlider = (value, isrc) => {
    let sum = 0;
    let cumulativeSum = 0;
    const data = this.state.topTracks.map((track) => {
      if (track.isrc === isrc) {
        track.streaming_info_share = value;
      }
      sum =
        sum +
        (track.streaming_info_share / 100) *
          track.slider_streams *
          track.dollar_per_stream;
      cumulativeSum =
        cumulativeSum +
        (track.streaming_info_share / 100) * track.slider_streams;
      track.cumulativeSum = cumulativeSum;
      return track;
    });

    let minDisplayIndex = this.state.minDisplayIndex;
    data.forEach((track, index) => {
      if (
        track.cumulativeSum <=
          data[data.length - 1].cumulativeSum * CUMULATIVE_MAX_PERCENT &&
        minDisplayIndex < index
      ) {
        minDisplayIndex = index;
      }
    });

    const minRange =
      Math.round((sum * MIN_MULTIPLIER_VALUE) / INCOME_SLIDER_STEP) *
      INCOME_SLIDER_STEP;
    let maxRange =
      Math.round((sum * MAX_MULTIPLIER_VALUE) / INCOME_SLIDER_STEP) *
      INCOME_SLIDER_STEP;
    maxRange = maxRange <= minRange ? minRange + INCOME_SLIDER_STEP : maxRange;
    this.setState({
      minDisplayIndex,
      topTracks: data,
      cumulativeVisibleTrack:
        data[data.length - 1].cumulativeSum * CUMULATIVE_MAX_PERCENT,
      streamingIncomeMin: minRange,
      streamingIncomeMax: maxRange,
      lastMonthIncome:
        this.state.lastMonthIncome ||
        Math.round((minRange + maxRange) / 3 / 50) * 50,
    });
  };

  soundExchangeIncomeChange = (e) => {
    this.setState({ soundExchangeIncome: e.target.value });
  };

  handleCancel = () => {
    this.setState(
      {
        tuneData: false,
        minDisplayIndex: 0,
        soundExchangeIncome: this.state.resetSoundExchangeIncome,
        topTracks: this.state.resetTopTracks.map((track) => ({ ...track })),
      },
      this.changeTuneDataSlider
    );
  };

  getSelevtedSliderValue = () => {
    const value =
      parseFloat(this.state.lastMonthIncome) /
      (Math.round(
        parseFloat(
          this.state.streamingIncomeMin + this.state.streamingIncomeMax
        ) /
          3 /
          50
      ) *
        50);
    if (value > MAX_MULTIPLIER_VALUE) return MAX_MULTIPLIER_VALUE;
    else if (value < MIN_MULTIPLIER_VALUE) return MIN_MULTIPLIER_VALUE;
    else return value;
  };

  handleSaveTunedData = () => {
    this.setState({ shortLoading: true });
    const payload = {
      streaming_income: this.state.streamingIncome,
      sound_exchange_income: this.state.soundExchangeIncome,
      last_month_income: this.state.lastMonthIncome,
      selected_slider_value: this.getSelevtedSliderValue(),
      top_tracks: this.state.topTracks.map((track) => ({
        isrc: track.isrc,
        track_name: track.track_name,
        streaming_info_share: track.streaming_info_share,
      })),
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ tuneData: false });
        if (res.status) {
          this.setState({
            resetSoundExchangeIncome: this.state.soundExchangeIncome,
            resetTopTracks: this.state.topTracks.map((track) => ({ ...track })),
          });
          this.props.history.push({
            pathname: FUNDING,
            state: { isDemo: this.state.dummyArtist },
          });
          toast.success(get(res, "message"));
          return true;
        }
        this.setState({ shortLoading: false });
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ shortLoading: false });
        toast.error(getErrorMessage(err));
      });
  };

  renderLastMonthIncome = () => {
    let lastMonthIncome = "";
    if (
      this.state.streamingIncomeMin !== "" &&
      this.state.streamingIncomeMax !== ""
    ) {
      if (
        parseFloat(this.state.lastMonthIncome) >
        parseFloat(this.state.streamingIncomeMax)
      ) {
        lastMonthIncome = this.state.streamingIncomeMax;
        this.setState({ lastMonthIncome });
      } else if (
        parseFloat(this.state.lastMonthIncome) <
        parseFloat(this.state.streamingIncomeMin)
      ) {
        lastMonthIncome = this.state.streamingIncomeMin;
        this.setState({ lastMonthIncome });
      }
      return (
        <ReactRange
          data-testid="LastMonthRange"
          className={styles.reactRangeLastMonth}
          values={
            lastMonthIncome ? [lastMonthIncome] : [this.state.lastMonthIncome]
          }
          onChange={() => {}}
          onFinalChange={(values) => {
            this.setState({
              lastMonthIncome: values[0],
            });
          }}
          min={this.state.streamingIncomeMin}
          max={this.state.streamingIncomeMax}
          thumbText={["$", ""]}
          step={INCOME_SLIDER_STEP}
          yourAdvance
          currencyValue
        />
      );
    }
    return null;
  };

  handleContactOpen = () => {
    this.setState({ isContactOpen: true });
  };
  handleContactClose = () => {
    this.setState({ isContactOpen: false });
  };

  renderButton = () => (
    <>
      <button
        className={`btn btn-link ${styles.secondary}`}
        onClick={this.handleCancel}
      >
        Reset
      </button>
      {get(this.state.offerStage, "verificationStatus.publicProfile") ? (
        <button
          data-testid="saveAndVerify"
          className={styles.primary}
          onClick={this.handleSaveTunedData}
        >
          Save and verify
        </button>
      ) : (
        <button className={styles.primary} onClick={this.handleSaveTunedData}>
          Next
        </button>
      )}
    </>
  );

  isCumulativeSumTracks = (track, index) =>
    track.cumulativeSum <= this.state.cumulativeVisibleTrack ||
    this.state.minDisplayIndex >= index ||
    MIN_TRACK_DISPLAY >= index + 1;

  render() {
    return (
      <>
        <LoginHeader isDemo={this.state.dummyArtist} />
        <div
          className={`${styles.pageContainer} ${styles.dataReviewContainer}`}
        >
          <MenuBar
            offerStage={this.state.offerStage}
            isDemo={this.state.dummyArtist}
            {...this.props}
          />
          <div className={styles.mainContainer}>
            <HorizontalMenu
              isDemo={this.state.dummyArtist}
              offerStage={this.state.offerStage}
              {...this.props}
            />
            <div className={styles.scrollContainer}>
              <div className={styles.container}>
                {!this.state.loading && (
                  <>
                    <div className={styles.title}>
                      <h1>
                        Verify Streaming Data
                        <i
                          className={styles.infoIcon}
                          data-tip={TUNE_YOUR_DATA}
                          data-for="your_advance"
                          data-place="top"
                        ></i>
                      </h1>
                      <ReactTooltip
                        delayHide={100}
                        class={styles.tooltip}
                        effect="solid"
                        place="top"
                        id="your_advance"
                      />
                    </div>
                    <div className={`${styles.welcomeModal}`}>
                      <div className={styles.dataReviewSubHeading}>
                        <p>
                          How much you own of your tracks directly impacts your
                          funding level.
                          <br />
                          Use the sliders to adjust your share of master
                          ownership for your top tracks.
                          <br />
                          <b>Note:</b> it may take 3-6 weeks for recent releases
                          to register.
                        </p>
                      </div>
                    </div>

                    <div className={`${styles.welcomeModal}`}>
                      <div className={styles.dataReviewSecondHeading}>
                        <table>
                          <tbody>
                            <tr className={styles.lastModalRow}>
                              <td>
                                <p>Streams last month:</p>
                                <p className={classes.specialChar}>
                                  {getShortNumber(this.state.streamingVolume)}
                                </p>
                              </td>
                              <td>
                                <p>Est. income last month :</p>

                                <div>
                                  <p className={classes.specialChar}>
                                    $
                                    {numberWithCommas(
                                      this.state.streamingIncomeMin
                                    )}
                                  </p>
                                  {this.renderLastMonthIncome()}
                                  <p className={classes.specialChar}>
                                    $
                                    {numberWithCommas(
                                      this.state.streamingIncomeMax
                                    )}
                                  </p>
                                </div>
                              </td>
                              {!this.state.dummyArtist && (
                                <td className={styles.notLookRight}>
                                  <span
                                    className={styles.contactSubText}
                                    onClick={this.handleContactOpen}
                                  >
                                    Doesn't look right?
                                  </span>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className={`${styles.modalBody}`}>
                        <table className={styles.tuneDataSecondTable}>
                          <thead>
                            <tr>
                              <th>
                                <p>TOP TRACKS</p>
                              </th>
                              <th>
                                <p>12-wk trend & streams last month</p>
                              </th>
                              <th>
                                <p>SHARE OF STREAMING INCOME</p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.topTracks.map(
                              (track, index) =>
                                (this.isCumulativeSumTracks(track, index) ||
                                  this.state.othersTrackIndex !== -1) &&
                                this.state.othersTrackIndex !== index && (
                                  <tr key={track.isrc}>
                                    <td>
                                      <div className={styles.trackProfile}>
                                        {track.image_url ? (
                                          <Image
                                            src={track.image_url}
                                            alt="album"
                                            imageType={IMAGE_TYPE.PROFILE}
                                          />
                                        ) : (
                                          <SvgIcons icon={MUSIC_ICON} />
                                        )}
                                        <div className={styles.trackName}>
                                          <p>{track.track_name}</p>
                                          <p className={styles.mobLastMonth}>
                                            Streams last month
                                            <span>
                                              {getShortNumber(
                                                track.streams_last_month
                                              )}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className={styles.chartData}>
                                        <p>
                                          {getShortNumber(
                                            track.streams_last_month
                                          )}
                                        </p>
                                        {this.getLineChart(
                                          track.trend_line_data
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div className={styles.mobSliderIncome}>
                                        <span>Share of streaming income</span>
                                        <ReactRange
                                          data-testid="ReactRange"
                                          yourAdvance={FUNDING}
                                          className={styles.reactRange}
                                          values={[track.streaming_info_share]}
                                          onChange={() => {}}
                                          onFinalChange={(values) => {
                                            this.changeTuneDataSlider(
                                              values[0],
                                              track.isrc
                                            );
                                          }}
                                          min={MIN_VALUE}
                                          max={MAX_VALUE}
                                          step={RANGE_STEP}
                                          valueAlign="right"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )
                            )}
                            {this.getOtherTracksInfo()}
                          </tbody>
                        </table>
                      </div>

                      <div
                        className={`${styles.dataReviewSubHeading} ${styles.dataTuneButton}`}
                      >
                        <div className={`${styles.buttonContainer} `}>
                          {this.state.dummyArtist ? (
                            <button
                              data-testid="VerifiedButton"
                              className={styles.demoBtn}
                              onClick={this.handleSaveTunedData}
                            >
                              How Would I Customize My Funding
                              <SvgIcons icon={ARROW_FORWARD} />
                            </button>
                          ) : (
                            this.renderButton()
                          )}
                        </div>
                      </div>
                    </div>
                    {this.state.dummyArtist && (
                      <FunnelCollapseGuide
                        guideData={this.state.guideData}
                        {...this.props}
                      />
                    )}
                  </>
                )}
                {this.state.loading && <Loader backgroundNone />}
                {this.state.shortLoading && <ShortCallLoader />}
              </div>
            </div>
          </div>
          <ContactPopup
            isOpen={this.state.isContactOpen}
            onRequestClose={this.handleContactClose}
          />
        </div>
      </>
    );
  }
}

TuneMyData.contextType = ThemeContext;
export default TuneMyData;
