import React from "react";
import { get } from "lodash";
import LoginHeader from "../../../component/LoginHeader";
import styles from "./DemoArtists.module.scss";
import Navigator from "../../../component/Navigation/Navigator";
import LeftPanel from "../AdminDashboard/LeftPanel";
import Loader from "../../../component/Loader";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { ARTIST_COLUMN } from "./constants";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import { EDIT_ICON } from "../../../component/MaterialIcons/constants";
import { setTitle } from "../../../component/ThemeManager/helper";
import {
  API_URL,
  ADMIN,
  DEMO_ARTIST_EDIT,
  DEMO_ARTIST_CREATE,
  ARTIST_API,
  DEMO_ARTIST_API,
  SAVE_DEMO_ARTIST,
  DEMO_DASHBOARD,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import Switch from "../AdminDashboard/Switch";
import generalColors from "../../../styles/global.scss";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";

class DemoArtists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      artists: [],
    };
  }

  componentDidUpdate() {
    setTitle("Demo Artists", this.context);
  }

  componentDidMount() {
    this.getDemoArtists();
  }

  getDemoArtists = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${DEMO_ARTIST_API}`;
    request(requestUrl, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            artists: get(res, "data", []),
          });
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };

  handleActivationArtist = (artist, active) => {
    this.setState({ loading: true });
    const data = {
      method: "PATCH",
      body: { _id: artist._id, active },
    };
    const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${SAVE_DEMO_ARTIST}`;
    request(requestUrl, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            artists: get(res, "data", []),
          });
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };

  actionSwitchRender = (artist) => (
    <Switch
      id={get(artist, "_id")}
      title="Activate/Deactivate Demo Artist"
      isOn={!get(artist, "deactivated", true)}
      onColor={generalColors.primaryColor}
      data-test="switch"
      handleToggle={(e) => {
        this.handleActivationArtist(artist, e.target.checked);
      }}
    />
  );

  copyDemoLink = (id) => {
    navigator.clipboard &&
      navigator.clipboard.writeText(
        `${get(this.context, "frontendDomain")}${DEMO_DASHBOARD}/${id}`
      );
  };

  render() {
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
          <Navigator {...this.props} />
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div className={`${styles.title} ${styles.innerTitle}`}>
              <h1>Demo Artists</h1>
            </div>
            <div className={styles.demoArtistsList}>
              <div className={styles.createBtn}>
                <button
                  onClick={() => this.props.history.push(DEMO_ARTIST_CREATE)}
                >
                  Create
                </button>
              </div>
              <TableContainer className={styles.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {ARTIST_COLUMN.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.artists.length ? (
                      this.state.artists.map((artist) => (
                        <TableRow key={artist._id}>
                          <TableCell align="left">
                            <div className={styles.profileColumn}>
                              <img
                                src={get(artist, "profilePicture[2].url", "")}
                                alt="profile"
                                height={60}
                              />
                              <p>{artist.name}</p>
                            </div>
                          </TableCell>
                          <TableCell align="center">
                            <div
                              className={styles.details}
                              onClick={() => this.copyDemoLink(artist._id)}
                              title={`Click to Copy Link`}
                              data-testid="copyLink"
                            >
                              <p className={styles.genre}>
                                {artist.musicGenre}
                              </p>
                              <p>{artist._id}</p>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <div className={styles.actionIcon}>
                              <SvgIcons
                                icon={EDIT_ICON}
                                title="Edit"
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: DEMO_ARTIST_EDIT,
                                    state: { artist },
                                  });
                                }}
                              />
                              {this.actionSwitchRender(artist)}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <p className={styles.noData}>No Data</p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        {this.state.loading && <Loader />}
      </>
    );
  }
}

DemoArtists.contextType = ThemeContext;
export default DemoArtists;
