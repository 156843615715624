import styles from "../../styles/global.scss";
export const sliderStyle = {
  handle: {
    height: 20,
    width: 20,
    backgroundColor: styles.sliderColor,
    marginTop: -8,
    borderColor: "transparent",
    boxShadow: "none",
  },
  track: { backgroundColor: styles.sliderColor },
  dot: {
    bottom: -3,
    marginLeft: -6,
    height: 10,
    width: 10,
    border: "none",
    backgroundColor: styles.light,
  },
  activeDot: { backgroundColor: styles.sliderColor, border: "none" },
};
