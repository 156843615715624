import { getPartnerName } from "../../component/ThemeManager/helper";

export const MYACCOUNT = `Your account details are only visible to the ${getPartnerName()} support team. These details are used to help verify your identity on the platform.`;
export const STATS =
  "We have gathered stats about this artist from publicly-available data. In rare cases, this data may not be 100% up to date. If something looks wrong, please contact us to evaluate.";
export const TUNE_YOUR_DATA =
  "Confirm your income share on your top tracks so we can make the most accurate funding offers. It's OK to make estimates if you don’t have the exact splits. Remember, this is your share of streaming income, not publishing.";
export const LASTMONTH_TOTAL =
  "Your approximate total streams per month across major global platforms. If it is off by more than 25%, you should contact us.";
export const LASTMONTH_STREAMING_INCOME =
  "Your approximate streaming income per month across major global platforms. If it is off by more than 25%, you should contact us. (add link to open contact form)";
export const LASTMONTH_SOUNDEXCHANGE_INCOME =
  "If you receive regular income from Soundexchange from radio airplay, Pandora, Sirius or other sources, enter that approximate monthly dollar amount here. If you don't, that's OK. Most artists don't. If you don't know for sure, enter 0, and we'll help you figure out if you are due more monthly income.";
export const YOUR_ADVANCEMSG =
  "Adjust the terms below to fit your needs. Change the term length, the music included, and the amount of income you want to continue to receive during the term, and the advance amount will change.";
export const LAST_MONTH_INCOME =
  "Indicate the streaming income on your most recent distribution reports.";
export const INCLUDED_CONTENT =
  "Use this slider to change the music covered by the advance.";
export const TERM =
  "This is the projected length of time you'll share revenue with our investors.";
export const SHARE_OF_CATALOG =
  "You can keep a share of streaming and SoundExchange income from your catalog during the term. We'll pay it through within days of receiving it from your distributor.";
export const SHARE_OF_NEWRELEASE =
  "You can keep a share of streaming and SoundExchange income from your new music during the term. We'll pay it through within days of receiving it from your distributor.";
export const ARTIST_SHARE_OF_INCOME =
  "This shows the share of streaming and soundexchange income you will receive before your advance is repaid, and then how much it increases after your advance is recouped.  And, remember, once your advance is repaid and the term is up, you will always receive 100% of your streaming and soundexchange income.";
export const SOCIAL_NETWORK_PAGE =
  "A complete social profile will help us put together the best possible offer.";
export const SEND_REPORT_MSG =
  "We ask for reports in order to validate our assumptions on your streaming and income.";
export const ADJUST_TERM_TOOLTIP =
  "Adjust the sliders to choose your funding options. All offer options on this page are confirmed.";
