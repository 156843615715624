import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import _, { get, set } from "lodash";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import ReactModal from "react-modal";
import selectStyle from "../../../component/UploadFiles/selectStyle";
import { FormControlLabel, FormControl, Checkbox } from "@material-ui/core";
import styles from "./SendReports.module.scss";
import {
  getRadioInformationText,
  getRadioText,
  OTHER_DISTRIBUTOR,
  areBothFieldsExist,
  isOneFieldRequired,
  reportTableListRender,
  OVERALL_STATUS,
} from "./constants";
import { SEND_REPORT_MSG } from "../infoIconMsg";
import MenuBar from "../../../component/MenuBar";
import LoginHeader from "../../../component/LoginHeader";
import UploadFiles from "../../../component/UploadFiles/UploadFiles";
import actionStyles from "../../../component/UploadFiles/UploadFiles.module.scss";
import {
  TOP_TRACK,
  ARTIST_API,
  USER_API,
  API_URL,
  DASHBOARD,
  REPORT,
  SEND_REPORTS_API,
  FUNDING,
  ACTIVE_DISTRIBUTORS,
  DISTRIBUTORS_API,
  SOCIAL_NETWORKS,
  DISTRIBUTOR_REPORTS_API,
  DEMO_DASHBOARD,
  EXIT_DEMO,
  TRACK_REASON_UPDATE,
} from "../constants";
import request from "../../../utils/request";
import { getErrorMessage } from "../helper";
import Loader from "../../../component/Loader";
import { VERIFIED } from "../../../component/MenuBar/constants";
import HorizontalMenu from "../../../component/HorizontalMenu";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import {
  setTitle,
  getPartnerName,
} from "../../../component/ThemeManager/helper";
import FormikForm from "../../../component/Form/FormikForm";
import { Form } from "formik";
import FormField from "../../../component/FormField/FormField";
import { FIELD_REGEX } from "../AdminDashboard/WhiteLabel/constants";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { FunnelCollapseGuide } from "../YourAdvance/helper";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import { ARROW_FORWARD } from "../../../component/MaterialIcons/constants";
import ShortCallLoader from "../../../component/Loader/ShortCallLoader";
import {
  AckSuccessModal,
  AckReportIssueModal,
  AckTrackIssueModal,
  AckErrorModal,
} from "./helper";
import ContactPopup from "../../../component/ContactPopup";

class SendReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFirstTime: false,
      selectOptions: [],
      files: {},
      offerStage: {},
      loading: false,
      shortLoading: false,
      sentReports: [],
      listLoading: false,
      uploadModal: false,
      shareReports: "yes",
      initialValues: {
        label1: "",
        label2: "",
        atLeastOne: "",
      },
      distributorInfo: {
        id: "",
        name: "",
        instructionsUrl: "",
        instructionsHTML: "",
        logoUrl: "",
      },
      selectedDistributor: "",
      mobileModal: true,
      uploadConfirmation: false,
      deleteReport: {},
      dummyArtist: get(props.history.location, "state.isDemo", false),
      guideData: {},
      isSignup: false,
      ackSuccessModal: false,
      ackReportIssueModal: false,
      ackTrackIssueModal: false,
      ackErrorModal: false,
      reportIssueData: [],
      failedReports: [],
      trackIssueData: [],
      isContactOpen: false,
    };
    this.formRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    setTitle("Send Reports", this.context);
    if (
      get(this.context, "reportFields.isDistributor") === "No" &&
      prevState.shareReports === "yes"
    ) {
      this.setState({ shareReports: "no" });
    }
  }

  componentDidMount() {
    this.getStatusData();
  }

  getStatusData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false&reportAnalytics=true`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          if (get(res.data, "dummy_artist", false)) {
            this.setState({
              offerStage: res.data.offerStage,
              dummyArtist: get(res.data, "dummy_artist", false),
              guideData: {
                funnelGuideCustomization: get(
                  res.data,
                  "funnelGuideCustomization",
                  ""
                ),
                funnelGuideSendReports: get(
                  res.data,
                  "funnelGuideSendReports",
                  ""
                ),
                funnelGuideVerification1: get(
                  res.data,
                  "funnelGuideVerification1",
                  ""
                ),
                funnelGuideVerification2: get(
                  res.data,
                  "funnelGuideVerification2",
                  ""
                ),
              },
            });
            this.getActiveDistributors();
            return true;
          }
          this.setState({
            offerStage: res.data.offerStage,
            artistProfile: {
              url: get(res.data, "profilePicture[2].url"),
              name: res.data.name,
            },
          });
          this.checkStatus(
            get(res.data, "offerStage.verificationStatus.funding.value")
          );
          switch (get(res.data, "reportAnalytics.status")) {
            case OVERALL_STATUS[2]:
              this.setState({
                ackTrackIssueModal: false,
                trackIssueData: get(
                  res.data,
                  "reportAnalytics.missing_isrc",
                  []
                ),
              });
              break;
            default:
              break;
          }
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  checkStatus = (fundingData) => {
    if (fundingData === VERIFIED) {
      this.setState({ loading: true });
      if (get(this.props.history.location, "state.redirectMsg")) {
        toast.error(get(this.props.history.location, "state.redirectMsg"));
        const state = { ...this.props.history.location.state };
        delete state.redirectMsg;
        this.props.history.replace({ ...this.props.history.location, state });
      }

      this.getActiveDistributors();
      return true;
    }
    this.props.history.push({
      pathname: FUNDING,
      state: { redirectMsg: "First, please save your funding options." },
    });
  };

  getActiveDistributors = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };

    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${ACTIVE_DISTRIBUTORS}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const options = res.data.map((option) => {
            return {
              label: get(option, "name", ""),
              value: get(option, "_id"),
            };
          });
          options.push({ label: "Other", value: OTHER_DISTRIBUTOR, name: "" });
          this.setState({ selectOptions: options });
          this.getUploadedFiles();
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  setFieldValues = (data) => {
    const initialValues = this.state.initialValues;
    data.forEach((field) => {
      if (get(this.context, "reportFields.label1") === field.name) {
        initialValues.label1 = field.value;
      }
      if (get(this.context, "reportFields.label2") === field.name) {
        initialValues.label2 = field.value;
      }
    });
    return initialValues;
  };

  handleCloseModalWithData = () =>
    this.setState(
      { ackReportIssueModal: false, ackTrackIssueModal: false },
      this.getUploadedFiles
    );

  getUploadedFiles = () => {
    this.setState({
      loading: true,
    });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${REPORT}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const initialValues = this.setFieldValues(
            get(res, "data.reportFields", [])
          );
          this.setState({
            files: get(res.data, "reports"),
            initialValues,
          });
          this.getSentReports();
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  onDistributorChange = (option, isOther) => {
    if (option.value === OTHER_DISTRIBUTOR || isOther === true) {
      const distributorInfo = {
        _id: option.name,
        name: option.name,
        otherName: `other: ${option.name}`,
        isOther: true,
      };
      distributorInfo.isEditMode = isOther === true;
      this.setState({
        distributorInfo,
        uploadModal: true,
        selectedDistributor: null,
      });
      return true;
    }
    this.setState({ loading: true, selectedDistributor: null });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${DISTRIBUTORS_API}?id=${option.value}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const { instructionsUrl, instructionsHTML, logoUrl, name } = res.data;
          const distributorInfo = {
            _id: res.data._id,
            name,
            instructionsUrl,
            instructionsHTML,
            logoUrl,
          };
          this.setState({ uploadModal: true, distributorInfo });
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  validationSchema = () => {
    const validationObject = {};
    const fieldsData = [];
    if (get(this.context, "reportFields.label1")) {
      validationObject.label1 = Yup.string()
        .trim()
        .matches(
          FIELD_REGEX.CTA_BUTTON_TEXT,
          `Invalid input. Please provide valid input.`
        )
        .max(300)
        .label(`${get(this.context, "reportFields.label1", "")}`);
      fieldsData.push(`${get(this.context, "reportFields.label1", "")}`);
      if (get(this.context, "reportFields.isField1Required")) {
        validationObject.label1 = Yup.string()
          .required()
          .trim()
          .matches(
            FIELD_REGEX.CTA_BUTTON_TEXT,
            `Invalid input. Please provide valid input.`
          )
          .max(300)
          .label(`${get(this.context, "reportFields.label1", "")}`);
      }
    }
    if (get(this.context, "reportFields.label2")) {
      validationObject.label2 = Yup.string()
        .trim()
        .matches(
          FIELD_REGEX.CTA_BUTTON_TEXT,
          `Invalid input. Please provide valid input.`
        )
        .max(300)
        .label(`${get(this.context, "reportFields.label2", "")}`);
      fieldsData.push(`${get(this.context, "reportFields.label2", "")}`);
      if (get(this.context, "reportFields.isField2Required")) {
        validationObject.label2 = Yup.string()
          .required()
          .trim()
          .matches(
            FIELD_REGEX.CTA_BUTTON_TEXT,
            `Invalid input. Please provide valid input.`
          )
          .max(300)
          .label(`${get(this.context, "reportFields.label2", "")}`);
      }
    }

    if (
      !get(this.context, "reportFields.isField1Required") &&
      !get(this.context, "reportFields.isField2Required") &&
      fieldsData.length
    ) {
      const msg =
        fieldsData.length > 1
          ? `Please provide either ${fieldsData[0]} or ${fieldsData[1]}`
          : `${fieldsData[0]} is required field`;
      validationObject.atLeastOne = Yup.string().when(["label1", "label2"], {
        is: (v1, v2) => !v1 && !v2,
        then: Yup.string().required(msg),
      });
    }
    return Yup.object().shape({
      ...validationObject,
    });
  };
  getSentReports = () => {
    this.setState({ listLoading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${SEND_REPORTS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ listLoading: false });
        if (res.status) {
          this.setState({ sentReports: res.data.reports });
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ listLoading: false });
        toast.error(getErrorMessage(err));
      });
  };

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  updateOthersList = (key, distributorId) => {
    const { files } = this.state;
    const data = _.remove(
      files[""].reports,
      (file) => key.indexOf(file.key) !== -1
    );
    !files[""].reports.length && delete files[""];
    const existingReports = get(files, `${distributorId}.reports`, []);
    set(files, `${distributorId}.reports`, [...existingReports, ...data]);
    files[distributorId]._id = distributorId;
    files[distributorId].name = distributorId;
    files[distributorId].isOther = true;
    this.setState({ files });
  };

  deleteAllReport = (distributorId, isOther) => {
    this.setState({ loading: true });
    const payload = isOther ? { otherName: distributorId } : { distributorId };
    const data = {
      method: "DELETE",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${DISTRIBUTOR_REPORTS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const files = this.state.files;
          delete files[distributorId];
          this.setState({ files });
          toast.success("Deleted successfully");
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  deleteReport = (key, fileName, distributorId, isSent) => {
    this.setState({ loading: true });
    const payload = {
      key,
    };
    const data = {
      method: "DELETE",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${REPORT}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          if (isSent) {
            const { sentReports } = this.state;
            const reportIndex = _.findIndex(sentReports, { key });
            sentReports[reportIndex].isDeleted = true;
            this.setState({ sentReports, deleteReport: {} });
            toast.success(`File "${fileName}" deleted successfully.`);
            return true;
          }
          const files = { ...this.state.files };
          _.remove(files[distributorId].reports, { key });
          !files[distributorId].reports.length && delete files[distributorId];
          this.setState({ files });
          toast.success(`File "${fileName}" deleted successfully.`);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        return false;
      });
  };

  addReport = (distributorInfo, file) => {
    const { distributorInfo: currentDistributor, files } = this.state;
    const existingReports = get(files, `${distributorInfo._id}.reports`, []);
    set(files, `${distributorInfo._id}.reports`, [...existingReports, file]);
    files[distributorInfo._id]._id = currentDistributor._id;
    files[distributorInfo._id].name = currentDistributor.name;
    files[distributorInfo._id].logoUrl = currentDistributor.logoUrl;
    files[distributorInfo._id].isOther = currentDistributor.isOther;
    this.setState({ files });
  };

  handleCloseReportsModal = () =>
    this.setState({
      uploadModal: false,
      mobileModal: false,
      deleteReport: {},
      ackReportIssueModal: false,
    });

  getFilesData = (distributorInfo) => {
    if (distributorInfo.isOther && !distributorInfo.isEditMode) {
      return [];
    }
    return get(this.state, `files.${distributorInfo._id}.reports`, []);
  };

  uploadReportsModal = () => {
    const { distributorInfo } = this.state;
    return (
      <ReactModal
        isOpen={this.state.uploadModal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.handleCloseReportsModal}
        className={styles.uploadModal}
        overlayClassName={styles.modalOverlay}
      >
        <UploadFiles
          files={this.getFilesData(distributorInfo)}
          handleClose={this.handleCloseReportsModal}
          distributorInfo={distributorInfo}
          deleteReport={this.deleteReport}
          updateOthersList={this.updateOthersList}
          addReport={this.addReport}
          offerStage={this.state.offerStage}
          radioSelection={this.state.shareReports}
          setLoading={this.setLoading}
          dummyArtist={this.state.dummyArtist}
          {...this.props}
        />
      </ReactModal>
    );
  };

  mobileScreenModal = () => {
    return (
      <ReactModal
        isOpen={this.state.mobileModal}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.handleCloseReportsModal}
        className={styles.mobileModal}
        overlayClassName={styles.mobileModalOverlay}
      >
        <div>
          <span
            className={styles.closeBtn}
            onClick={this.handleCloseReportsModal}
          >
            &times;
          </span>
          <i>i</i>
          <h2>Heads up!</h2>
          <p>
            You’ll find the next few steps much easier on a computer than on a
            phone.
          </p>
          <p>
            We suggest you login to your {getPartnerName()} account from a
            laptop or desktop to continue.
          </p>
        </div>
      </ReactModal>
    );
  };

  deleteSentReportModal = () => {
    const { deleteReport } = this.state;
    return (
      <ReactModal
        isOpen={get(this.state, "deleteReport.isOpen", false)}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.handleCloseReportsModal}
        className={styles.deleteModal}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.deleteModalContainer}>
          <span
            className={styles.closeBtn}
            onClick={this.handleCloseReportsModal}
          >
            &times;
          </span>
          <h2>
            Are you sure you want to delete{" "}
            <span>{this.state.deleteReport.name}</span> report?
          </h2>
          <div className={styles.buttonContainer}>
            <button
              className={styles.btnPrimary}
              onClick={() => {
                this.deleteReport(
                  deleteReport.key,
                  deleteReport.name,
                  null,
                  true
                );
              }}
            >
              Yes
            </button>
            <button
              className={styles.btnLink}
              onClick={this.handleCloseReportsModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </ReactModal>
    );
  };

  renderRadioUI = () => {
    const { shareReports } = this.state;
    const displayName = get(this.context, "displayName", "");
    return (
      <>
        <p>{getRadioInformationText(displayName)}</p>
        <FormControl component="fieldset">
          <FormControlLabel
            onChange={(e) => {
              this.setState({
                shareReports: e.target.checked ? "yes" : "no",
              });
            }}
            data-testid="SendReportsRadio"
            value={shareReports}
            label={getRadioText(displayName).trueValue}
            control={
              <Checkbox
                checked={shareReports === "yes"}
                className={styles.checkbox}
              />
            }
            className={shareReports === "yes" ? styles.checked : ""}
          />
        </FormControl>
      </>
    );
  };

  fieldsRender = () => {
    const { reportFields } = this.context;
    return (
      this.state.shareReports === "yes" && (
        <FormikForm
          initialValues={this.state.initialValues}
          enableReinitialize
          validationSchema={this.validationSchema}
          innerRef={this.formRef}
          onSubmit={() => {}}
        >
          <Form className={styles.form}>
            {areBothFieldsExist(this.context) &&
              !isOneFieldRequired(this.context) && (
                <div className="form-group  w-100 m-0">
                  <div className={styles.formInfoText}>
                    Please fill out at least one of these fields
                  </div>
                </div>
              )}
            {get(reportFields, "label1") && (
              <div className="form-group d-flex align-items-center">
                <FormField
                  name="label1"
                  placeholder={get(reportFields, "label1", "")}
                  label={`${get(reportFields, "label1", "")}${
                    get(reportFields, "isField1Required") ||
                    !areBothFieldsExist(this.context)
                      ? " (Required)"
                      : ""
                  }`}
                />
                {get(reportFields, "tooltip1") && (
                  <>
                    <i
                      className={styles.infoIcon}
                      data-tip={get(reportFields, "tooltip1")}
                      data-for="fieldLabel1"
                      data-place="top"
                    ></i>
                    <ReactTooltip
                      delayHide={100}
                      class={styles.tooltip}
                      effect="solid"
                      place="top"
                      id="fieldLabel1"
                    />
                  </>
                )}
              </div>
            )}
            {get(reportFields, "label2") && (
              <div className="form-group  d-flex align-items-center">
                <FormField
                  name="label2"
                  placeholder={get(reportFields, "label2", "")}
                  label={`${get(reportFields, "label2", "")}${
                    get(reportFields, "isField2Required") ||
                    !areBothFieldsExist(this.context)
                      ? " (Required)"
                      : ""
                  }`}
                />
                {get(reportFields, "tooltip2") && (
                  <>
                    <i
                      className={styles.infoIcon}
                      data-tip={get(reportFields, "tooltip2")}
                      data-for="fieldLabel2"
                      data-place="top"
                    ></i>
                    <ReactTooltip
                      delayHide={100}
                      class={styles.tooltip}
                      effect="solid"
                      place="top"
                      id="fieldLabel2"
                    />
                  </>
                )}
              </div>
            )}
            <div className="form-group  w-100 m-0">
              <FormField
                name={"atLeastOne"}
                type="text"
                as={"textMsg"}
                errorClass={styles.error}
                containerClass={styles.containerClass}
              />
            </div>
          </Form>
        </FormikForm>
      )
    );
  };

  getFieldObject = (formRef, reportFields, label) =>
    get(reportFields, label)
      ? [
          {
            name: `${get(reportFields, label)}`,
            value: `${formRef.values[label]}`,
          },
        ]
      : [];

  handleSendReports = async () => {
    const body = { reportFields: [] };
    const { reportFields } = this.context;
    let formRef = this.formRef.current;
    if (
      get(reportFields, "isDistributor") === "Yes" &&
      this.state.shareReports === "yes" &&
      formRef
    ) {
      await formRef.submitForm();
      formRef = this.formRef.current;
      if (!formRef.isValid) {
        return false;
      }
      const fields = [
        ...this.getFieldObject(formRef, reportFields, "label1"),
        ...this.getFieldObject(formRef, reportFields, "label2"),
      ];
      body.reportFields = fields;
    }

    _.size(this.state.files)
      ? this.setState({ shortLoading: true })
      : this.setState({ loading: true });
    body.reportFromDistributor = this.state.shareReports;
    body.isDistributor = get(reportFields, "isDistributor");
    const data = {
      method: "POST",
      body,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${SEND_REPORTS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ shortLoading: false, loading: false });
        if (res.status) {
          switch (get(res.data, "status")) {
            case OVERALL_STATUS[0]:
              this.setState({ ackSuccessModal: true });
              break;
            case OVERALL_STATUS[1]:
              this.setState({
                ackReportIssueModal: true,
                reportIssueData: get(
                  res.data,
                  "processed_reports.distributor",
                  []
                ),
                failedReports: get(res.data, "failed", []),
              });
              break;
            case OVERALL_STATUS[2]:
              this.setState({
                ackTrackIssueModal: true,
                trackIssueData: get(res.data, "missing_isrc", []),
              });
              break;
            case OVERALL_STATUS[3]:
              this.setState({ ackErrorModal: true });
              break;
            case OVERALL_STATUS[4]:
              this.setState({ ackErrorModal: true });
              break;
            default:
              this.props.history.push(SOCIAL_NETWORKS);
              toast.success(get(res, "message"));
              break;
          }
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ shortLoading: false, loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  exitDemo = () => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${EXIT_DEMO}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.props.history.push(DEMO_DASHBOARD);
          return true;
        }
        toast.error(res.message);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };

  renderActions = () => {
    return (
      <div className={actionStyles.uploadButtons}>
        {!this.state.dummyArtist && (
          <FormControl
            component="fieldset"
            className={actionStyles.actionCheck}
          >
            <FormControlLabel
              onChange={(e) => {
                this.setState({
                  uploadConfirmation: e.target.checked,
                });
              }}
              value={this.state.uploadConfirmation}
              label={`I've uploaded 6 months of reports for each track I want included in my advance offer.`}
              control={
                <Checkbox
                  checked={this.state.uploadConfirmation}
                  className={actionStyles.checkbox}
                />
              }
              className={this.state.uploadConfirmation ? styles.checked : ""}
            />
          </FormControl>
        )}
        <div
          className={`${actionStyles.uploadReportsButtonContainer} ${
            this.state.dummyArtist ? actionStyles.dummyBtnContainer : ""
          }`}
        >
          {this.state.dummyArtist ? (
            <>
              <button
                data-testid="signupButton"
                className={actionStyles.demoBtn}
                onClick={() => {
                  this.setState({ isSignup: true });
                }}
              >
                I Want to Sign Up
                <SvgIcons icon={ARROW_FORWARD} />
              </button>
              <button
                className={actionStyles.secondary}
                onClick={this.exitDemo}
              >
                View Another Demo
              </button>
            </>
          ) : (
            <button
              className={actionStyles.primary}
              disabled={
                (this.state.shareReports === "no" &&
                  !_.size(this.state.files)) ||
                !this.state.uploadConfirmation
              }
              onClick={this.handleSendReports}
            >
              Submit for review
            </button>
          )}
        </div>
      </div>
    );
  };

  reportDistributorLogo = (distributor) =>
    distributor.logoUrl ? (
      <Image
        src={distributor.logoUrl}
        alt={distributor.name}
        imageType={IMAGE_TYPE.LOGO}
      />
    ) : (
      <h2>{distributor.name}</h2>
    );

  renderReportList = () => {
    const distributors = _.values(this.state.files);
    return (
      <div className={styles.distList}>
        {distributors.map((distributor) => (
          <div className={styles.reportContainer} key={distributor._id}>
            <div className={styles.reportDistributor}>
              {distributor.isOther ? (
                <h2>Other{distributor.name && `: ${distributor.name}`}</h2>
              ) : (
                this.reportDistributorLogo(distributor)
              )}
              <div className="d-flex">
                <i
                  className="fa fa-trash-o"
                  title="delete all"
                  onClick={() => {
                    this.deleteAllReport(distributor._id, distributor.isOther);
                  }}
                  data-testid="deleteAll"
                ></i>
              </div>
            </div>
            <div className={styles.reportList}>
              {get(distributor, "reports", []).map((file) => (
                <span className={styles.fileName} key={file.key}>
                  {file.originalName}
                  <span
                    className={styles.delete}
                    data-testid="deleteReport"
                    onClick={() => {
                      this.deleteReport(
                        file.key,
                        file.originalName,
                        distributor._id
                      );
                    }}
                  >
                    &times;
                  </span>
                </span>
              ))}
              <span
                className={styles.addMore}
                onClick={() =>
                  this.onDistributorChange(
                    { value: distributor._id, name: distributor.name },
                    distributor.isOther
                  )
                }
              >
                Add more reports
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  handleDeleteSentReport = (report) =>
    this.setState({
      deleteReport: {
        isOpen: true,
        name: report.originalName,
        key: report.key,
      },
    });

  handleTrackReasonUpdate = (values) => {
    this.setState({ loading: true });
    const payload = { missing_tracks: [...this.state.trackIssueData] };
    payload.missing_tracks = payload.missing_tracks.map((track, index) => ({
      ...track,
      reason: get(values, `tracks.${index}.reason`, ""),
    }));
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TRACK_REASON_UPDATE}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.props.history.push(SOCIAL_NETWORKS);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  handleToggleContact = () => {
    this.setState({ isContactOpen: !this.state.isContactOpen });
  };

  render() {
    return (
      <>
        <LoginHeader isDemo={this.state.dummyArtist} />
        <div
          className={`${styles.pageContainer} ${styles.dataReviewContainer} ${styles.sendReportsContainer}`}
        >
          <MenuBar
            isDemo={this.state.dummyArtist}
            offerStage={this.state.offerStage}
            {...this.props}
          />
          <div className={styles.mainContainer}>
            <HorizontalMenu
              isDemo={this.state.dummyArtist}
              offerStage={this.state.offerStage}
              {...this.props}
            />
            <div className={styles.scrollContainer}>
              <div className={styles.container}>
                <div className={styles.title}>
                  <h1>
                    Send Reports
                    <i
                      className={styles.infoIcon}
                      data-tip={SEND_REPORT_MSG}
                      data-for="send_reports"
                      data-place="top"
                    ></i>
                  </h1>
                  <ReactTooltip
                    delayHide={100}
                    class={styles.tooltip}
                    effect="solid"
                    place="top"
                    id="send_reports"
                  />
                </div>
                {get(this.context, "reportFields.isDistributor") === "Yes" && (
                  <>
                    <div className={`${styles.radioContainer}`}>
                      {this.renderRadioUI()}
                      {this.fieldsRender()}
                    </div>
                  </>
                )}
                <div className={styles.subtitle}>
                  {get(this.context, "reportFields.isDistributor") === "Yes" ? (
                    <p>
                      If there are any tracks you’d like included in your
                      advance offer that are on another distributor, or you
                      would like to manually upload your{" "}
                      {get(this.context, "displayName", "")} reports, please
                      upload those earnings reports below.
                      <i
                        className={`${styles.infoIcon} d-inline-flex`}
                        data-tip={`Any tracks included in your advance calculation that are not currently distributed by ${get(
                          this.context,
                          "displayName"
                        )} must be transferred before we can release your advance`}
                        data-for="send_reports_subtext"
                        data-place="top"
                      ></i>
                      <ReactTooltip
                        delayHide={100}
                        class={styles.tooltip}
                        effect="solid"
                        place="top"
                        id="send_reports_subtext"
                      />
                    </p>
                  ) : (
                    <p>
                      Upload the last six months of detailed reports covering
                      all tracks you want included in your advance offer.
                    </p>
                  )}
                </div>
                <div className={styles.sendReportsSubContainer}>
                  <p className={styles.subTitle}>
                    Pick your distributor(s) from the drop-down menu below to
                    upload reports:
                  </p>
                  <div className={styles.uploadReportListing}>
                    {this.renderReportList()}
                  </div>
                  <div className={styles.dropDownContainer}>
                    <Select
                      classNamePrefix="distributor"
                      styles={selectStyle}
                      onChange={this.onDistributorChange}
                      options={this.state.selectOptions}
                      placeholder={`${
                        _.size(this.state.files)
                          ? "Add Another Distributor"
                          : "Select a Distributor"
                      }`}
                      value={this.state.selectedDistributor}
                      components={{ IndicatorSeparator: null }}
                    />
                  </div>
                  {this.renderActions()}
                  {reportTableListRender(
                    this.state.listLoading,
                    this.state.sentReports,
                    this.handleDeleteSentReport
                  )}
                </div>
              </div>
            </div>
          </div>
          {this.uploadReportsModal()}
          {this.mobileScreenModal()}
          {this.deleteSentReportModal()}
          {this.state.dummyArtist && (
            <FunnelCollapseGuide
              guideData={this.state.guideData}
              isSignup={this.state.isSignup}
              {...this.props}
            />
          )}
          <AckSuccessModal
            isOpen={this.state.ackSuccessModal}
            history={this.props.history}
          />
          <AckReportIssueModal
            isOpen={this.state.ackReportIssueModal}
            reportData={this.state.reportIssueData}
            failedReports={this.state.failedReports}
            handleClose={this.handleCloseModalWithData}
            handleToggleContact={this.handleToggleContact}
            history={this.props.history}
          />
          <AckTrackIssueModal
            isOpen={this.state.ackTrackIssueModal}
            trackData={this.state.trackIssueData}
            handleClose={this.handleCloseModalWithData}
            handleSubmitApi={this.handleTrackReasonUpdate}
            handleToggleContact={this.handleToggleContact}
            history={this.props.history}
          />
          <AckErrorModal
            isOpen={this.state.ackErrorModal}
            history={this.props.history}
          />
          <ContactPopup
            isOpen={this.state.isContactOpen}
            onRequestClose={this.handleToggleContact}
          />
          {this.state.loading && <Loader light backgroundNone />}
          {this.state.shortLoading && (
            <ShortCallLoader
              loaderTitle="Processing your reports."
              text="This may take up to 1 minute"
            />
          )}
        </div>
      </>
    );
  }
}
SendReports.contextType = ThemeContext;

export default SendReports;

SendReports.contextTypes = {
  reportFields: PropTypes.object,
};
