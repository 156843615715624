import React, { Component } from "react";
import { get } from "lodash";
import * as Yup from "yup";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import LoginHeader from "../../../component/LoginHeader";
import styles from "./SocialNetworks.module.scss";
import { Form, Formik } from "formik";
import FormField from "../../../component/FormField/FormField";
import Loader from "../../../component/Loader";
import request from "../../../utils/request";
import {
  API_URL,
  USER_API,
  DASHBOARD,
  ARTIST_API,
  SOCIAL_NETWORK,
  INSTAGRAM_PLATFORM,
  FACEBOOK_PLATFORM,
  YOUTUBE_PLATFORM,
  WIKIPEDIA_PLATFORM,
  SEND_REPORTS,
  TOP_TRACK,
} from "../constants";
import { SOCIAL_NETWORK_PAGE } from "../infoIconMsg";
import { getErrorMessage, getSocialMediaPayload } from "../helper";
import MenuBar from "../../../component/MenuBar";
import { VERIFIED } from "../../../component/MenuBar/constants";
import ReactModal from "react-modal";
import HorizontalMenu from "../../../component/HorizontalMenu/HorizontalMenu";
import StorageService from "../../../utils/StorageService";
import fbIcon from "../../../img/SocialMediaIcons/fb.png";
import instaIcon from "../../../img/SocialMediaIcons/insta.png";
import wikiIcon from "../../../img/SocialMediaIcons/wiki.png";
import ytIcon from "../../../img/SocialMediaIcons/yt.png";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { setTitle } from "../../../component/ThemeManager/helper";

class SocialNetworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initialValues: {
        instagramUrl: "",
        youtubeUrl: "",
        facebookUrl: "",
        wikipediaUrl: "",
        textMsg: "",
      },
      ackModalOpen: false,
      offerStage: {},
      profileImg: "",
      spotifyURL: "",
    };
  }

  componentDidUpdate() {
    setTitle("Your Profile", this.context);
  }

  componentDidMount() {
    this.setState({ profileImg: StorageService.get("profileImg") });
    this.getAdvanceData();
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=false`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          this.setState({
            offerStage: res.data.offerStage,
            artistProfile: {
              url: get(res.data, "profilePicture[2].url"),
              name: res.data.name,
            },
          });
          this.getOffersFirst(get(res.data, "offerStage.verificationStatus"));
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  getOffersFirst = (verificationStatus) => {
    if (
      get(verificationStatus, "funding.value") === VERIFIED &&
      get(verificationStatus, "reports")
    ) {
      this.getSocialNetworkData();
      return true;
    }
    this.props.history.push({
      pathname: SEND_REPORTS,
      state: { redirectMsg: "First, please send your reports." },
    });
  };

  getSocialNetworkData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };

    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${SOCIAL_NETWORK}`;
    request(requestURL, data)
      .then((res) => {
        this.SetDataInForm(res);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  SetDataInForm = (res) => {
    this.setState({ loading: false });
    if (res.status) {
      this.setState((prevState) => ({
        initialValues: {
          ...prevState.initialValues,
        },
        spotifyURL: get(res, "data.spotifyUrl"),
      }));
      const data = get(res, "data.socialMediaAccounts") || [];
      data.forEach((d) => {
        if (d.platform === INSTAGRAM_PLATFORM) {
          this.setState((prevState) => ({
            initialValues: {
              ...prevState.initialValues,
              instagramUrl: get(d, "metadata.username"),
            },
          }));
        }
        if (d.platform === FACEBOOK_PLATFORM) {
          this.setState((prevState) => ({
            initialValues: {
              ...prevState.initialValues,
              facebookUrl: get(d, "metadata.username"),
            },
          }));
        }
        if (d.platform === YOUTUBE_PLATFORM) {
          this.setState((prevState) => ({
            initialValues: {
              ...prevState.initialValues,
              youtubeUrl: get(d, "metadata.username"),
            },
          }));
        }
        if (d.platform === WIKIPEDIA_PLATFORM) {
          this.setState((prevState) => ({
            initialValues: {
              ...prevState.initialValues,
              wikipediaUrl: get(d, "metadata.username"),
            },
          }));
        }
      });
      return true;
    }
    toast.error(get(res, "message"));
    this.props.history.push(DASHBOARD);
  };

  instagramRegex = /^(?:(?:[Hh][Tt][Tt][Pp]|[Hh][Tt][Tt][Pp][Ss]):\/\/)?(?:[wW]{3}[.])?(?:[Ii][Nn][Ss][Tt][Aa][Gg][Rr][Aa][Mm].[Cc][Oo][Mm])\/([A-Za-z0-9-_.]+)(\/?)$|^(?:@([A-Za-z0-9-_.]+))$/;
  youtubeRegex = /^(?:(?:[Hh][Tt][Tt][Pp]|[Hh][Tt][Tt][Pp][Ss]):\/\/)?(?:[wW]{3}[.])?(?:[Yy][Oo][Uu][Tt][Uu][Bb][Ee].[Cc][Oo][Mm])\/(?:channel|c|user)\/([A-Za-z0-9-_.]+)(\/?)$/;
  facebookRegex = /^(?:(?:[Hh][Tt][Tt][Pp]|[Hh][Tt][Tt][Pp][Ss]):\/\/)?(?:[wW]{3}[.])?(?:[Ff][Aa][Cc][Ee][Bb][Oo][Oo][Kk].[Cc][Oo][Mm])\/([A-Za-z0-9-_@#.]+)(\/?)$|^(?:@([A-Za-z0-9-_.]+))$/;
  wikipediaRegex = /^(?:(?:[Hh][Tt][Tt][Pp]|[Hh][Tt][Tt][Pp][Ss]):\/\/)?(?:[A-Za-z0-9_-]+[.])?(?:[Ww][Ii][Kk][Ii][Pp][Ee][Dd][Ii][Aa].[Oo][Rr][Gg])\/(?:wiki)\/([A-Za-z0-9-_.()]+)(\/?)$/;

  validationSchema = Yup.object().shape({
    instagramUrl: Yup.string()
      .label("Instagram channel Link")
      .matches(
        this.instagramRegex,
        "Please enter a valid Instagram URL or channel name"
      ),
    youtubeUrl: Yup.string()
      .label("YouTube channel Link")
      .matches(this.youtubeRegex, "Please enter a valid YouTube channel URL"),
    facebookUrl: Yup.string()
      .label("Facebook page Link")
      .matches(
        this.facebookRegex,
        "Please enter a valid Facebook URL or channel"
      ),
    wikipediaUrl: Yup.string()
      .label("Wikipedia page Link")
      .matches(this.wikipediaRegex, "Please enter a valid Wikipedia URL"),
    textMsg: Yup.string().test(
      "err-test",
      "Please enter at least one of the above URLs",
      function () {
        const {
          youtubeUrl,
          facebookUrl,
          instagramUrl,
          wikipediaUrl,
        } = this.parent;
        if (!youtubeUrl && !facebookUrl && !wikipediaUrl && !instagramUrl) {
          return false;
        }
        return true;
      }
    ),
  });

  saveDataSuccess = (res) => {
    this.setState({ loading: false });
    if (res.status) {
      const offerStage = this.state.offerStage;
      offerStage.verificationStatus.publicProfile = true;
      this.setState({ offerStage, ackModalOpen: true });
      toast.success(get(res, "message"));
      return true;
    }
    toast.error(get(res, "message"));
  };

  saveChangesCall = (values) => {
    const initialValues = this.state.initialValues;
    for (const key in initialValues) {
      initialValues[key] = values[key];
    }
    this.setState({ loading: true, initialValues });

    const socialMediaUrl = getSocialMediaPayload(
      values.instagramUrl,
      values.facebookUrl,
      values.youtubeUrl,
      values.wikipediaUrl
    );

    const data = {
      method: "POST",
      body: {
        socialMediaUrl,
      },
    };
    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${SOCIAL_NETWORK}`;
    request(requestURL, data)
      .then(this.saveDataSuccess)
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  goBack = () => {
    this.props.history.push(SEND_REPORTS);
  };

  handleCancel = () => {
    this.setState({ ackModalOpen: false });
  };

  ackModal = () => (
    <ReactModal
      isOpen={this.state.ackModalOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancel}
      className={styles.imageModal}
      overlayClassName={styles.modalOverlay}
    >
      <span className={styles.close} onClick={this.handleCancel}>
        &times;
      </span>
      <h3>Thank you!</h3>
      <div className={styles.hr} />
      <p>{get(this.context, "thankYouCTA.popupText")}</p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={get(this.context, "thankYouCTA.actionUrl")}
      >
        {get(this.context, "thankYouCTA.buttonText")}
      </a>
    </ReactModal>
  );

  getButtons = () =>
    get(this.state.offerStage, "verificationStatus.publicProfile") ? (
      <button
        type="submit"
        className={styles.primary}
        disabled={this.state.loading}
      >
        Save Changes
      </button>
    ) : (
      <>
        <button
          onClick={this.goBack}
          className={styles.secondary}
          disabled={this.state.loading}
        >
          Back
        </button>
        <button
          type="submit"
          className={styles.primary}
          disabled={this.state.loading}
        >
          Finish
        </button>
      </>
    );

  render() {
    return (
      <>
        <LoginHeader />
        <div className={styles.pageContainer}>
          <MenuBar offerStage={this.state.offerStage} {...this.props} />
          <div className={styles.mainContainer}>
            <HorizontalMenu
              offerStage={this.state.offerStage}
              {...this.props}
            />
            <div className={styles.scrollContainer}>
              <div className={styles.container}>
                <div className={styles.title}>
                  <h1>
                    Your Profile
                    <i
                      className={styles.infoIcon}
                      data-tip={SOCIAL_NETWORK_PAGE}
                      data-for="socialNetwork"
                      data-place="top"
                    ></i>
                  </h1>
                  <ReactTooltip
                    class={styles.tooltip}
                    effect="solid"
                    place="top"
                    id="socialNetwork"
                  />
                </div>

                {this.state.loading ? (
                  <Loader light backgroundNone />
                ) : (
                  <div className={styles.detailsContainer}>
                    <Formik
                      initialValues={this.state.initialValues}
                      validationSchema={this.validationSchema}
                      onSubmit={this.saveChangesCall}
                      enableReinitialize
                      data-testid="formRender"
                    >
                      <Form>
                        <div
                          className={`${styles.detailsBody} ${styles.borderBottom}`}
                        >
                          <div className={styles.formContainer}>
                            <div className={styles.spotifyProfile}>
                              <div className={styles.imgContainer}>
                                <Image
                                  src={this.state.profileImg}
                                  alt="artist"
                                  imageType={IMAGE_TYPE.PROFILE}
                                />
                              </div>
                              <div className={`form-group`}>
                                <label htmlFor="spotifyProfile">
                                  Spotify profile
                                </label>
                                <a
                                  href={this.state.spotifyURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {this.state.spotifyURL}
                                </a>
                              </div>
                            </div>
                            <div className={styles.socialUrlForm}>
                              <p className={styles.socialTitle}>
                                Social networks
                              </p>
                              <div className={`form-group`}>
                                <FormField
                                  name="instagramUrl"
                                  placeholder="http://instagram.com/yourinfo"
                                  icon={instaIcon}
                                  as="icon"
                                  label="Instagram channel"
                                />
                              </div>
                              <div className={`form-group`}>
                                <FormField
                                  name="facebookUrl"
                                  placeholder="http://facebook.com/yourinfo"
                                  icon={fbIcon}
                                  as="icon"
                                  label="Facebook page"
                                />
                              </div>
                              <div className={`form-group`}>
                                <FormField
                                  name="youtubeUrl"
                                  placeholder="http://www.youtube.com/c/yourinfo"
                                  icon={ytIcon}
                                  as="icon"
                                  label="YouTube channel"
                                />
                              </div>
                              <div className={`form-group`}>
                                <FormField
                                  name="wikipediaUrl"
                                  placeholder="https://en.wikipedia.org/wiki/yourinfo"
                                  icon={wikiIcon}
                                  as="icon"
                                  label="Wikipedia page"
                                />
                              </div>
                              <div className={`form-group pb-4`}>
                                <FormField
                                  name="textMsg"
                                  dataTestId="textMsg"
                                  as="textMsg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`${styles.formMainButtons}`}>
                          <div className={`${styles.buttonContainer} `}>
                            {this.getButtons()}
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.ackModal()}
      </>
    );
  }
}

SocialNetworks.contextType = ThemeContext;
export default SocialNetworks;
