import React, { Component } from "react";
import { toast } from "react-toastify";
import _, { get } from "lodash";
import ReactTooltip from "react-tooltip";
import { LineChart, Line } from "recharts";
import styles from "./YourAdvance.module.scss";
import generalColors from "../../../styles/global.scss";
import {
  getShortNumber,
  numberWithCommas,
  formatTrendData,
  FunnelCollapseGuide,
} from "./helper";
import {
  API_URL,
  USER_API,
  ARTIST_API,
  CHANGE_VISIT_STATUS,
  TOP_TRACK,
  DASHBOARD,
  TUNE_MY_DATA,
  FUNDING,
} from "../constants";
import { TUNE_YOUR_DATA } from "../infoIconMsg";
import classes from "../Auth/Auth.module.scss";
import { getErrorMessage } from "../helper";
import request from "../../../utils/request";
import LoginHeader from "../../../component/LoginHeader";
import MenuBar from "../../../component/MenuBar";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import {
  MUSIC_ICON,
  ARROW_FORWARD,
} from "../../../component/MaterialIcons/constants";
import Loader from "../../../component/Loader";
import { UNVERIFIED, VERIFIED } from "../../../component/MenuBar/constants";
import HorizontalMenu from "../../../component/HorizontalMenu";
import Image from "../../../component/Image";
import { IMAGE_TYPE } from "../../../component/Image/constants";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import {
  INIT_TREND_DATA,
  CUMULATIVE_MAX_PERCENT,
  MIN_TRACK_DISPLAY,
  OTHER_ISRC,
} from "./constants";
class ReviewArtistData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFirstTime: false,
      shortLoading: false,
      firstName: "",
      topTracks: [],
      streamingVolume: "",
      streamingIncome: "",
      soundExchangeIncome: "",
      streamingIncomeMax: "",
      streamingIncomeMin: "",
      artistProfile: {},
      offerStage: {},
      dummyArtist: get(props.history.location, "state.isDemo", false),
      guideData: {},
      cumulativeVisibleTrack: 0,
      othersTrackIndex: 0,
    };
  }

  componentDidUpdate() {
    setTitle("Data Review", this.context);
  }

  componentDidMount() {
    const { history } = this.props;
    if (get(history.location, "state.reviewData")) {
      const data = get(history.location, "state.reviewData");
      if (get(history.location, "state.redirectMsg")) {
        toast.error(get(history.location, "state.redirectMsg"));
      }
      if (!data.isFirstTime) {
        this.props.history.push({
          pathname: TUNE_MY_DATA,
          state: { tuneData: data },
        });
      }
      this.setState(
        {
          isFirstTime: data.isFirstTime,
          firstName: data.firstName,
          topTracks: data.top_tracks,
          streamingVolume: data.streaming_volume,
          streamingIncome: data.streaming_income,
          soundExchangeIncome: data.sound_exchange_income,
          streamingIncomeMax: data.streaming_income_high,
          streamingIncomeMin: data.streaming_income_low,
          artistProfile: {
            url: get(data, "profilePicture[2].url"),
            name: data.name,
          },
          offerStage: data.offerStage,
          othersTrackIndex: _.findIndex(data.top_tracks, ["isrc", OTHER_ISRC]),
        },
        this.calculateCumulative
      );
      const state = { ...history.location.state };
      delete state.reviewData;
      history.replace({ ...history.location, state });
      return null;
    }
    this.getAdvanceData();
  }

  getAdvanceData = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${TOP_TRACK}?isTopTrackDataNeeded=true`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res && res.status) {
          if (!res.data.isFirstTime) {
            this.props.history.push({
              pathname: TUNE_MY_DATA,
              state: { tuneData: res.data },
            });
          }
          this.setState(
            {
              isFirstTime: res.data.isFirstTime,
              firstName: res.data.firstName,
              topTracks: res.data.top_tracks,
              streamingVolume: res.data.streaming_volume,
              streamingIncome: res.data.streaming_income,
              soundExchangeIncome: res.data.sound_exchange_income,
              streamingIncomeMax: res.data.streaming_income_high,
              streamingIncomeMin: res.data.streaming_income_low,
              artistProfile: {
                url: get(res.data, "profilePicture[2].url"),
                name: res.data.name,
              },
              offerStage: res.data.offerStage,
              dummyArtist: get(res.data, "dummy_artist", false),
              guideData: {
                funnelGuideCustomization: get(
                  res.data,
                  "funnelGuideCustomization",
                  ""
                ),
                funnelGuideSendReports: get(
                  res.data,
                  "funnelGuideSendReports",
                  ""
                ),
                funnelGuideVerification1: get(
                  res.data,
                  "funnelGuideVerification1",
                  ""
                ),
                funnelGuideVerification2: get(
                  res.data,
                  "funnelGuideVerification2",
                  ""
                ),
              },
              othersTrackIndex: _.findIndex(res.data.top_tracks, [
                "isrc",
                OTHER_ISRC,
              ]),
            },
            this.calculateCumulative
          );
          return true;
        }
        toast.error(get(res, "message"));
        this.props.history.push(DASHBOARD);
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
        this.props.history.push(DASHBOARD);
      });
  };

  getLineChart = (data) => (
    <LineChart width={80} height={30} data={formatTrendData(data)}>
      <Line
        dataKey="value"
        stroke={generalColors.primaryColor}
        dot={false}
        strokeWidth={3}
        isAnimationActive={false}
      />
    </LineChart>
  );

  handleFirstTime = (tuneData = false) => {
    this.setState({ loading: true });
    const payload = {
      streamingData: tuneData ? UNVERIFIED : VERIFIED,
    };
    const data = {
      method: "PUT",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${CHANGE_VISIT_STATUS}`;
    request(requestUrl, data)
      .then((res) => {
        if (res.status) {
          this.setState({ loading: false });
          tuneData
            ? this.props.history.push(TUNE_MY_DATA)
            : this.props.history.push(FUNDING);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  getOtherTracksInfo = () => {
    let otherStreams = 0;
    const { topTracks, cumulativeVisibleTrack, othersTrackIndex } = this.state;
    const trendLine = INIT_TREND_DATA;
    topTracks.forEach((track, index) => {
      if (
        track.cumulativeSum > cumulativeVisibleTrack &&
        MIN_TRACK_DISPLAY < index + 1
      ) {
        otherStreams += track.streams_last_month;
        for (let i = 0; i < trendLine.length; i++) {
          trendLine[i] += track.trend_line_data[i];
        }
      }
    });
    return (
      (othersTrackIndex !== -1 || !!otherStreams) && (
        <tr className={styles.partitionTitle}>
          <td>
            <div className={styles.trackProfile}>
              <div className={styles.trackName}>
                <p>All other tracks</p>
                <p className={styles.mobLastMonth}>
                  Streams last month
                  {othersTrackIndex !== -1 ? (
                    <span>
                      {topTracks[othersTrackIndex] &&
                        getShortNumber(
                          topTracks[othersTrackIndex].streams_last_month
                        )}
                    </span>
                  ) : (
                    <span>{getShortNumber(otherStreams)}</span>
                  )}
                </p>
              </div>
            </div>
          </td>
          <td>
            {othersTrackIndex !== -1 ? (
              <div className={styles.chartData}>
                <p>
                  {getShortNumber(
                    get(topTracks, [othersTrackIndex, "streams_last_month"], 0)
                  )}
                </p>
                {this.getLineChart(
                  get(topTracks, [othersTrackIndex, "trend_line_data"], [])
                )}
              </div>
            ) : (
              <div className={styles.chartData}>
                <p>{getShortNumber(otherStreams)}</p>
                {this.getLineChart(trendLine)}
              </div>
            )}
          </td>
          <td></td>
        </tr>
      )
    );
  };

  calculateCumulative = () => {
    let cumulativeSum = 0;
    const data = this.state.topTracks.map((track) => {
      cumulativeSum =
        cumulativeSum +
        (track.streaming_info_share / 100) * track.slider_streams;
      track.cumulativeSum = cumulativeSum;
      return track;
    });

    this.setState({
      topTracks: data,
      cumulativeVisibleTrack:
        data[data.length - 1].cumulativeSum * CUMULATIVE_MAX_PERCENT,
    });
  };

  isCumulativeSumTracks = (track, index) =>
    track.cumulativeSum <= this.state.cumulativeVisibleTrack ||
    MIN_TRACK_DISPLAY >= index + 1;

  handleTuneData = () => {
    this.handleFirstTime(true);
  };

  render() {
    return (
      <>
        <LoginHeader isDemo={this.state.dummyArtist} />
        <div
          className={`${styles.pageContainer} ${styles.dataReviewContainer}`}
        >
          <MenuBar
            offerStage={this.state.offerStage}
            isDemo={this.state.dummyArtist}
            {...this.props}
          />
          <div className={styles.mainContainer}>
            <HorizontalMenu
              isDemo={this.state.dummyArtist}
              offerStage={this.state.offerStage}
              {...this.props}
            />
            <div className={styles.scrollContainer}>
              <div className={styles.container}>
                {!this.state.loading && (
                  <>
                    <div className={styles.title}>
                      <h1>
                        Does your data look right?
                        <i
                          className={styles.infoIcon}
                          data-tip={TUNE_YOUR_DATA}
                          data-for="your_advance"
                          data-place="top"
                        ></i>
                      </h1>
                      <ReactTooltip
                        delayHide={100}
                        className={`${styles.tooltip}`}
                        effect="solid"
                        place="top"
                        id="your_advance"
                      />
                    </div>
                    <div className={`${styles.welcomeModal}`}>
                      <div className={styles.dataReviewSubHeading}>
                        <p>
                          If your share of streaming income for any track below
                          is incorrect, click the button below to adjust your
                          shares.
                          <br />
                          <b>Note:</b> New Releases may take 3-6 weeks to appear
                        </p>
                        <div
                          className={`${styles.dataTuneButton} ${styles.reviewButtons}`}
                        >
                          <div className={styles.buttonContainer}>
                            {this.state.dummyArtist ? (
                              <button
                                data-testid="VerifiedButton"
                                className={styles.demoBtn}
                                onClick={() => {
                                  this.props.history.push({
                                    pathname: TUNE_MY_DATA,
                                    state: { isDemo: true },
                                  });
                                }}
                              >
                                Can I Fine Tune My Data?
                                <SvgIcons icon={ARROW_FORWARD} />
                              </button>
                            ) : (
                              <>
                                <button
                                  data-testid="VerifiedButton"
                                  className={styles.primary}
                                  onClick={() => this.handleFirstTime(false)}
                                >
                                  Yes, it's right
                                </button>
                                <button
                                  onClick={this.handleTuneData}
                                  className={styles.secondary}
                                >
                                  No, I need to adjust
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={styles.dataReviewSecondHeading}>
                        <table>
                          <tbody>
                            <tr className={styles.lastModalRow}>
                              <td>
                                <p>Streams last month:</p>
                                <p>
                                  {getShortNumber(this.state.streamingVolume)}
                                </p>
                              </td>
                              <td className={styles.dataDisplay}>
                                <p>Est. income last month:</p>
                                <p>
                                  <p className={classes.specialChar}>
                                    $
                                    {numberWithCommas(
                                      this.state.streamingIncomeMin
                                    )}{" "}
                                    - $
                                    {numberWithCommas(
                                      this.state.streamingIncomeMax
                                    )}
                                  </p>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className={`${styles.modalBody}`}>
                        <table className={styles.dataReviewSecondTable}>
                          <thead>
                            <tr>
                              <th>
                                <p>TOP TRACKS</p>
                              </th>
                              <th>
                                <p>12-wk trend & streams last month</p>
                              </th>
                              <th>
                                <p>Share of Streaming Income</p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.topTracks.map(
                              (track, index) =>
                                (this.isCumulativeSumTracks(track, index) ||
                                  this.state.othersTrackIndex !== -1) &&
                                this.state.othersTrackIndex !== index && (
                                  <tr key={track.isrc}>
                                    <td>
                                      <div className={styles.trackProfile}>
                                        {track.image_url ? (
                                          <Image
                                            src={track.image_url}
                                            alt="album"
                                            imageType={IMAGE_TYPE.PROFILE}
                                          />
                                        ) : (
                                          <SvgIcons icon={MUSIC_ICON} />
                                        )}
                                        <div className={styles.trackName}>
                                          <p>{track.track_name}</p>
                                          <p className={styles.mobLastMonth}>
                                            Streams last month
                                            <span>
                                              {getShortNumber(
                                                track.streams_last_month
                                              )}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className={styles.chartData}>
                                        <p>
                                          {getShortNumber(
                                            track.streams_last_month
                                          )}
                                        </p>
                                        {this.getLineChart(
                                          track.trend_line_data
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <p>
                                        <span className={styles.mobShareIncome}>
                                          Share of streaming income
                                        </span>
                                        <span className={styles.mobShare}>
                                          {track.streaming_info_share}%
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                )
                            )}
                            {this.getOtherTracksInfo()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.state.dummyArtist && (
                      <FunnelCollapseGuide
                        guideData={this.state.guideData}
                        {...this.props}
                      />
                    )}
                  </>
                )}
                {this.state.loading && <Loader backgroundNone />}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ReviewArtistData.contextType = ThemeContext;
export default ReviewArtistData;
