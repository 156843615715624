import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AuthRoute from "./AuthRoute";
import PrivateRoute from "./PrivateRoute";
import Login from "./pages/Auth/Login";
import GetFundedAuth from "./pages/Auth/GetFundedAuth";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ChangePassword from "./pages/Auth/ChangePassword";
import "react-toastify/dist/ReactToastify.css";
import "../styles/global.scss";
import {
  LOGIN,
  HOME,
  GETFUNDED,
  GETFUNDED_AUTH,
  ERROR_PAGE,
  FORGOT_PASS,
  CHANGE_PASS,
  MY_ACCOUNT,
  DATA_REVIEW,
  SEND_REPORTS,
  FUNDING,
  VERIFY,
  LINK_ANOTHER_ARTIST,
  DASHBOARD,
  CONTACT_BEATBREAD_GET_FUNDED,
  SUCCESS,
  WHY_BEATBREAD,
  ADMIN_DASHBOARD,
  ERROR_SERVER_DOWN,
  SOCIAL_NETWORKS,
  ERROR_FORBIDDEN,
  TUNE_MY_DATA,
  ACK_HIGH,
  ACK_LOW,
  ACK_ERROR,
  ACK_NEW,
  ACK_REDIRECT,
  ACK_IN_RANGE_1,
  ACK_IN_RANGE_2,
  ACK_IN_RANGE_3,
  ADMIN_BLACKLIST,
  ADMIN_WHITELABEL,
  ADMIN_WHITELABEL_NEW_ACCOUNT,
  ADMIN_WHITELABEL_EDIT_ACCOUNT,
  ADMIN_LOGIN,
  ADMIN_MAINTENANCE_MODE,
  MAINTENANCE_PAGE,
  DISTRIBUTORS,
  DISTRIBUTORS_EDIT,
  DISTRIBUTORS_CREATE,
  EMAIL_PREFERENCES,
  ADJUST_TERMS,
  OFFER_SUMMARY,
  ARTIST_VERIFY,
  ACCEPT_OFFER,
  DEMO_ARTIST,
  DEMO_ARTIST_CREATE,
  DEMO_ARTIST_EDIT,
  DEMO_DASHBOARD,
} from "./pages/constants";
import MyAccount from "./pages/MyAccount";
import Dashboard from "./pages/Dashboard";
import YourAdvance from "./pages/YourAdvance";
import ReviewArtistData from "./pages/YourAdvance/ReviewArtistData";
import VerifyUser from "./pages/Auth/VerifyUser/VerifyUser";
import LinkAnotherArtist from "./pages/Auth/LinkAnotherArtist";
import ContactForFund from "./pages/YourAdvance/ContactForFund";
import Success from "./pages/YourAdvance/Success";
import { ErrorPage, PrivacyPolicy, WhyBeatBread } from "./pages/Misc";
import {
  whiteLabelListing,
  AdminDashboard,
  whiteLabelAccount,
} from "./pages/AdminDashboard";
import ErrorServerDown from "./pages/Misc/ErrorPage/ErrorServerDown";
import SocialNetworks from "./pages/SocialNetworks";
import ErrorForbidden from "./pages/Misc/ErrorPage/ErrorForbidden";
import ManageBlacklist from "./pages/ManageBlacklist/ManageBlacklist";
import GetFunded from "./pages/Auth/Getfunded";
import TuneMyData from "./pages/YourAdvance/TuneMyData";
import SendReports from "./pages/SendReports";
import AcknowledgementScreen from "./pages/Auth/AcknowledgementScreen";
import AckRedirect from "./pages/Auth/AcknowledgementScreen/AckRedirect";
import ThemeManager from "../component/ThemeManager/ThemeManager";
import MaintenanceMode from "./pages/MaintenanceMode";
import SiteMaintenancePage from "./pages/Misc/ErrorPage/SiteMaintenancePage";
import Distributors from "./pages/Distributors";
import DistributorForm from "./pages/Distributors/DistributorForm";
import EmailPreferences from "./pages/EmailPreferences";
import OfferSummary from "./pages/OfferSummary";
import ArtistVerify from "./pages/OfferSummary/ArtistVerify";
import AcceptOffer from "./pages/AcceptOffer/AcceptOffer";
import DemoArtists from "./pages/DemoArtists/DemoArtists";
import DemoArtistForm from "./pages/DemoArtists/DemoArtistForm";
import DemoDashboard from "./pages/DemoArtists/DemoDashboard";
import { hubSpotTracking } from "./pages/helper";

export class MainRoutes extends React.Component {
  componentDidMount() {
    this.unListen = this.props.history.listen(hubSpotTracking);
  }
  componentWillUnmount() {
    this.unListen();
  }
  render() {
    return (
      <>
        <ToastContainer
          className="Toast"
          type="default"
          position={toast.POSITION.TOP_RIGHT}
          autoClose={4000}
          hideProgressBar
          pauseOnHover
        />
        <ThemeManager>
          <Switch>
            <Route path="/privacy" component={PrivacyPolicy} exact />
            <Route path={ERROR_PAGE} component={ErrorPage} exact />
            <Route path={ERROR_FORBIDDEN} component={ErrorForbidden} exact />
            <Route path={ERROR_SERVER_DOWN} component={ErrorServerDown} exact />
            <Route
              path={MAINTENANCE_PAGE}
              component={SiteMaintenancePage}
              exact
            />
            <PrivateRoute exact path={`${HOME}`} component={Dashboard} />
            <Route exact path={`${LOGIN}/:token`} component={Login} />
            <AuthRoute exact path={`${LOGIN}`} component={Login} />
            <AuthRoute exact path={`${ADMIN_LOGIN}`} component={Login} />
            <AuthRoute exact path={`${GETFUNDED}`} component={GetFunded} />
            <PrivateRoute
              exact
              path={`${LINK_ANOTHER_ARTIST}`}
              component={LinkAnotherArtist}
            />
            <AuthRoute
              exact
              path={`${GETFUNDED_AUTH}`}
              component={GetFundedAuth}
            />

            <Route exact path={`${FORGOT_PASS}`} component={ForgotPassword} />
            <Route
              exact
              path={`${CHANGE_PASS}/:key`}
              component={ChangePassword}
            />
            <AuthRoute exact path={`${VERIFY}/:key`} component={VerifyUser} />

            <PrivateRoute path={MY_ACCOUNT} component={MyAccount} />
            <PrivateRoute path={DASHBOARD} component={Dashboard} />
            <PrivateRoute path={ADMIN_DASHBOARD} component={AdminDashboard} />
            <PrivateRoute path={ADMIN_BLACKLIST} component={ManageBlacklist} />
            <PrivateRoute
              exact
              path={ADMIN_WHITELABEL}
              component={whiteLabelListing}
            />
            <PrivateRoute
              exact
              path={ADMIN_WHITELABEL_NEW_ACCOUNT}
              component={whiteLabelAccount}
            />
            <PrivateRoute
              exact
              path={ADMIN_WHITELABEL_EDIT_ACCOUNT}
              component={whiteLabelAccount}
            />
            <PrivateRoute
              exact
              path={ADMIN_MAINTENANCE_MODE}
              component={MaintenanceMode}
            />
            <PrivateRoute exact path={DISTRIBUTORS} component={Distributors} />
            <PrivateRoute
              exact
              path={DISTRIBUTORS_CREATE}
              component={DistributorForm}
            />
            <PrivateRoute
              exact
              path={DISTRIBUTORS_EDIT}
              component={DistributorForm}
            />
            <PrivateRoute exact path={DEMO_ARTIST} component={DemoArtists} />
            <PrivateRoute
              exact
              path={DEMO_ARTIST_CREATE}
              component={DemoArtistForm}
            />
            <PrivateRoute
              exact
              path={DEMO_ARTIST_EDIT}
              component={DemoArtistForm}
            />
            <Route
              exact
              path={`${DEMO_DASHBOARD}/:id?`}
              component={DemoDashboard}
            />
            <PrivateRoute path={FUNDING} component={YourAdvance} />
            <PrivateRoute path={TUNE_MY_DATA} component={TuneMyData} />
            <PrivateRoute path={DATA_REVIEW} component={ReviewArtistData} />
            <PrivateRoute path={SEND_REPORTS} component={SendReports} />
            <PrivateRoute
              exact
              path={SOCIAL_NETWORKS}
              component={SocialNetworks}
            />
            <PrivateRoute path={OFFER_SUMMARY} component={OfferSummary} />
            <PrivateRoute path={ADJUST_TERMS} component={YourAdvance} />
            <PrivateRoute path={ARTIST_VERIFY} component={ArtistVerify} />
            <PrivateRoute path={ACCEPT_OFFER} component={AcceptOffer} />
            <PrivateRoute
              path={CONTACT_BEATBREAD_GET_FUNDED}
              component={ContactForFund}
            />
            <Route path={ACK_IN_RANGE_1} component={AcknowledgementScreen} />
            <Route path={ACK_IN_RANGE_2} component={AcknowledgementScreen} />
            <Route path={ACK_IN_RANGE_3} component={AcknowledgementScreen} />
            <Route path={ACK_HIGH} component={AcknowledgementScreen} />
            <Route path={ACK_LOW} component={AcknowledgementScreen} />
            <Route path={ACK_ERROR} component={AcknowledgementScreen} />
            <Route path={ACK_NEW} component={AcknowledgementScreen} />
            <Route path={ACK_REDIRECT} component={AckRedirect} />
            <Route path={EMAIL_PREFERENCES} component={EmailPreferences} />
            <PrivateRoute path={SUCCESS} component={Success} />
            <PrivateRoute path={WHY_BEATBREAD} component={WhyBeatBread} />
            <Redirect to={ERROR_PAGE} />
          </Switch>
        </ThemeManager>
      </>
    );
  }
}

export default withRouter(MainRoutes);
