import React, { Component } from "react";
import PropTypes from "prop-types";
import _, { get } from "lodash";
import htmlParser from "html-react-parser";
import Dropzone from "./Dropzone";
import styles from "./UploadFiles.module.scss";
import popupStyles from "../../routes/pages/SendReports/SendReports.module.scss";
import Progress from "./Progress";
import {
  USER_API,
  API_URL,
  REPORT,
  ARTIST_API,
  OTHER_REPORTS_API,
} from "../../routes/pages/constants";
import AuthTokenService from "../../utils/AuthTokenService";
import Axios from "axios";
import { userExists } from "../../utils/Helper";
import { ERR, UPLOADED, PENDING, VALID_FILE_TYPE } from "./constants";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import { IMAGE_TYPE } from "../Image/constants";
import Image from "../Image";
import request from "../../utils/request";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../routes/pages/helper";
import FormikForm from "../Form/FormikForm";
import { Form } from "formik";
import FormField from "../FormField/FormField";
import ContactPopup from "../ContactPopup";

class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successUploadCount: 0,
      isOpen: false,
      isContactOpen: false,
    };
    this.otherNameRef = React.createRef();
    this.uploadRef = React.createRef();
  }

  componentDidMount() {
    this.updateStateData();
  }

  componentDidUpdate() {
    const current = this.uploadRef.current;
    current.scrollTop = current.clientHeight - current.scrollHeight;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.distributorInfo.isOther &&
      !nextProps.distributorInfo.isEditMode
    ) {
      return true;
    }
    if (
      this.state.files.length > nextProps.files.length &&
      this.props.files.length === nextProps.files.length
    ) {
      const uploadProgress = {};
      const fileList = nextProps.files.map((file) => {
        uploadProgress[file.originalName] = {
          state: UPLOADED,
          deleteKey: file.key,
          percentage: 100,
        };
        return { name: file.originalName, size: 0 };
      });
      this.setState({
        files: fileList,
        successUploadCount: fileList.length,
        uploadProgress: { ...uploadProgress },
      });
    }
  }

  updateStateData = () => {
    const uploadProgress = {};
    const fileList = this.props.files.map((file) => {
      uploadProgress[file.originalName] = {
        state: UPLOADED,
        deleteKey: file.key,
        percentage: 100,
      };
      return { name: file.originalName, size: 0 };
    });
    this.setState({
      files: fileList,
      successUploadCount: fileList.length,
      uploadProgress: { ...uploadProgress },
    });
  };

  onFilesAdded = (files) => {
    const fileList = files.filter(
      (file) => !this.state.uploadProgress[file.name]
    );
    this.setState(
      (prevState) => ({
        files: prevState.files.concat(fileList),
      }),
      this.uploadFiles
    );
  };

  cancelRequest = (file) => {
    if (!this.state.uploading) {
      this.props.deleteReport(
        this.state.uploadProgress[file.name].deleteKey,
        file.name,
        this.props.distributorInfo._id
      );
      const { uploadProgress, files } = this.state;
      delete uploadProgress[file.name];
      _.remove(files, (f) => f.name === file.name);
      this.setState({
        uploadProgress,
        successUploadCount: this.state.successUploadCount - 1,
      });
    } else {
      this.state.uploadProgress[file.name].cancel();
      const copy = { ...this.state.uploadProgress };
      copy[file.name] = {
        state: ERR,
        percentage: 100,
        msg: "Cancelled",
      };
      this.setState({ uploadProgress: copy });
    }
  };

  renderProgress = (file) => {
    const uploadProgress = this.state.uploadProgress[file.name];
    return (
      <div className={styles.progressWrapper}>
        <>
          <Progress
            progress={uploadProgress.percentage || 0}
            status={uploadProgress.state}
          />
        </>
      </div>
    );
  };

  sendUploadRequest = (file) => {
    const formData = new FormData();
    formData.append("report", file, file.name);

    const requestURL = `${API_URL}${USER_API}${ARTIST_API}${REPORT}`;

    if (userExists()) {
      Axios.defaults.headers.common.Authorization = `${AuthTokenService.get()}`;
    } else {
      delete Axios.defaults.headers.common.Authorization;
    }

    const source = Axios.CancelToken.source();

    return Axios.put(requestURL, formData, {
      onUploadProgress: (ProgressEvent) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = {
          state: PENDING,
          cancel: source.cancel,
          percentage: Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100
          ),
        };
        this.setState({ uploadProgress: copy });
      },
      headers: { "Content-Type": "multipart/form-data" },
      cancelToken: source.token,
      params: {
        reportFromDistributor: this.props.radioSelection,
        distributorId: this.props.distributorInfo._id,
        isOther: get(this.props.distributorInfo, "isOther", false),
      },
    });
  };

  otherReportsCall = () => {
    this.props.setLoading(true);
    const key = [];
    for (const objKey in this.state.uploadProgress) {
      this.state.uploadProgress[objKey].deleteKey &&
        key.push(this.state.uploadProgress[objKey].deleteKey);
    }
    const payload = {
      key,
      otherName: get(this.otherNameRef.current, "values.otherName", ""),
    };
    const data = {
      method: "PUT",
      body: payload,
    };

    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${OTHER_REPORTS_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.props.setLoading(false);
        if (res.status) {
          !this.props.distributorInfo.isEditMode &&
            this.props.updateOthersList(key, payload.otherName);
          this.props.handleClose();
          toast.success(res.message);
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.props.setLoading(false);
        toast.error(getErrorMessage(err));
        return false;
      });
  };

  uploadFiles = () => {
    this.setState({
      uploading: true,
    });
    const promises = [];
    this.state.files.forEach((file) => {
      const fileExtension = file.name.split(".");
      if (file.size > 20000000) {
        this.state.uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: "File size exceeded (20MB)",
        };
      } else if (
        fileExtension.length < 2 ||
        VALID_FILE_TYPE.indexOf(fileExtension.pop().toLowerCase()) === -1
      ) {
        this.state.uploadProgress[file.name] = {
          state: ERR,
          percentage: 100,
          msg: "File type not accepted",
        };
      }
      if (!this.state.uploadProgress[file.name] && file.size <= 20000000) {
        promises.push(this.sendUploadRequest(file));
      }
    });

    Promise.allSettled(promises)
      .then((responses) => {
        responses.forEach((res) => {
          if (res.status === "fulfilled") {
            this.state.uploadProgress[
              get(res, "value.config.data").get("report").name
            ].deleteKey = get(res, "value.data.data._id");
            this.state.uploadProgress[
              get(res, "value.config.data").get("report").name
            ].state = UPLOADED;
            const file = {
              key: get(res, "value.data.data._id"),
              originalName: get(res, "value.config.data").get("report").name,
              sent: false,
            };
            this.props.addReport(this.props.distributorInfo, file);
            this.setState({
              successUploadCount: this.state.successUploadCount + 1,
            });
          } else {
            if (get(res, "reason.config.data")) {
              this.state.uploadProgress[
                get(res, "reason.config.data").get("report").name
              ].state = ERR;
            }
          }
        });
        this.setState({ uploading: false });
      })
      .catch();
  };

  fileText = () => (this.state.successUploadCount === 1 ? "file" : "files");

  unsentFileListing = () => (
    <div className={styles.upload}>
      <div className={styles.content}>
        {!this.state.files.length ? (
          <></>
        ) : (
          <>
            <div className={styles.files}>
              {this.state.files.map((file) => {
                const uploadProgress = this.state.uploadProgress[file.name];
                return (
                  uploadProgress && (
                    <div key={file.name} className={styles.row}>
                      <span className={styles.fileName}>
                        {file.name}
                        <span
                          className={`${styles.checkIcon} ${
                            uploadProgress.state === ERR ||
                            (uploadProgress.percentage === 100 &&
                              this.state.uploading)
                              ? "d-none"
                              : ""
                          }`}
                          onClick={() => {
                            this.cancelRequest(file);
                          }}
                        >
                          &times;
                        </span>
                        {get(this.state.uploadProgress[file.name], "msg") && (
                          <span className={styles.msgError}>
                            {get(this.state.uploadProgress[file.name], "msg")}
                            <i
                              className={`${styles.helpBtn} fa fa-question-circle`}
                              onClick={() => {
                                this.setState({ isContactOpen: true });
                              }}
                              title={`Help`}
                            ></i>
                          </span>
                        )}
                      </span>
                      {this.renderProgress(file)}
                    </div>
                  )
                );
              })}
              {!!this.state.successUploadCount && (
                <p className={styles.fileCount}>
                  {this.state.successUploadCount} {this.fileText()} uploaded
                  successfully!
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );

  infoDistributorText = () =>
    get(this.props, "distributorInfo.name") && (
      <>
        {" "}
        from{" "}
        <span className="textBold">
          {get(this.props, "distributorInfo.name")}
        </span>
      </>
    );

  renderDistributorLogo = () =>
    get(this.props, "distributorInfo.logoUrl", "") ? (
      <Image
        src={get(this.props, "distributorInfo.logoUrl", "")}
        alt="logo"
        imageType={IMAGE_TYPE.LOGO}
        className={popupStyles.logo}
      />
    ) : (
      get(this.props, "distributorInfo.name", "")
    );

  handleCloseContact = () => {
    this.setState({ isContactOpen: false });
  };

  render() {
    const { distributorInfo } = this.props;
    return (
      <>
        <div className={popupStyles.uploadModalContent} ref={this.uploadRef}>
          <div className={popupStyles.distributorInfo}>
            <div className={popupStyles.logoDetails}>
              <h2>
                {get(distributorInfo, "isOther") ? (
                  <>
                    Other{distributorInfo.name && `: ${distributorInfo.name}`}
                  </>
                ) : (
                  this.renderDistributorLogo()
                )}
              </h2>
              <FormikForm
                initialValues={{ otherName: distributorInfo.name }}
                innerRef={this.otherNameRef}
                onSubmit={() => {}}
              >
                <Form className={popupStyles.otherForm}>
                  {distributorInfo.isOther && !distributorInfo.isEditMode && (
                    <div className="form-group d-flex align-items-center">
                      <FormField
                        name="otherName"
                        placeholder={`Distributor Name (Optional)`}
                      />
                    </div>
                  )}
                </Form>
              </FormikForm>
            </div>
            <h4>Acceptable File Formats:</h4>
            <p className={popupStyles.acceptInfo}>
              Microsoft Excel (.xls, .xlsx, .csv, .tsv)
              <br />
              Text File (.txt)
              <br />
              Numbers (.numbers)
            </p>
            <div className={popupStyles.highlightBox}>
              <p>
                FILES TYPES WE DO NOT ACCEPT: jpg, png, screenshots and other
                image formats.
              </p>
            </div>
            {get(distributorInfo, "isOther") ? (
              <div className={popupStyles.otherContent}>
                <p>
                  We are looking for an excel-type file (csv, tsv, xls, etc)
                  with detailed data that shows at least:
                </p>
                <ul>
                  <li>Track Title &amp; Artist name</li>
                  <li>Track ISRC</li>
                  <li>Number of streams</li>
                  <li>Date those streams occurred</li>
                  <li>Revenue generated by each of those streams</li>
                  <li>Platform where those streams occurred</li>
                  <li>Country where those streams occurred</li>
                </ul>
                <p>
                  If you are having trouble finding the right report from your
                  distributor, please reach out to us and we’ll help you
                  navigate!
                </p>
              </div>
            ) : (
              <>
                <h4>How to get your reports:</h4>
                <div className={popupStyles.instructionsContent}>
                  {htmlParser(get(distributorInfo, "instructionsHTML", ""))}
                </div>
                {!this.props.dummyArtist && (
                  <>
                    <h4>Need help getting the right reports?</h4>
                    <a
                      className={popupStyles.btnLink}
                      href={get(distributorInfo, "instructionsUrl")}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download PDF instructions
                    </a>
                  </>
                )}
              </>
            )}
          </div>
          <div className={popupStyles.uploadContainer}>
            <div className={popupStyles.uploadBox}>
              <Dropzone
                disabled={this.state.uploading || this.props.dummyArtist}
                onFilesAdded={this.onFilesAdded}
                successUploadCount={this.state.successUploadCount}
                dummyArtist={this.props.dummyArtist}
              />
            </div>
            {this.unsentFileListing()}
            {this.state.successUploadCount ? (
              <div className={popupStyles.infoText}>
                <i>i</i>
                <p>
                  Have you uploaded 6 months of detailed earnings reports
                  {this.infoDistributorText()}?
                </p>
              </div>
            ) : null}
            <button
              onClick={
                distributorInfo.isOther
                  ? this.otherReportsCall
                  : this.props.handleClose
              }
              className={popupStyles.uploadBtn}
              disabled={!this.state.successUploadCount || this.state.uploading}
            >
              Confirm
            </button>
          </div>
          <span
            className={popupStyles.closeBtn}
            onClick={this.props.handleClose}
          >
            &times;
          </span>
        </div>
        <ContactPopup
          isOpen={this.state.isContactOpen}
          onRequestClose={this.handleCloseContact}
        />
      </>
    );
  }
}
UploadFiles.contextType = ThemeContext;
export default UploadFiles;

UploadFiles.contextTypes = {
  reportFields: PropTypes.object,
};
