import { GENERAL_FIELD_VALIDATION_MESSAGE } from "../AdminDashboard/WhiteLabel/constants";

export const DISTRIBUTOR_TABLE_COLUMN = [
  {
    name: "",
    key: "emptySpace",
  },
  {
    name: "",
    key: "appLogo",
  },
  {
    name: "Distributor Name",
    key: "distributorName",
  },
  {
    name: "Action",
    key: "action",
  },
];

export const FIELD_NAMES = {
  DISTRIBUTOR_NAME: {
    NAME: "distributorName",
    LABEL: "Distributor name",
    PLACEHOLDER: "Enter Distributor Name",
  },

  INSTRUCTIONS_HTML: {
    NAME: "instructionsHTML",
    LABEL: "Add Instruction Steps",
    PLACEHOLDER: "Create instructions layout here...",
    EXTRA_TEXT: "",
    LABEL_TO_DISPLAY: "Add instructions to download distributor reports",
  },
  INSTRUCTIONS_URL: {
    NAME: "instructionsUrl",
    LABEL: "Upload",
    PLACEHOLDER: "Upload",
    EXTRA_TEXT: "Upload PDF instructions",
  },
  ADMIN_PASSWORD: {
    NAME: "adminPassword",
    LABEL: "Password",
    PLACEHOLDER: "Password",
  },
};

export const FIELD_INITIAL_VALUES = {
  [FIELD_NAMES.DISTRIBUTOR_NAME.NAME]: "",
  [FIELD_NAMES.INSTRUCTIONS_HTML.NAME]: "",
  [FIELD_NAMES.INSTRUCTIONS_URL.NAME]: "",
  [FIELD_NAMES.ADMIN_PASSWORD.NAME]: "",
};

export const FIELD_VALIDATION_MESSAGES = {
  [FIELD_NAMES.DISTRIBUTOR_NAME
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.DISTRIBUTOR_NAME.LABEL}`,
  [FIELD_NAMES.INSTRUCTIONS_HTML
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.DISTRIBUTOR_NAME.LABEL}`,
  [FIELD_NAMES.INSTRUCTIONS_URL
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.DISTRIBUTOR_NAME.LABEL}`,
  [FIELD_NAMES.ADMIN_PASSWORD
    .NAME]: `${GENERAL_FIELD_VALIDATION_MESSAGE}${FIELD_NAMES.DISTRIBUTOR_NAME.LABEL}`,
};

export const DEFAULT_INSTRUCTION_LIST_HTML = `<style> .custom-howTo-list{ counter-reset: list-counter; list-style: none; padding: 0 } .custom-howTo-list li{ position: relative; margin: 1em 0; padding-inline-start: 3em; } .custom-howTo-list li:before{ content: counter(list-counter); counter-increment: list-counter; width: 1em; height: 1em; padding: .5em; margin-right: 1em; border-radius: 50%; background: #f9a749; color: #fff; font-family: arial; font-weight: bold; text-align: center; display: inline-flex; align-items: center; justify-content: center; margin-left: -3em } .custom-howTo-list li:after{ content: " "; display: block; height: 100%; width: 1px; background: #f9a749; left: 1em; top: 1.5em; position: absolute; transform: translateX(-.5px); } .custom-howTo-list li:last-child:after{ display:none; }</style><ul class="custom-howTo-list"><li>Instruction list here</li></ul>`;
