const placesSelectStyle = {
  menu: (props) => ({
    ...props,
    color: "#000000",
    borderRadius: 0,
    marginTop: 1,
  }),
  valueContainer: (props) => ({
    ...props,
    padding: 0,
    lineHeight: "18px",
  }),
  control: (props) => ({
    ...props,
    boxShadow: "none",
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "1px solid #e6e6e6",
    borderRadius: 0,
    padding: "13px 18px",
    "&:hover": {
      outline: "transparent",
    },
  }),
  container: (props) => ({
    ...props,
    paddingTop: 16,
  }),
};

export default placesSelectStyle;
