import request from "../../utils/request";
import { USER_API, ME, API_URL } from "../../routes/pages/constants";
import StorageService from "../../utils/StorageService";

export function fetchAccountImg() {
  const reqData = {
    method: "GET",
  };
  const requestURL = `${API_URL}${USER_API}${ME}`;
  request(requestURL, reqData)
    .then((res) => {
      if (res.status) {
        if (res.data.activeArtist) {
          const imgUrlLength = res.data.activeArtist.profilePicture.length - 1;
          StorageService.set(
            "profileImg",
            res.data.activeArtist.profilePicture[imgUrlLength].url
          );
          StorageService.set("activeArtist", res.data.activeArtist.name);
        }
        if (res.data.role === "admin") {
          StorageService.set("role", res.data.role);
        }
        this.setState({ imgUrl: res.data.profilePicture, role: res.data.role });
        return [];
      }
    })
    .catch((err) => {});
}
