import React, { Component } from "react";
import { get } from "lodash";
import { Link } from "react-router-dom";
import { Form } from "formik";
import * as Yup from "yup";
import base from "base-64";
import StorageService from "../../../../utils/StorageService";
import Header from "../../../../component/Header";
import FormikForm from "../../../../component/Form/FormikForm";
import FormField from "../../../../component/FormField/FormField";
import styles from "../Auth.module.scss";
import {
  API_URL,
  AUTH,
  SIGNIN_API,
  FORGOT_PASS,
  LOGIN,
  DASHBOARD,
} from "../../constants";
import AuthTokenService from "../../../../utils/AuthTokenService";
import AuthCookies from "../../../../utils/setCookies";
import Loader from "../../../../component/Loader";
import { toast } from "react-toastify";
import {
  PASS_UPPER_LOWER_REGEX,
  PASS_NUM_SPECIAL_REGEX,
  EMAIL_REGEX,
} from "../constants";
import request from "../../../../utils/request";
import { getErrorMessage, forbiddenCheck } from "../../helper";
import {
  getSubDomain,
  extractHostname,
  setTitle,
} from "../../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../../component/ThemeManager/ThemeManager";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      logout: false,
    };
  }

  componentDidMount() {
    forbiddenCheck();
    AuthCookies.delete("artistDetails");
    AuthCookies.delete("userId");
    get(this.props, "location.state.logout") && this.setState({ logout: true });
    if (get(this.props, "match.params.token")) {
      const query = base.decode(get(this.props, "match.params.token"));
      const params = new URLSearchParams(query);
      if (
        params.has("timestamp") &&
        Math.floor((Date.now() - parseInt(params.get("timestamp"))) / 1000) <=
          60
      ) {
        StorageService.delete("jwtToken");
        AuthTokenService.storeToken(params.get("token"));
        StorageService.set(
          "isAdminInMaintenance",
          params.get("isAdmin") || false,
          { hash: false }
        );
        this.props.history.replace(DASHBOARD);
      } else {
        this.props.history.replace(LOGIN);
      }
    }
  }

  componentDidUpdate() {
    setTitle("Login", this.context);
  }

  passwordValidate = Yup.string()
    .required()
    .min(8, "Please enter valid password between 8 to 20 characters")
    .max(20, "Please enter valid password between 8 to 20 characters")
    .matches(
      PASS_UPPER_LOWER_REGEX,
      "Password should have at least 1 uppercase letter & 1 lowercase letter."
    )
    .matches(
      PASS_NUM_SPECIAL_REGEX,
      "Password should have at least 1 number & 1 special character."
    )
    .label("Password");

  validationSchemaEmail = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
    password: this.passwordValidate,
  });

  loginUser = (data) => {
    if (getSubDomain() === extractHostname(data.landingPageUrl)) {
      AuthTokenService.storeToken(data.accessToken);
      this.props.history.push(DASHBOARD);
      return true;
    }
    const redirectToken = base.encode(
      `?token=${data.accessToken}&timestamp=${Date.now()}`
    );
    window.location.replace(`${data.landingPageUrl}${LOGIN}/${redirectToken}`);
  };

  handelSubmit = (values) => {
    this.setState({ loading: true });

    const payload = {
      emailOrPhone: values.email,
      password: values.password,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${AUTH}${SIGNIN_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          AuthCookies.delete("userId");
          AuthCookies.delete("instaUser");
          AuthCookies.delete("ytUser");
          StorageService.delete("spotify_id");
          StorageService.delete("spotify_label");
          StorageService.delete("spotify_url");
          StorageService.delete("isAdminInMaintenance");
          this.loginUser(res.data);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  render() {
    return (
      <div className="theme-bg-dark">
        <Header active={LOGIN} />
        <div className={styles.loginContent}>
          <FormikForm
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={this.validationSchemaEmail}
            onSubmit={this.handelSubmit}
          >
            <div className={styles.formContainer}>
              <Form>
                {this.state.logout ? (
                  <>
                    <h1>You are logged out.</h1>
                    <p className={styles.subTitle}>Login below</p>
                  </>
                ) : (
                  <h1>Login to your account.</h1>
                )}
                <div className={`form-group mb-0`}>
                  <FormField
                    name="email"
                    placeholder="Email address"
                    type="text"
                    label="Email address"
                  />
                </div>
                <div className="form-group">
                  <FormField
                    name="password"
                    placeholder="Password"
                    as="password"
                    label="Password"
                  />
                </div>
                <div className="form-group">
                  <button type="submit" disabled={this.state.loading}>
                    Login
                  </button>
                  <Link className={styles.forgotPassword} to={FORGOT_PASS}>
                    Forgot your password?
                  </Link>
                </div>
              </Form>
            </div>
          </FormikForm>
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

Login.contextType = ThemeContext;
export default Login;
