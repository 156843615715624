import React, { Component } from "react";
import { get } from "lodash";
import * as Yup from "yup";
import ReactModal from "react-modal";
import LoginHeader from "../../../component/LoginHeader";
import generalColors from "../../../styles/global.scss";
import styles from "./MaintenanceMode.module.scss";
import Loader from "../../../component/Loader";
import Navigator from "../../../component/Navigation/Navigator";
import LeftPanel from "../AdminDashboard/LeftPanel";
import { setTitle } from "../../../component/ThemeManager/helper";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import { Grid, Paper } from "@material-ui/core";
import { Form, Formik } from "formik";
import Switch from "../AdminDashboard/Switch";
import FormField from "../../../component/FormField/FormField";
import {
  API_URL,
  ADMIN_MAINTENANCE_MODE_API,
  CHANGE_STATE,
} from "../constants";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { getErrorMessage } from "../helper";
import FormikForm from "../../../component/Form/FormikForm";

class MaintenanceMode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      initialValues: {
        isWidgetActive: false,
        widgetHeaderText: "",
        widgetDetailText: "",
        isAppActive: false,
        appHeaderText: "",
        appDetailText: "",
      },
      isUnderMaintenance: false,
    };
  }

  componentDidMount() {
    this.getMaintenanceStatus();
  }

  componentDidUpdate() {
    setTitle("Maintenance Mode", this.context);
  }

  getMaintenanceStatus = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestURL = `${API_URL}${ADMIN_MAINTENANCE_MODE_API}`;
    request(requestURL, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const { widget, allApps, isUnderMaintenance } = res.data;
          this.setState({
            initialValues: {
              isAppActive: allApps.status,
              isWidgetActive: widget.status,
              appHeaderText: allApps.headerText,
              appDetailText: allApps.detailText,
              widgetHeaderText: widget.headerText,
              widgetDetailText: widget.detailText,
            },
            isUnderMaintenance,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  validationSchemaMaintenance = Yup.object().shape({
    appHeaderText: Yup.string().required().label("App header text"),
    appDetailText: Yup.string().required().label("App detail text"),
    widgetHeaderText: Yup.string().required().label("Widget header text"),
    widgetDetailText: Yup.string().required().label("Widget detail text"),
  });

  validationSchemaAdmin = Yup.object().shape({
    adminPassword: Yup.string().required().label("Password"),
  });

  toggleMaintenanceMode = (values) => {
    this.setState({ loading: true });
    this.handleCloseModal();
    const payload = {
      isUnderMaintenance: !this.state.isUnderMaintenance,
      adminPassword: values.adminPassword,
    };
    const data = {
      method: "PUT",
      body: payload,
    };
    const requestURL = `${API_URL}${ADMIN_MAINTENANCE_MODE_API}${CHANGE_STATE}`;
    request(requestURL, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            isUnderMaintenance: !this.state.isUnderMaintenance,
          });
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  handleCloseModal = () => this.setState({ isModalOpen: false });
  handleOpenModal = () => this.setState({ isModalOpen: true });

  renderConfirmationModal = () => (
    <ReactModal
      isOpen={this.state.isModalOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCloseModal}
      className={styles.deleteModal}
      overlayClassName={styles.modalOverlay}
    >
      <h3 className={`${styles.modalContainer} `}>
        Set Maintenance Mode {this.state.isUnderMaintenance ? `Off` : `On`}?
      </h3>
      <FormikForm
        initialValues={{ adminPassword: "" }}
        validationSchema={this.validationSchemaAdmin}
        onSubmit={this.toggleMaintenanceMode}
      >
        <Form className={`d-flex align-items-center flex-column`}>
          <p className="textSmall text-center mb-0 mt-2">
            Please input the password to confirm the changes:
          </p>
          <div className="form-group mb-0 w-75">
            <FormField
              name="adminPassword"
              label="Password"
              as="password"
              placeholder="Password"
            />
          </div>
          <div
            className={`${styles.modalBtnContainer} ${styles.modalContainer}`}
          >
            <button type="submit" className={styles.primary}>
              Save
            </button>
            <button
              type="reset"
              className={styles.secondary}
              onClick={this.handleCloseModal}
            >
              Cancel
            </button>
          </div>
        </Form>
      </FormikForm>
    </ReactModal>
  );

  handleSaveChanges = (values) => {
    this.setState({ loading: true });

    const payload = {
      isWidgetActive: values.isWidgetActive,
      widgetHeaderText: values.widgetHeaderText,
      widgetDetailText: values.widgetDetailText,
      isAppActive: values.isAppActive,
      appHeaderText: values.appHeaderText,
      appDetailText: values.appDetailText,
    };
    const data = {
      method: "PUT",
      body: payload,
    };
    const requestURL = `${API_URL}${ADMIN_MAINTENANCE_MODE_API}`;
    request(requestURL, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  render() {
    return (
      <>
        <LoginHeader />
        <div className={styles.title}>
          <h2>Admin</h2>
          <Navigator {...this.props} />
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div className={`${styles.title} ${styles.innerTitle}`}>
              <h1>Maintenance Mode</h1>
              {this.state.isUnderMaintenance && (
                <div className={styles.liveStatus}>Live</div>
              )}
              <button className={styles.liveBtn} onClick={this.handleOpenModal}>
                {this.state.isUnderMaintenance ? `Turn Off` : `Turn On`}
              </button>
            </div>
            <div className={styles.formContainer}>
              <Formik
                initialValues={this.state.initialValues}
                enableReinitialize
                validationSchema={this.validationSchemaMaintenance}
                onSubmit={this.handleSaveChanges}
                data-testid="formMaintenance"
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Paper elevation={0} className={styles.card}>
                          <div className={styles.cardTitle}>
                            <p>Widget</p>
                            <p>
                              <Switch
                                id="widgetSwitch"
                                title="Enable/Disable Widget"
                                isOn={values.isWidgetActive}
                                onColor={generalColors.primaryColor}
                                data-testid="widgetSwitch"
                                handleToggle={() => {
                                  setFieldValue(
                                    "isWidgetActive",
                                    !values.isWidgetActive
                                  );
                                }}
                              />
                            </p>
                          </div>
                          <div className="form-group mb-0">
                            <FormField
                              name="widgetHeaderText"
                              label="Header text"
                              placeholder="Header text"
                            />
                          </div>
                          <div className="form-group mb-0">
                            <FormField
                              name="widgetDetailText"
                              label="Details text"
                              as="textarea"
                              placeholder="Details text"
                            />
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Paper elevation={0} className={styles.card}>
                          <div className={styles.cardTitle}>
                            <p>All Apps</p>
                            <p>
                              <Switch
                                id="appSwitch"
                                title="Enable/Disable App"
                                isOn={values.isAppActive}
                                onColor={generalColors.primaryColor}
                                data-testid="appSwitch"
                                handleToggle={() => {
                                  setFieldValue(
                                    "isAppActive",
                                    !values.isAppActive
                                  );
                                }}
                              />
                            </p>
                          </div>
                          <div className="form-group mb-0">
                            <FormField
                              name="appHeaderText"
                              label="Header text"
                              placeholder="Header text"
                            />
                          </div>
                          <div className="form-group mb-0">
                            <FormField
                              name="appDetailText"
                              label="Details text"
                              as="textarea"
                              placeholder="Details text"
                            />
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                    <div className={styles.btnContainer}>
                      <button type="reset">Cancel</button>
                      <button type="submit">Save Changes</button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {this.renderConfirmationModal()}
        </div>
        {this.state.loading && <Loader />}
      </>
    );
  }
}

MaintenanceMode.contextType = ThemeContext;
export default MaintenanceMode;
