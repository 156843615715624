import React, { Component } from "react";
import { get } from "lodash";
import ReactModal from "react-modal";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form } from "formik";
import styles from "./ContactPopup.module.scss";
import FormikForm from "../Form/FormikForm";
import request from "../../utils/request";
import {
  API_URL,
  USER_API,
  CHAT_API,
  ME,
  CHORDCASH_CALENDLY_LINK,
  BEATBREAD_CALENDLY_LINK,
} from "../../routes/pages/constants";
import Loader from "../Loader";
import { EMAIL_REGEX } from "../../routes/pages/Auth/constants";
import FormField from "../FormField/FormField";
import { userExists } from "../../utils/Helper";
import { getErrorMessage } from "../../routes/pages/helper";
import { BB_SUBDOMAINS } from "../ThemeManager/constants";
import { getSubDomain } from "../ThemeManager/helper";

class ContactPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
    };
  }

  componentWillMount() {
    ReactModal.setAppElement("body");
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.getDefaultEmail();
    }
  }

  getDefaultEmail = () => {
    if (userExists() && this.props.isOpen) {
      this.setState({ loading: true });
      const data = {
        method: "GET",
      };
      const requestURL = `${API_URL}${USER_API}${ME}`;
      request(requestURL, data)
        .then((res) => {
          this.setState({ loading: false });
          if (res.status) {
            this.setState({ email: get(res.data, "email.value") });
            return true;
          }
          return false;
        })
        .catch((err) => {
          this.setState({ loading: false });
          toast.error(getErrorMessage(err));
        });
    }
  };
  validationSchema = Yup.object().shape({
    email: Yup.string()
      .required()
      .matches(EMAIL_REGEX, "Please enter valid email only")
      .label("Email address"),
    message: Yup.string().required().label("Message"),
  });

  handleSubmit = (values) => {
    this.setState({ loading: true });
    const payload = {
      email: values.email,
      message: values.message,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestURL = `${API_URL}${USER_API}${CHAT_API}`;
    request(requestURL, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.props.onRequestClose();
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  render() {
    return (
      <>
        <ReactModal
          isOpen={this.props.isOpen}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          onRequestClose={this.props.onRequestClose}
          className={styles.contactModal}
          overlayClassName={`${styles.modalOverlay} ${
            this.state.loading ? styles.loading : ""
          }`}
        >
          <div className={styles.modalBody}>
            <div className={styles.title}>
              <h3>Contact Us</h3>
            </div>
            <div className={styles.chatForm}>
              <FormikForm
                initialValues={{ email: this.state.email, message: "" }}
                enableReinitialize
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
              >
                <Form>
                  <div className="form-group form-group-m0 mb-0">
                    <FormField
                      name="email"
                      placeholder="Email address"
                      label="Email address"
                    />
                  </div>
                  <div className="form-group mb-0">
                    <FormField
                      name="message"
                      as="textarea"
                      placeholder="To help us assist you better, please provide all details such as the artist’s name, what device you are using, etc."
                      label="Please describe your issue"
                    />
                  </div>
                  <div className={styles.btnContainer}>
                    <button type="submit">Send Message</button>
                    <button
                      type="reset"
                      onClick={this.props.onRequestClose}
                      className={styles.btnLink}
                    >
                      Cancel
                    </button>
                    {
                      <a
                        href={
                          BB_SUBDOMAINS.indexOf(getSubDomain()) === -1
                            ? CHORDCASH_CALENDLY_LINK
                            : BEATBREAD_CALENDLY_LINK
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Schedule a time with a team member to review your
                        advance estimates
                      </a>
                    }
                  </div>
                </Form>
              </FormikForm>
            </div>
          </div>
        </ReactModal>
        {this.state.loading && <Loader light />}
      </>
    );
  }
}

export default ContactPopup;
