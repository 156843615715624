import moment from "moment";

export const filterChangedData = (values, initialValue, primaryField) => {
  const formData = new FormData();
  if (values.phone) {
    const regExp = new RegExp(values.countryCode);
    values.phone = values.phone.replace(regExp, "");
  }
  for (const key in values) {
    primaryField !== key &&
      values[key] !== initialValue[key] &&
      formData.append(key, values[key]);
  }
  return formData;
};

export const setInitialData = (data, initialValue) => {
  const formData = {};
  for (const key in initialValue) {
    formData[key] =
      (data[key] && data[key].value !== undefined
        ? data[key].value
        : data[key]) || "";
    if (key === "phone" && data[key].value) {
      formData[key] = `${data[key].countryCode}${data[key].value}`;
    }
  }
  return formData;
};

export const getFormattedDate = (date, format) => {
  return date ? moment(date).format(format) : "";
};

export const getLocationValue = (location, values) => {
  if (values.location) {
    return { label: values.location, value: values.location };
  }
  return location && { label: location, value: location };
};
