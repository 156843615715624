export const MIN_VALUE = 0;
export const MAX_VALUE = 100;
export const RANGE_STEP = 1;
export const TERM_MIN = 1;

export const CATALOG_SLIDER = 0;
export const TERM_SLIDER = 1;
export const C_INC_SLIDER = 2;
export const R_INC_SLIDER = 3;

export const RANGE_MULTIPLY = 0.25;
export const OTHER_VALUE = 60;
export const MIN_MULTIPLIER_VALUE = 0.5;
export const MAX_MULTIPLIER_VALUE = 2.5;
export const INCOME_SLIDER_STEP = 50;

export const INIT_TREND_DATA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
export const CUMULATIVE_MAX_PERCENT = 0.8;
export const MIN_TRACK_DISPLAY = 5;
export const OTHER_ISRC = "111";

export const WIDGET_RENDER = `
<head>
    <link href="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/css/chunk.css" rel="stylesheet" />
    <link href="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/css/main.chunk.css" rel="stylesheet" />
    <style>
        .getFundedContainer {
            padding: 0;
        }
        .select__input input {
            letter-spacing: -0.3px;
        }
    </style>
</head>
<body>
    <div id="getFunded" class="getFundedContainer"></div>
    <script async>
        window.GET_FUNDED_API_URL = "${process.env.REACT_APP_API_URL}";
        window.WIDGET_HEADLINE = "Sign up";
    </script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/chunk.js" async></script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/main.chunk.js" async></script>
    <script src="${process.env.REACT_APP_WIDGET_ENDPOINT}/static/js/runtime-main.js" async></script>
</body>
`;
