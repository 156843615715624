export const VISIBILITY = "Visibility";
export const VISIBILITY_OFF = "VisibilityOff";
export const MENU_ICON = "Menu";
export const INFO_ICON = "Info";
export const MORE_VERT = "MoreVert";
export const PROFILE_ICON = "AccountCircle";
export const MUSIC_ICON = "MusicVideoSharp";
export const SUCCESS_ICON = "CheckCircle";
export const CLOUD_UPLOAD = "CloudUpload";
export const LANGUAGE = "Language";
export const EMAIL_ICON = "MailIcon";
export const VIEW_REPORTS_ICON = "ViewReportsIcon";
export const DELETE_ICON = "DeleteIcon";
export const DOWNLOAD_ICON = "GetApp";
export const EDIT_ICON = "EditIcon";
export const PLUS_ICON = "PlusIcon";
export const MINUS_ICON = "MinusIcon";
export const SYNC_ICON = "Sync";
export const ARROW_FORWARD = "ArrowForward";
