import React, { Component } from "react";
import _, { get } from "lodash";
import base from "base-64";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import ReactModal from "react-modal";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Select from "react-select";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./AdminDashboard.module.scss";
import LeftPanel from "./LeftPanel";
import { USERS_TABLE_COLUMNS, FILTER_STATUS_OPTIONS } from "./constants";
import UserRows from "./UserRows";
import {
  API_URL,
  ADMIN_USERS,
  ADMIN_USER,
  ADMIN_ARTIST_API,
  RESEND_VERIFICATION,
  ADMIN_WHITELABEL_API,
  ARTIST_API,
  REPORTS,
  ADMIN,
  ADMIN_ACTIVATE_USER,
  LOGIN,
  REPROCESS_API,
  REVOKE_APPROVAL_API,
} from "../constants";
import selectStyle from "../../../component/UploadFiles/selectStyle";
import { getErrorMessage } from "../helper";
import LoginHeader from "../../../component/LoginHeader";
import Loader from "../../../component/Loader";
import request from "../../../utils/request";
import Navigator from "../../../component/Navigation/Navigator";
import SvgIcons from "../../../component/MaterialIcons/SvgIcons";
import {
  DELETE_ICON,
  DOWNLOAD_ICON,
} from "../../../component/MaterialIcons/constants";
import StorageService from "../../../utils/StorageService";
import AuthTokenService from "../../../utils/AuthTokenService";
import { getPartnerName } from "../../../component/ThemeManager/helper";
import { REVOKE_OPTIONS } from "./WhiteLabel/constants";
import { AckReportIssueModal, AckTrackIssueModal } from "../SendReports/helper";
import { OVERALL_STATUS } from "../SendReports/constants";

let activePage = 1;
const useDefaultToken = false;
let toggleValue;
class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      image: "",
      enabledUser: false,
      loading: false,
      artistList: [],
      newLinkCount: 0,
      enableSwitch: true,
      count: 0,
      activePage: 1,
      itemsCountPerPage: 10,
      totalItemsCount: 0,
      pageRangeDisplayed: 6,
      artist: [],
      switchModal: false,
      enabledSwitch: "",
      editUserArtist: {},
      list: [],
      selectedUserArtist: {},
      selectedUserIpDetail: {},
      ipDetailModal: false,
      sourceOptions: [],
      selectedSource: null,
      selectedStatus: FILTER_STATUS_OPTIONS[0],
      viewArtistModal: false,
      artistReports: [],
      jwtToken: StorageService.get("jwtToken"),
      isPendingList: false,
      isRevokeModalOpen: false,
      artistToRevoke: undefined,
      selectedReason: undefined,
      reportAnalyticsData: {},
      ackReportIssueModal: false,
      ackTrackIssueModal: false,
    };
  }

  componentDidMount() {
    document.title = `${getPartnerName()}: Admin Dashboard`;
    activePage = 1;
    this.getSourceDetails();
    AuthTokenService.storeToken(StorageService.get("customToken"));
    this.setState({
      jwtToken: StorageService.get("jwtToken"),
    });
  }

  getSourceDetails = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN_WHITELABEL_API}`;

    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const options = res.data.map(({ slugName, _id }) => ({
            label: slugName,
            value: _id,
          }));
          const selected = options.filter(({ label }) => label === "all");
          this.setState(
            { sourceOptions: options, selectedSource: selected[0] },
            this.getLinkedArtists
          );
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  getLinkedArtists = () => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };

    const requestUrl = `${API_URL}${ADMIN_USERS}?limit=20&page=${activePage}&source=${get(
      this.state.selectedSource,
      "value"
    )}&status=${get(this.state.selectedStatus, "value")}&sort=-createdAt`;

    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            switchModal: false,
            list: res.data.list,
            itemsCountPerPage: res.data.limit,
            totalItemsCount: res.data.total,
          });
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  handleSearchChange = (e) => {
    if (e) {
      this.setState({
        artist: [
          {
            _id: e.value,
            isDeleted: e.isDeleted,
            maxLinkedArtist: e.maxLinkedArtist,
            artistName: e.label,
            email: e.email,
            artistImage: e.imageUrl,
            firstName: e.firstName,
            lastName: e.lastName,
            linkedArtist: e.linkedArtist,
            active: e.active,
            ip: e.ip,
            geoLocation: e.geoLocation,
            isSourceActive: e.isSourceActive,
            source: e.source,
          },
        ],
      });
    } else {
      this.setState({ artist: [] });
    }
  };

  editUserToggle = (artist) => {
    this.setState({
      switchModal: true,
      enabledSwitch: artist.isDeleted.value ? "Enable" : "Disable",
      editUserArtist: artist,
    });
  };
  editUserArtistToggle = () => {
    this.editUser(this.state.editUserArtist, "toggle");
  };
  editUser = (artist, editFunction) => {
    this.setState({ loading: true });
    let maxLinkedArtist;
    if (editFunction === "increment") {
      maxLinkedArtist = artist.maxLinkedArtist + 1;
      toggleValue = artist.isDeleted.value;
    }
    if (editFunction === "decrement") {
      if (artist.maxLinkedArtist === 1) {
        this.setState({ loading: false });
        return false;
      }
      maxLinkedArtist = artist.maxLinkedArtist - 1;
      toggleValue = artist.isDeleted.value;
    }
    if (editFunction === "toggle") {
      maxLinkedArtist = artist.maxLinkedArtist;
      toggleValue = !this.state.editUserArtist.isDeleted.value;
    }
    const payload = {
      id: artist._id,
      isDeleted: toggleValue,
      maxLinkedArtist,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${ADMIN_USER}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(get(res, "message"));
          if (editFunction === "increment") {
            artist.maxLinkedArtist = artist.maxLinkedArtist + 1;
          }
          if (editFunction === "decrement") {
            artist.maxLinkedArtist = artist.maxLinkedArtist - 1;
          }
          if (editFunction === "toggle") {
            artist.isDeleted.value = toggleValue;
            this.setState({
              enabledUser: !this.state.editUserArtist.isDeleted.value,
            });
          }
          this.getLinkedArtists();
          return [];
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
        this.setState({ loading: false });
      });
  };
  increment = (artist) => {
    this.setState({ enabledUser: artist.isDeleted.value });
    this.editUser(artist, "increment");
  };

  decrement = (artist) => {
    if (artist.maxLinkedArtist > 0) {
      this.setState({ enabledUser: artist.isDeleted.value });
      this.editUser(artist, "decrement");
    }
  };
  handlePageChange(pageNumber) {
    activePage = pageNumber;
    this.getLinkedArtists();
  }

  sendVerificationMail = (id) => {
    this.setState({ loading: true });
    const payload = {
      id,
    };
    const data = {
      method: "POST",
      body: payload,
    };
    const requestUrl = `${API_URL}${ADMIN_USER}${RESEND_VERIFICATION}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
        this.setState({ loading: false });
      });
  };

  getUserDetail = (artistId) => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
      body: { id: artistId },
    };
    const requestUrl = `${API_URL}${ADMIN_ACTIVATE_USER}`;
    request(requestUrl, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const redirectToken = base.encode(
            `?token=${
              res.data.accessToken
            }&timestamp=${Date.now()}&isAdmin=${true}`
          );
          window.open(`${res.data.landingPageUrl}${LOGIN}/${redirectToken}`);
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  handleCancel = () => {
    this.setState({
      switchModal: false,
      isRevokeModalOpen: false,
      artistToRevoke: undefined,
      editUserArtist: {},
    });
  };

  switchModal = () => (
    <ReactModal
      isOpen={this.state.switchModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancel}
      className={styles.imageModal}
      overlayClassName={styles.modalOverlay}
    >
      <h3>Are you sure you want to {this.state.enabledSwitch} User?</h3>
      <div className={styles.modalBtnContainer}>
        <button className={styles.primary} onClick={this.editUserArtistToggle}>
          Yes
        </button>
        <button className={styles.secondary} onClick={this.handleCancel}>
          No
        </button>
      </div>
    </ReactModal>
  );

  revokeArtistAction = (artist) => {
    this.setState({ isRevokeModalOpen: true, artistToRevoke: artist });
  };

  handleRevokeArtistStatus = () => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
      body: {
        id: get(this.state.artistToRevoke, "_id", ""),
        reason: get(this.state.selectedReason, "value"),
      },
    };
    const requestUrl = `${API_URL}${ADMIN_ARTIST_API}${REVOKE_APPROVAL_API}`;
    request(requestUrl, data, false)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.setState({
            artistToRevoke: undefined,
            isRevokeModalOpen: false,
          });
          this.getLinkedArtists();
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  onReasonChange = (option) => {
    this.setState({ selectedReason: option });
  };

  revokeApprovalModal = () => (
    <ReactModal
      isOpen={this.state.isRevokeModalOpen}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCancel}
      className={styles.imageModal}
      overlayClassName={styles.modalOverlay}
    >
      <h3>Revoke Offer for {get(this.state.artistToRevoke, "name")}?</h3>
      <p className={`text-center`}>
        Are you sure you want to revoke their offer? This will force the user to
        go through the report funnel.
      </p>
      <Select
        isSearchable
        classNamePrefix="revoke"
        styles={selectStyle}
        onChange={this.onReasonChange}
        value={this.state.selectedReason}
        options={REVOKE_OPTIONS}
        placeholder="Reason"
      />
      <div className={styles.modalBtnContainer}>
        <button
          className={styles.primary}
          disabled={!this.state.selectedReason}
          onClick={this.handleRevokeArtistStatus}
        >
          Revoke
        </button>
        <button className={styles.secondary} onClick={this.handleCancel}>
          Cancel
        </button>
      </div>
    </ReactModal>
  );

  offerReGeneration = (artist, user) => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${ADMIN_ARTIST_API}/${artist._id}${REPROCESS_API}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const users = this.state.list;
          const userIndex = _.findIndex(users, ["_id", user._id]);
          if (userIndex === -1) {
            const user = this.state.artist[0];
            const artistIndex = _.findIndex(user.linkedArtist, [
              "_id",
              artist._id,
            ]);
            user.linkedArtist[artistIndex].reprocessCount = get(
              res,
              "data.reprocessCount"
            );
            user.linkedArtist[artistIndex].lastReprocessedAt = get(
              res,
              "data.lastReprocessedAt"
            );
            this.setState({ artist: [user] });
            toast.success(get(res, "message"));
            return true;
          }
          const artistIndex = _.findIndex(users[userIndex].linkedArtist, [
            "_id",
            artist._id,
          ]);
          users[userIndex].linkedArtist[artistIndex].reprocessCount = get(
            res,
            "data.reprocessCount"
          );
          users[userIndex].linkedArtist[artistIndex].lastReprocessedAt = get(
            res,
            "data.lastReprocessedAt"
          );
          this.setState({ list: users });
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
        this.setState({ loading: false });
      });
  };

  renderUsersTabularView = () => {
    const { list, artist } = this.state;
    const isDisplaySearched = artist.length > 0;
    const displayValue = isDisplaySearched ? artist : list;
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {USERS_TABLE_COLUMNS.map((column) => (
                  <TableCell {...column.props} key={column.key}>
                    {column.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {displayValue.map((artist) => (
                  <UserRows
                    key={artist._id}
                    artist={artist}
                    onResendVerificationLink={() =>
                      this.sendVerificationMail(artist._id)
                    }
                    onSwitchToggle={() => this.editUserToggle(artist)}
                    incrementArtistLimit={() => this.increment(artist)}
                    decrementArtistLimit={() => this.decrement(artist)}
                    handleUserRedirection={() => {
                      this.getUserDetail(artist._id);
                    }}
                    renderDeleteArtistPopup={(artist, user) =>
                      this.renderDeleteArtistPopUp(artist, user)
                    }
                    viewReports={(artistDetails) =>
                      this.handleViewReports(artistDetails)
                    }
                    data-testid="UserRows"
                    editSourceAction={() => {
                      this.editSourceAction(artist);
                    }}
                    isPendingList={_.isEqual(
                      this.state.selectedStatus,
                      FILTER_STATUS_OPTIONS[1]
                    )}
                    offerReGeneration={this.offerReGeneration}
                    revokeArtistAction={this.revokeArtistAction}
                  />
                ))}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        {!isDisplaySearched && (
          <div className={styles.adminPagination}>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={this.state.itemsCountPerPage}
              totalItemsCount={this.state.totalItemsCount}
              pageRangeDisplayed={this.state.pageRangeDisplayed}
              onChange={this.handlePageChange.bind(this)}
              activeClass={styles.active}
            />
          </div>
        )}
      </>
    );
  };

  popoverClose = (id) => document.getElementById(`del_${id}`).click();

  handelDeleteArtist = (id) => {
    this.popoverClose(id);
    this.setState({ loading: true });
    const data = {
      method: "DELETE",
    };
    const requestUrl = `${API_URL}${ADMIN_ARTIST_API}?id=${id}`;

    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          this.getLinkedArtists();
          this.state.artist.length &&
            this.setState({
              artist: [
                {
                  ...this.state.artist[0],
                  linkedArtist: this.state.artist[0].linkedArtist.filter(
                    (artist) => artist._id !== id
                  ),
                },
              ],
            });
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  popover = (prop, id) => (
    <Popover id={id} {...prop} className={styles.popover}>
      <Popover.Title className={styles.titlePopover}>
        Are you sure you want to delete this artist?
      </Popover.Title>
      <Popover.Content>
        <div className={styles.btnPopover}>
          <button
            onClick={() => {
              this.handelDeleteArtist(id);
            }}
          >
            Yes
          </button>
          <button
            onClick={() => {
              this.popoverClose(id);
            }}
          >
            No
          </button>
        </div>
      </Popover.Content>
    </Popover>
  );

  renderDeleteArtistPopUp = (artist, user) => (
    <OverlayTrigger
      trigger={!user.isDeleted.value && "click"}
      rootClose
      placement="top"
      overlay={(prop) => this.popover(prop, artist._id)}
    >
      <i
        className={`${styles.deleteIcon}`}
        title="Delete Artist"
        id={`del_${artist._id}`}
      >
        <SvgIcons icon={DELETE_ICON} />
      </i>
    </OverlayTrigger>
  );

  sourceFilterChange = (option) => {
    this.setState({ selectedSource: option }, this.getLinkedArtists);
    activePage = 1;
  };

  handleViewReports = (artistDetails) => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${REPORTS}?id=${artistDetails._id}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({
          loading: false,
          viewArtistModal: true,
          artistReports: res.data.reportList,
          reportAnalyticsData: res.data.reportAnalyticsData,
        });
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
        this.setState({ loading: false });
      });
  };

  handleDownloadReports = (reportData) => {
    this.setState({ loading: true });
    const { originalName, key } = reportData;
    const payload = {
      key,
      originalName,
    };
    const data = {
      method: "POST",
      body: payload,
      blob: true,
    };
    const requestUrl = `${API_URL}${ADMIN}${ARTIST_API}${REPORTS}`;
    request(requestUrl, data, useDefaultToken)
      .then((res) => {
        this.setState({ loading: false });
        this.setState({ loading: false });
        if (!res.type.includes("/json") || originalName.includes(".json")) {
          const url = URL.createObjectURL(res);
          const a = document.createElement("a");
          a.href = url;
          a.download = originalName;
          a.style.display = "none";
          a.target = "_blank";
          document.body.append(a);
          a.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(a);
          return true;
        }
        toast.error("File downloading failed");
        return false;
      })
      .catch((err) => {
        toast.error(getErrorMessage(err));
        this.setState({ loading: false });
      });
  };

  handleCloseViewArtistModal = () => {
    this.setState({
      viewArtistModal: false,
      artistReports: [],
    });
  };

  handleOpenAnalysisPopup = () => {
    get(this.state.reportAnalyticsData, "status") === OVERALL_STATUS[1]
      ? this.setState({ ackReportIssueModal: true })
      : this.setState({ ackTrackIssueModal: true });
  };

  viewReports = () => (
    <ReactModal
      isOpen={this.state.viewArtistModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={this.handleCloseViewArtistModal}
      className={styles.deleteModal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={`${styles.modalContainer} ${styles.reportListContainer}`}>
        {this.state.artistReports.length > 0 ? (
          <table>
            <tr>
              <th>Name</th>
              <th>Distributor</th>
              <th></th>
            </tr>
            {this.state.artistReports.map((report) => (
              <tr
                key={report.key}
                className={report.isDeleted ? styles.deleted : ""}
              >
                <td>
                  {report.originalName}
                  {report.isDeleted && ` (Deleted)`}
                </td>
                <td>
                  {report.isOther
                    ? `Other: ${report.distributorName}`
                    : report.distributorName}
                </td>
                {report.isDeleted ? (
                  <td></td>
                ) : (
                  <td
                    onClick={() => {
                      this.handleDownloadReports(report);
                    }}
                    data-testid="DownloadReport"
                    title={`Download ${report.originalName}`}
                  >
                    <SvgIcons icon={DOWNLOAD_ICON} />
                  </td>
                )}
              </tr>
            ))}
          </table>
        ) : (
          <p className={`text-center ${styles.noReports}`}>
            No reports to show
          </p>
        )}
      </div>
      <div className={`${styles.modalBtnContainer} ${styles.modalContainer}`}>
        <button
          className={styles.filled}
          onClick={this.handleOpenAnalysisPopup}
          disabled={
            OVERALL_STATUS.indexOf(
              get(this.state.reportAnalyticsData, "status")
            ) <= 0
          }
        >
          See Report Analysis
        </button>
        <button
          className={styles.primary}
          onClick={this.handleCloseViewArtistModal}
        >
          Close
        </button>
      </div>
    </ReactModal>
  );

  statusFilterChange = (option) => {
    activePage = 1;
    this.setState({ selectedStatus: option }, this.getLinkedArtists);
  };

  handleCloseAnalysis = () => {
    this.setState({ ackReportIssueModal: false, ackTrackIssueModal: false });
  };

  render() {
    return (
      <>
        <LoginHeader
          handleSearchChange={this.handleSearchChange.bind(this)}
          activePage={activePage - 1}
          userToken={this.state.jwtToken}
          selectedStatus={this.state.selectedStatus}
          selectedSource={this.state.selectedSource}
        />
        <div className={styles.title}>
          <h2>Admin</h2>
          <Navigator {...this.props} />
        </div>
        <div className={styles.container}>
          <LeftPanel {...this.props} />
          <div className={styles.subContainer}>
            <div className={`${styles.title} ${styles.innerTitle}`}>
              <h1>Users</h1>
            </div>
            <div className={styles.filterBox}>
              <div>
                <h4>Source: </h4>
                <Select
                  isSearchable
                  isDisabled={this.state.artist.length}
                  classNamePrefix="filter"
                  styles={selectStyle}
                  onChange={this.sourceFilterChange}
                  value={this.state.selectedSource}
                  options={this.state.sourceOptions}
                  placeholder="Source"
                />
              </div>
              <div>
                <h4>Status: </h4>
                <Select
                  isSearchable
                  isDisabled={this.state.artist.length}
                  classNamePrefix="status"
                  styles={selectStyle}
                  onChange={this.statusFilterChange}
                  value={this.state.selectedStatus}
                  options={FILTER_STATUS_OPTIONS}
                  placeholder="Status"
                />
              </div>
            </div>
            <div className={styles.artistContainer}>
              {this.renderUsersTabularView()}
            </div>
          </div>
        </div>
        {this.switchModal()}
        {this.viewReports()}
        {this.revokeApprovalModal()}
        <AckReportIssueModal
          isOpen={this.state.ackReportIssueModal}
          reportData={get(
            this.state.reportAnalyticsData,
            "processed_reports.distributor",
            []
          )}
          failedReports={get(this.state.reportAnalyticsData, "failed", [])}
          handleClose={this.handleCloseAnalysis}
          isAdmin
        />
        <AckTrackIssueModal
          isOpen={this.state.ackTrackIssueModal}
          trackData={get(this.state.reportAnalyticsData, "missing_isrc", [])}
          handleClose={this.handleCloseAnalysis}
          isAdmin
        />
        {this.state.loading && <Loader />}
      </>
    );
  }
}

export default AdminDashboard;
