import React, { Component } from "react";
import styles from "../Misc.module.scss";
import { Link } from "react-router-dom";
import { HOME } from "../../constants";
class ErrorServerDown extends Component {
  render() {
    return (
      <>
        <div className="theme-bg-dark">
          <div className={styles.errorContainer}>
            <div>
              <h1>503</h1>
            </div>
            <div>
              <h3>We’ll be back online shortly</h3>
            </div>
            <a href={`mailto:help@beatbread.com`} className={styles.homeLink}>
              Contact beatBread
            </a>
            <Link to={HOME} className={styles.homeLink}>
              Return Home
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default ErrorServerDown;
