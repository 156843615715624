import React, { Component } from "react";
import { get } from "lodash";
import ReactModal from "react-modal";
import generalColors from "../../../styles/global.scss";
import Header from "../../../component/Header";
import styles from "./EmailPreferences.module.scss";
import {
  LOGIN,
  API_URL,
  USER_API,
  EMAIL_PREFERENCES_API,
  EMAIL_PREFERENCES,
} from "../constants";
import Loader from "../../../component/Loader";
import { ThemeContext } from "../../../component/ThemeManager/ThemeManager";
import {
  setTitle,
  getPartnerName,
} from "../../../component/ThemeManager/helper";
import { userExists } from "../../../utils/Helper";
import LoginHeader from "../../../component/LoginHeader/LoginHeader";
import request from "../../../utils/request";
import { toast } from "react-toastify";
import { getErrorMessage } from "../helper";
import Switch from "../AdminDashboard/Switch";
import {
  CHORDCASH_PARTNER,
  UPDATES_PREFERENCE,
  MARKETING_PREFERENCE,
  ALL_PREFERENCE,
  FALSE_PREFERENCES,
} from "./constants";
import Navigator from "../../../component/Navigation/Navigator";

class EmailPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preferences: {
        [UPDATES_PREFERENCE]: true,
        [MARKETING_PREFERENCE]: true,
        [ALL_PREFERENCE]: true,
      },
      email: "",
      userId: "",
      isPopupOpen: false,
      isAllChordCashOff: false,
      loading: false,
    };
  }
  componentDidMount() {
    this.checkOnLoadData();
  }

  componentDidUpdate() {
    setTitle("Email Preferences", this.context);
  }

  checkOnLoadData = () => {
    const params = new URLSearchParams(window.location.search);
    if (userExists()) {
      this.getPreferenceDetails();
    } else if (params.get("userId")) {
      this.getPreferenceDetails(params.get("userId"));
    } else {
      this.props.history.push(LOGIN);
    }
  };

  getPreferenceDetails = (userId = "") => {
    this.setState({ loading: true });
    const data = {
      method: "GET",
    };
    const requestUrl = `${API_URL}${USER_API}${EMAIL_PREFERENCES_API}${
      userId && `?userId=${userId}`
    }`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          const preferences = get(res.data, "emailPreferences", {});
          this.setState({
            preferences: {
              [UPDATES_PREFERENCE]: preferences[UPDATES_PREFERENCE],
              [MARKETING_PREFERENCE]: preferences[MARKETING_PREFERENCE],
              [ALL_PREFERENCE]: preferences[ALL_PREFERENCE],
            },
            email: res.data.email,
            userId,
          });
          return true;
        }
        toast.error(get(res, "message"));

        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  handleSubmit = (optOutOfAll) => {
    if (this.state.isAllChordCashOff) {
      this.setState({
        preferences: { ...FALSE_PREFERENCES },
        isAllChordCashOff: false,
        isPopupOpen: false,
      });
      return true;
    }
    this.setState({ loading: true, isPopupOpen: false });
    const payload = {
      userId: this.state.userId,
      emailPreferences:
        optOutOfAll === true
          ? {
              [UPDATES_PREFERENCE]: false,
              [MARKETING_PREFERENCE]: false,
              [ALL_PREFERENCE]: false,
            }
          : this.state.preferences,
    };
    const data = {
      method: "PUT",
      body: payload,
    };
    const requestUrl = `${API_URL}${USER_API}${EMAIL_PREFERENCES_API}`;
    request(requestUrl, data)
      .then((res) => {
        this.setState({ loading: false });
        if (res.status) {
          optOutOfAll === true &&
            this.setState({
              preferences: { ...FALSE_PREFERENCES },
            });
          toast.success(get(res, "message"));
          return true;
        }
        toast.error(get(res, "message"));
        return false;
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(getErrorMessage(err));
      });
  };

  getDisplayName = () =>
    getPartnerName() === CHORDCASH_PARTNER &&
    `${get(this.context, "displayName", "")} `;

  handlePreferenceChange = (e, key) => {
    const { preferences } = this.state;
    const checked = e.target.checked;
    if (key === ALL_PREFERENCE && !checked) {
      this.handleTogglePopup(true);
      return true;
    }
    if (checked) preferences[ALL_PREFERENCE] = checked;
    preferences[key] = checked;
    this.setState({ preferences });
  };

  handleTogglePopup = (isAllChordCashOff) => {
    this.setState({ isPopupOpen: !this.state.isPopupOpen });
    (isAllChordCashOff === true || this.state.isAllChordCashOff) &&
      this.setState({ isAllChordCashOff: !this.state.isAllChordCashOff });
  };

  optOutConfirmation = () => (
    <ReactModal
      isOpen={this.state.isPopupOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={this.handleTogglePopup}
      className={styles.imageModal}
      overlayClassName={`${styles.modalOverlay}`}
    >
      <div className={styles.modalBody}>
        <div className={styles.title}>
          <h3>
            Are you sure you want to opt out of all emails from{" "}
            {getPartnerName()}.com?
          </h3>
        </div>
        {this.getDisplayName() ? (
          <p>
            This may affect emails from other {getPartnerName()} distributors or
            partners. You can always change these settings later. You will still
            receive transactional and support emails regarding your{" "}
            {getPartnerName()} account.
          </p>
        ) : (
          <p>
            You can always change these settings later. You will still receive
            transactional and support emails regarding your {getPartnerName()}{" "}
            account.
          </p>
        )}
        <div className={styles.modalBtnContainer}>
          <button onClick={this.handleTogglePopup} className={styles.secondary}>
            Cancel
          </button>
          <button
            className={styles.primary}
            onClick={() => {
              this.handleSubmit(true);
            }}
            data-testid="handleSubmit"
          >
            Opt Out of All
          </button>
        </div>
      </div>
    </ReactModal>
  );

  render() {
    return (
      <div className="theme-bg-dark">
        {userExists() ? <LoginHeader /> : <Header />}
        <div className={`${styles.pageContent}`}>
          {userExists() && (
            <div className={styles.title}>
              <h2>Email Preferences</h2>
              <Navigator {...this.props} />
            </div>
          )}
          <div className={styles.header}>
            <h2 className={`${!userExists() ? styles.visible : ""}`}>
              Email Preferences
            </h2>
            <p>{this.state.email}</p>
          </div>
          <div className={styles.preferenceContainer}>
            <table>
              <thead>
                <tr>
                  <th>Email Group</th>
                  <th>Receiving</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h4>Transactional Emails</h4>
                    <p className={styles.subText}>
                      These emails will always be sent to you for important
                      updates on your account or actions triggered by you
                      (forgot password, adding an artist, etc)
                    </p>
                  </td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>
                    <h4>
                      {this.getDisplayName()}Advance Estimates Updates/Refreshes
                    </h4>
                    <p className={styles.subText}>
                      Notification emails for expiring and refreshed advance
                      estimates
                    </p>
                  </td>
                  <td>
                    <Switch
                      id={`${EMAIL_PREFERENCES}${UPDATES_PREFERENCE}`}
                      isOn={!!this.state.preferences[UPDATES_PREFERENCE]}
                      handleToggle={(e) => {
                        this.handlePreferenceChange(e, UPDATES_PREFERENCE);
                      }}
                      data-testid="preferenceSwitch"
                      onColor={generalColors.primaryColor}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>{this.getDisplayName()}Marketing Emails</h4>
                    <p className={styles.subText}>
                      Newsletters that give you more information about what{" "}
                      {getPartnerName()} can offer you
                    </p>
                  </td>
                  <td>
                    <Switch
                      id={`${EMAIL_PREFERENCES}${MARKETING_PREFERENCE}`}
                      isOn={!!this.state.preferences[MARKETING_PREFERENCE]}
                      handleToggle={(e) => {
                        this.handlePreferenceChange(e, MARKETING_PREFERENCE);
                      }}
                      data-testid="preferenceSwitch"
                      onColor={generalColors.primaryColor}
                    />
                  </td>
                </tr>
                {this.getDisplayName() && (
                  <tr>
                    <td>
                      <h4>All of chordCash</h4>
                    </td>
                    <td>
                      <Switch
                        id={`${EMAIL_PREFERENCES}${ALL_PREFERENCE}`}
                        isOn={!!this.state.preferences[ALL_PREFERENCE]}
                        handleToggle={(e) => {
                          this.handlePreferenceChange(e, ALL_PREFERENCE);
                        }}
                        onColor={generalColors.primaryColor}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className={styles.buttonContainer}>
              <button className={styles.secondary} onClick={this.handleSubmit}>
                Save Preferences
              </button>
              <button
                className={styles.primary}
                onClick={this.handleTogglePopup}
                data-testid="popupOpen"
              >
                Opt Out of All Emails
              </button>
            </div>
          </div>
        </div>
        {this.optOutConfirmation()}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

EmailPreferences.contextType = ThemeContext;
export default EmailPreferences;
