import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce, get } from "lodash";
import {
  HOME,
  MY_ACCOUNT,
  DASHBOARD,
  ADMIN_DASHBOARD,
  API_URL,
  ADMIN_USERS,
  ADMIN,
  EMAIL_PREFERENCES,
  USER_API,
  ARTIST_API,
  EXIT_DEMO,
} from "../../routes/pages/constants";
import styles from "./LoginHeader.module.scss";
import { logout } from "../../utils/Helper";
import SvgIcons from "../../component/MaterialIcons/SvgIcons";
import { PROFILE_ICON } from "../../component/MaterialIcons/constants";
import { fetchAccountImg } from "../commonApiCall/AccountImageCall";
import Select from "react-select/async";
import {
  NoOptionsMessage,
  LoadingMessage,
} from "../../routes/pages/Auth/Getfunded/helper";
import { userSearchStyle } from "../../routes/pages/Auth/Getfunded/autofillStyle";
import { toast } from "react-toastify";
import request from "../../utils/request";
import StorageService from "../../utils/StorageService";
import ContactPopup from "../ContactPopup";
import AuthTokenService from "../../utils/AuthTokenService";
import { getErrorMessage } from "../../routes/pages/helper";
import { ThemeContext } from "../ThemeManager/ThemeManager";
import Image from "../Image";
import { IMAGE_TYPE } from "../Image/constants";
import Loader from "../Loader";

class LoginHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgUrl: undefined,
      role: "",
      isOpen: false,
      loading: false,
    };
    this.getSearchOption = debounce(this.getSearchOptions.bind(this), 500);
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };
  handleClose = () => {
    this.setState({ isOpen: false });
  };

  checkActiveLocation = (value) => {
    return window.location.pathname.includes(value);
  };

  componentDidMount() {
    !this.props.isDemoDashboard && fetchAccountImg.call(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userToken !== this.props.userToken) {
      fetchAccountImg.call(this);
    }
  }
  getSearchOptions = (inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
    } else {
      const reqData = {
        method: "GET",
      };
      const requestURL = `${API_URL}${ADMIN_USERS}?limit=50&page=1&source=${get(
        this.props.selectedSource,
        "value"
      )}&status=${get(
        this.props.selectedStatus,
        "value"
      )}&searchByName=${encodeURIComponent(inputValue)}&sort=-createdAt`;
      request(requestURL, reqData, false)
        .then((res) => {
          if (!res.status) {
            toast.error(res.message);
            callback([]);
          } else {
            callback(this.getSearchOptionResponse(res));
          }
        })
        .catch((err) => {
          callback([]);
          toast.error(getErrorMessage(err));
        });
    }
  };

  getSearchOptionResponse = (res) => {
    const options = [];
    options.push({
      value: "total-count",
      label: res.data.list.length + " Users",
      imageUrl: "Total Users",
      isDisabled: true,
    });
    if (res.data.list.length > 0) {
      res.data.list.map((item) =>
        options.push({
          value: item._id,
          isDeleted: item.isDeleted,
          maxLinkedArtist: item.maxLinkedArtist,
          label: item.active
            ? `${item.firstName} ${item.lastName}`
            : `${item.email.value}`,
          imageUrl: item.profilePicture,
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName,
          linkedArtist: item.linkedArtist,
          active: item.active,
          ip: item.ip,
          geoLocation: item.geoLocation,
          isSourceActive: item.isSourceActive,
          source: item.source,
        })
      );
    }

    this.setState({ selectOptions: options });
    return options;
  };
  renderImage = (imageUrl) => {
    return (
      <div className={styles.profile}>
        {this.props.profileImg || imageUrl ? (
          <Image
            src={this.props.profileImg || imageUrl}
            alt="artist_profile"
            imageType={IMAGE_TYPE.PROFILE}
          />
        ) : (
          <SvgIcons icon={PROFILE_ICON} />
        )}
      </div>
    );
  };
  formatOptionLabel = ({ label, imageUrl, email }) => {
    return (
      <div className={`${styles.selectOption}`}>
        {imageUrl !== "Total Users" ? (
          <div className={`d-flex align-items-center`}>
            {this.renderImage(imageUrl)}
            <div className={`d-flex flex-column justify-content-center`}>
              <p>{label}</p>
              {!label.includes("@") && (
                <p className={styles.optional}>{email.value}</p>
              )}
            </div>
          </div>
        ) : (
          <div className={`d-flex align-items-center`}>
            <p className={styles.searchUsers}>{label}</p>
          </div>
        )}
      </div>
    );
  };
  storeCustomTokenInJwt = () => {
    AuthTokenService.storeToken(StorageService.get("customToken"));
  };

  exitDemo = () => {
    this.setState({ loading: true });
    const data = {
      method: "POST",
    };
    const requestUrl = `${API_URL}${USER_API}${ARTIST_API}${EXIT_DEMO}`;
    request(requestUrl, data)
      .then(() => {
        window.location.href = get(this.context, "whitelistUrl[0]", "");
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      });
  };

  render() {
    return (
      <>
        <div className={styles.headermain}>
          <div className={styles.container}>
            <div className={styles.leftNav}>
              <Link to={HOME} className={styles.title}>
                <Image
                  src={get(this.context, "appLogo")}
                  alt="logo"
                  className={styles.bBLogo}
                  imageType={IMAGE_TYPE.LOGO}
                />
              </Link>
              {this.props.isDemo ? (
                <>
                  <span className={styles.demoIndicator}>
                    Viewing artist demo
                  </span>
                  <span
                    className={`${styles.menuText} ${styles.spanLink}`}
                    onClick={this.exitDemo}
                  >
                    Exit demo
                  </span>
                </>
              ) : (
                <>
                  {!window.location.pathname.includes(ADMIN) && (
                    <Link
                      to={DASHBOARD}
                      className={`${
                        this.checkActiveLocation(DASHBOARD) && styles.primary
                      } ${styles.menuText}`}
                    >
                      My Artists
                    </Link>
                  )}
                  <span
                    onClick={this.handleOpen}
                    className={`${this.state.isOpen && styles.primary} ${
                      styles.menuText
                    } ${styles.spanLink}`}
                  >
                    Contact Us
                  </span>
                </>
              )}
            </div>
            <div className={styles.rightNav}>
              {window.location.pathname === ADMIN_DASHBOARD && (
                <div className={styles.adminSearch}>
                  <i className="fa fa-search"></i>
                  <Select
                    maxMenuHeight={176}
                    id="artistSearchSelect"
                    data-test="AdminSelect"
                    className={`${styles.selectSearch} "basic-multi-select"`}
                    classNamePrefix="select"
                    isClearable={true}
                    loadOptions={this.getSearchOption}
                    onChange={this.props.handleSearchChange}
                    formatOptionLabel={this.formatOptionLabel}
                    name="identifier"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                      NoOptionsMessage,
                      LoadingMessage,
                    }}
                    styles={userSearchStyle}
                    placeholder=""
                    menuIsOpen
                  />
                </div>
              )}
              {this.props.isDemo ? (
                <span className={styles.highlightedBtn} onClick={this.exitDemo}>
                  See Your Real Advance Offers
                </span>
              ) : (
                <div className={styles.profileContainer}>
                  {this.renderImage(this.state.imgUrl)}
                  <div className={styles.dropDown}>
                    {StorageService.get("customToken") && (
                      <Link
                        to={ADMIN_DASHBOARD}
                        className={`${
                          this.checkActiveLocation(ADMIN_DASHBOARD) &&
                          styles.primary
                        } ${styles.menuText}`}
                        onClick={this.storeCustomTokenInJwt}
                      >
                        Admin
                      </Link>
                    )}
                    <Link
                      to={MY_ACCOUNT}
                      className={`${
                        this.checkActiveLocation(MY_ACCOUNT) && styles.primary
                      } ${styles.menuText}`}
                    >
                      My Account
                    </Link>
                    <Link
                      to={EMAIL_PREFERENCES}
                      className={`${
                        this.checkActiveLocation(EMAIL_PREFERENCES) &&
                        styles.primary
                      } ${styles.menuText}`}
                    >
                      Email Preferences
                    </Link>
                    <Link onClick={logout} className={styles.menuText}>
                      Logout
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ContactPopup
          isOpen={this.state.isOpen}
          onRequestClose={this.handleClose}
        />
        {this.state.loading && <Loader />}
      </>
    );
  }
}

LoginHeader.contextType = ThemeContext;

export default LoginHeader;
