import { BB_SUBDOMAINS } from "./constants";
import { get } from "lodash";

export const getSubDomain = () => window.location.hostname || BB_SUBDOMAINS[0];

export const setTitle = (pageTitle, context) => {
  const preText =
    BB_SUBDOMAINS.indexOf(getSubDomain()) === -1
      ? `${context && `${get(context, "displayName")} - `}chordCash`
      : "beatBread";
  document.title = `${preText}${pageTitle ? ` : ${pageTitle}` : ""}`;
};

export const getPartnerName = () => {
  return BB_SUBDOMAINS.indexOf(getSubDomain()) === -1
    ? "chordCash"
    : "beatBread";
};

export const extractHostname = (url) => {
  let hostname;
  if (url.indexOf("//") !== -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }
  hostname = hostname.split(":")[0];
  hostname = hostname.split("?")[0];

  return hostname;
};

const CC_THEME = {
  appLogo: "",
};

const BB_THEME = {
  appLogo: "https://dev.beatbread.com/logo/beatbread-blue-logo.png",
};

export const getConditionalTheme = () => {
  const partnerDisplayName = {
    displayName: getPartnerName(),
  };
  if (getPartnerName() === "beatBread") {
    return {
      ...BB_THEME,
      ...partnerDisplayName,
    };
  }
  return {
    ...CC_THEME,
    ...partnerDisplayName,
  };
};
