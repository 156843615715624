import React from "react";
import { get } from "lodash";
import {
  CATALOG_SLIDER,
  TERM_SLIDER,
  C_INC_SLIDER,
  R_INC_SLIDER,
  WIDGET_RENDER,
} from "./constants";
import styles from "./YourAdvance.module.scss";
import { Collapse } from "@material-ui/core";
import htmlParser from "html-react-parser";
import { DATA_REVIEW, TUNE_MY_DATA, FUNDING, SEND_REPORTS } from "../constants";

export const getShortNumber = (num) => {
  if (Math.abs(num) >= 1.0e9) {
    return `${Math.round((num / 1.0e9) * 10) / 10}b`;
  }

  if (Math.abs(num) >= 1.0e6) {
    return `${Math.round((num / 1.0e6) * 10) / 10}m`;
  }

  if (Math.abs(num) >= 1.0e3) {
    return `${Math.round((num / 1.0e3) * 10) / 10}k`;
  }

  return num;
};

export const formatTrendData = (data) => data.map((value) => ({ value }));

const getObjectData = (data, index) => data[Object.keys(data)[index]];

const getObjectLength = (data) => Object.keys(data).length - 1;

const getValidData = (data, key) => {
  if (key === null) {
    return getObjectData(data, 0);
  }
  if (data[key]) {
    return data[key];
  }
  for (const item in data) {
    if (item > key) {
      return data[item];
    }
  }
  return getObjectData(data, 0);
};

export const getMaxTermValue = (data, catalog) => {
  const catalogData = getObjectData(data, catalog);
  const last = getObjectLength(catalogData);
  return Object.keys(catalogData)[last];
};

export const getMaxValue = (data, sliderIndex, catalog, term, cIncome) => {
  if (sliderIndex === CATALOG_SLIDER) return getObjectLength(data);
  const catalogData = getObjectData(data, catalog);
  if (sliderIndex === TERM_SLIDER) return getObjectLength(catalogData);
  const termData = getValidData(catalogData, term);
  if (sliderIndex === C_INC_SLIDER) return getObjectLength(termData);
  const cIncomeData = getValidData(termData, cIncome);
  if (sliderIndex === R_INC_SLIDER) return getObjectLength(cIncomeData);
};

const marksMapping = (data) => {
  const marks = {};
  Object.keys(data).map((key) => {
    marks[key] = "";
    return true;
  });
  return marks;
};

export const getAdvanceKeys = (data, sliderIndex, catalog, term, cIncome) => {
  const catalogData = getObjectData(data, catalog);
  if (sliderIndex === TERM_SLIDER) return marksMapping(catalogData);
  const termData = getValidData(catalogData, term);
  if (sliderIndex === C_INC_SLIDER) return marksMapping(termData);
  const cIncomeData = getValidData(termData, cIncome);
  if (sliderIndex === R_INC_SLIDER) return marksMapping(cIncomeData);
};

export const getCurrentCombinationValue = (
  data,
  catalog,
  term,
  cIncome,
  rIncome
) => {
  const catalogData = getObjectData(data, catalog);
  const termData = getValidData(catalogData, term);
  const cIncomeData = getValidData(termData, cIncome);

  return Object.keys(cIncomeData).includes("null")
    ? cIncomeData[null]
    : getValidData(cIncomeData, rIncome);
};

export const getNewReleaseLabel = (data, future) =>
  future !== null && <>,&nbsp;New Releases: {data}%</>;

export const getDefaultOffer = (defaultOffer, data) => ({
  newTracks: Object.keys(data).indexOf(defaultOffer.works),
  term: defaultOffer.term_length,
  catalogIncome: defaultOffer.catalog,
  releaseIncome: defaultOffer.future,
});

export const numberWithCommas = (num = 0) =>
  num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getAdvanceOptionalCount = (work = "", isOptional) => {
  const split = work.split("+");
  const result = isOptional
    ? get(split, "2", "").match(/\d+/)
    : get(split, "1", "").match(/\d+/);
  return get(result, "0", 0);
};

export class FunnelCollapseGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.widgetRenderArea = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSignup !== this.props.isSignup &&
      this.props.isSignup === true
    ) {
      this.setState({ isOpen: true });
    }
  }

  isCurrentScreen = (path) =>
    get(this.props, "history.location.pathname") === path;

  addScript = () => {
    const frame = get(this.widgetRenderArea.current, "contentWindow.document");
    frame.open();
    frame.write(WIDGET_RENDER);
    frame.close();
  };

  render() {
    const { isOpen } = this.state;
    return (
      <div className={`${styles.artistCollapse} ${isOpen ? styles.open : ""}`}>
        <span
          className={styles.arrow}
          onClick={() =>
            this.setState({ isOpen: this.props.isSignup || !isOpen })
          }
        />
        <Collapse
          in={
            isOpen ||
            this.isCurrentScreen(DATA_REVIEW) ||
            this.isCurrentScreen(TUNE_MY_DATA)
          }
          mountOnEnter
          unmountOnExit
        >
          <p
            className={`${styles.collapseTitle} ${
              this.isCurrentScreen(DATA_REVIEW) ||
              this.isCurrentScreen(TUNE_MY_DATA)
                ? styles.activeGuide
                : ""
            }`}
          >
            1. Verification
          </p>
        </Collapse>
        <Collapse
          in={
            isOpen &&
            (this.isCurrentScreen(DATA_REVIEW) ||
              this.isCurrentScreen(TUNE_MY_DATA))
          }
          mountOnEnter
          unmountOnExit
          className={`${styles.collapseContent} ${
            !this.isCurrentScreen(DATA_REVIEW) ? styles.inactiveGuide : ""
          }`}
        >
          {htmlParser(
            get(this.props, "guideData.funnelGuideVerification1", "")
          )}
        </Collapse>
        <Collapse
          in={isOpen && this.isCurrentScreen(TUNE_MY_DATA)}
          mountOnEnter
          unmountOnExit
          className={styles.collapseContent}
        >
          {htmlParser(
            get(this.props, "guideData.funnelGuideVerification2", "")
          )}
        </Collapse>
        <Collapse
          in={isOpen || this.isCurrentScreen(FUNDING)}
          mountOnEnter
          unmountOnExit
        >
          <p
            className={`${styles.collapseTitle} ${
              this.isCurrentScreen(FUNDING) ? styles.activeGuide : ""
            }`}
          >
            2. Customization
          </p>
        </Collapse>
        <Collapse
          in={isOpen && this.isCurrentScreen(FUNDING)}
          mountOnEnter
          unmountOnExit
          className={styles.collapseContent}
        >
          {htmlParser(
            get(this.props, "guideData.funnelGuideCustomization", "")
          )}
        </Collapse>
        <Collapse
          in={isOpen || this.isCurrentScreen(SEND_REPORTS)}
          mountOnEnter
          unmountOnExit
        >
          <p
            className={`${styles.collapseTitle} ${
              this.isCurrentScreen(SEND_REPORTS) && !this.props.isSignup
                ? styles.activeGuide
                : ""
            }`}
          >
            3. Send Reports
          </p>
        </Collapse>
        <Collapse
          in={
            isOpen && this.isCurrentScreen(SEND_REPORTS) && !this.props.isSignup
          }
          mountOnEnter
          unmountOnExit
          className={styles.collapseContent}
        >
          {htmlParser(get(this.props, "guideData.funnelGuideSendReports", ""))}
        </Collapse>
        <Collapse
          in={isOpen && this.props.isSignup}
          mountOnEnter
          unmountOnExit
          onEntered={this.addScript}
          onExited={this.state.removeFunc}
          className={styles.collapseContent}
        >
          <div id="getFunded" className="getFundedContainer">
            <iframe
              title="Widget"
              id="widgetRenderArea"
              ref={this.widgetRenderArea}
            ></iframe>
          </div>
        </Collapse>
      </div>
    );
  }
}
