const PRIMARY_COLOR = "--primary-color";
const APP_PRIMARY_BACKGROUND_COLOR = "--app-primary-background-color";
const APP_HEADER_BACKGROUND_COLOR = "--app-header-background-color";
const APP_HEADER_FONT_COLOR = "--app-header-font-color";
const ACCENT_FONT_COLOR = "--accent-font-color";
const CTA_COLOR = "--cta-color";
const SIDEBAR_PRIMARY_BACKGROUND_COLOR = "--sidebar-primary-background-color";
const SIDEBAR_HEADER_FOOTER_COLOR = "--sidebar-header-footer-color";
const SIDEBAR_FONT_COLOR_1 = "--sidebar-font-color-1";
const SIDEBAR_FONT_COLOR_2 = "--sidebar-font-color-2";
const SIDEBAR_TOOLTIP_ICON_COLOR = "--sidebar-tooltip-icon-color";
const PROGRESS_ACCENT_COLOR = "--progress-accent-color";
const PROGRESS_UPDATE_COLOR = "--progress-update-color";
const TOOLTIP_BACKGROUND_COLOR = "--tooltip-background-color";
const TOOLTIP_FONT_COLOR = "--tooltip-font-color";
const ALERT_BACKGROUND_COLOR = "--alert-background-color";
const ALERT_FONT_COLOR = "--alert-font-color";
const SLIDER_COLOR = "--slider-color";
const HELP_TEXT_COLOR = "--help-text-color";

export const BB_SUBDOMAINS = [
  "app.beatbread.com",
  "dev.beatbread.com",
  "demo.beatbread.com",
];

export const DEFAULT_BB_THEME = {
  displayName: "",
  slugName: "app",
  primaryColor: "#310AE3",
  emailLogo: "https://dev.beatbread.com/logo/beatbread-blue-logo.png",
  favicon: "",
  thankYouCTA: {
    popupText:
      "Thank you for completing your application. Our team will analyze the reports you uploaded and we will be in touch within 2 business days. In the meantime, please check out our FAQs.",
    buttonText: "book an appointment",
    actionUrl: "#",
  },
  emailStyle: {
    headerFooterColor: "#000000",
    ctaColor: "#000000",
    fontColor1: "#000000",
    fontColor2: "#000000",
    boxColor: "#000000",
    iconColor: "#000000",
  },
  appStyle: {
    primaryBackgroundColor: "#310AE3",
    headerBackgroundColor: "#5a3be9",
    headerFontColor: "#ffffff",
    accentFontColor: "#310ae3",
    ctaColor: "#000000",
  },
  sidebarStyle: {
    primaryBackgroundColor: "#310AE3",
    headerFooterColor: "#5a3be9",
    fontColor1: "#310ae3",
    fontColor2: "#5a3be9",
    tooltipIconColor: "#ffffff",
  },
  progressStatusStyle: { accentColor: "#310ae3", updateColor: "#eb33fd" },
  alertStyle: {
    tooltipBackgroundColor: "#ffffff",
    tooltipFontColor: "#000000",
    alertBackgroundColor: "#000000",
    alertFontColor: "#ffffff",
  },
  contentStyle: { sliderColor: "#310ae3", helpTextColor: "#310ae3" },
  isActive: true,
  isDeleted: { value: false },
};

export default {
  primaryColor: PRIMARY_COLOR,
  appStyle: {
    primaryBackgroundColor: APP_PRIMARY_BACKGROUND_COLOR,
    headerBackgroundColor: APP_HEADER_BACKGROUND_COLOR,
    headerFontColor: APP_HEADER_FONT_COLOR,
    accentFontColor: ACCENT_FONT_COLOR,
    ctaColor: CTA_COLOR,
  },
  sidebarStyle: {
    primaryBackgroundColor: SIDEBAR_PRIMARY_BACKGROUND_COLOR,
    headerFooterColor: SIDEBAR_HEADER_FOOTER_COLOR,
    fontColor1: SIDEBAR_FONT_COLOR_1,
    fontColor2: SIDEBAR_FONT_COLOR_2,
    tooltipIconColor: SIDEBAR_TOOLTIP_ICON_COLOR,
  },
  progressStatusStyle: {
    accentColor: PROGRESS_ACCENT_COLOR,
    updateColor: PROGRESS_UPDATE_COLOR,
  },
  alertStyle: {
    tooltipBackgroundColor: TOOLTIP_BACKGROUND_COLOR,
    tooltipFontColor: TOOLTIP_FONT_COLOR,
    alertBackgroundColor: ALERT_BACKGROUND_COLOR,
    alertFontColor: ALERT_FONT_COLOR,
  },
  contentStyle: { sliderColor: SLIDER_COLOR, helpTextColor: HELP_TEXT_COLOR },
};
